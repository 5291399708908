import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Box, Autocomplete, TableHead, TableBody, TableCell, TableRow, Table, IconButton } from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
import PurchaseOrderColumns from './MachineOrderColumns';
import AddMachineForm from './AddMachineForm';
import MachineModelDropdown from '../ChinaProduction/MachineModelDropdown';
import FormControl from '@mui/material/FormControl';

const backendString = process.env.REACT_APP_BACKEND_STRING;
const PurchaseOrder = ({ onOpen, handleAddMachine = null, onClose, type = 'both', isEdit = false, selectedItem, onSave = null }) => {
    const today = new Date().toISOString().split('T')[0]; //get today's date
    const { selectedRegion, user: { username, email } } = useUser();
    const [validationErrors, setValidationErrors] = useState({});
    const [addMachineView, setAddMachineView] = useState(false);
    const [CFOEmail, setCFOEmail] = useState("");
    const [paymentterms, setPaymentTerms] = useState([]);
    // const [inputvoltages, setInputVoltages] = useState(["110", "220"]);
    const { columnFields, columnLabels } = PurchaseOrderColumns;
    const [machines, setMachines] = useState([]);   //machines in this order

    const machineOrderTemplate = {
        ponumber: '',
        applicationdate: today,
        customername: '',
        customeraddress: '',
        vendorname: '',
        vendoraddress: '',
        podate: today,
        expecteddeliverydate: '',
        specialrequirement: '',
        actualdeliverydate: '',
        orderStatus: 'NEW',
        owner: username,
        owneremail: email,
        region: selectedRegion,
        orderprice: '',
        paymentMethod: '',
        shipVia: '',
        incoterm: '',
    }

    //cells for machine information
    const [hiddenCells, setHiddenCells] = useState({});
    //toggle machine information cells
    const toggleHiddenCell = (id) => {
        console.log(hiddenCells);
        setHiddenCells((prevState) => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    //all require fields 
    const requiredFields = ["customername", "applicationdate"];
    //validate the form
    const validateForm = () => {
        const errors = {};
        requiredFields.forEach(field => {
            if (!formData[field]) {
                errors[field] = `this field is required`;
            }
        });
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const [formData, setFormData] = useState(machineOrderTemplate)


    useEffect(() => {
        console.log(formData);
    }, [formData]);

    useEffect(() => {
        if (isEdit && selectedItem) {
            console.log(selectedItem);
            setFormData(selectedItem);
            setMachines(selectedItem.machines);
        }
        getCFOEmail();
    }, [isEdit, selectedItem]);

    const getCFOEmail = async () => {
        const response = await axios.get(backendString + '/users/email/CFO_EMAIL');
        setCFOEmail(response.data.email);
    }

    //set field valus to default
    const resetFormData = () => {
        setFormData(machineOrderTemplate);
    }
    //field valus change event
    const handleChange = (event) => {
        const { name, value } = event.target;
        let newValue = value;
        let fieldName = name;

        setFormData((prev) => ({ ...prev, [fieldName]: newValue, }));
        if (validationErrors[name]) {
            setValidationErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };
    //open the add machine window
    const openAddMachine = () => {
        setAddMachineView(true);
    }
    //close the add machine window
    const closeAddMachine = () => {
        setAddMachineView(false);
    }
    //add new machine to this order
    const addMachineToOrder = (newMachine) => {
        setMachines(prevMachines => [
            ...prevMachines,
            {
                name: newMachine.model,
                inputVoltage: newMachine.inputVoltage,
                warranty: newMachine.warranty,
                detail: newMachine.parts
            }
        ]);
        closeAddMachine();
    }
    //delete the machine from machine order form
    const handleDeleteMachine = (index) => {
        setMachines(machines.filter((_, i) => i !== index));
    }
    //place a new order
    const handleSubmit = async () => {
        console.log("submit called to add record");
        if (!validateForm()) {
            return;
        }
        let newpo = '';
        let region = 'US';
        switch (selectedRegion) {
            case 'USA':
                region = 'US';
                break;
            case 'CHINA':
                region = 'CN';
                break;
            case 'MEXICO':
                region = 'MX';
                break;
            case 'EU':
                region = 'EU';
                break;
            case 'TAIWAN':
                region = 'TW';
                break;
            default:
                region = 'US';
                break;
        }
        let updatedFormData = null;
        try {
            //get temp PO number
            let response = await axios.get(`${backendString}/machineorder/year/`);
            let numberOfdata = response.data.length; // total machine orders
            const currentYear = new Date().getFullYear();
            newpo = region + currentYear + numberOfdata.toString().padStart(5, '0');
            let invalidPO = true;
            while (invalidPO) {
                console.log("loop");
                //check if PO exist in china production
                let response1 = await axios.get(`${backendString}/chinaproduction/searchpo/${newpo}`);
                //check if PO exist in machine order
                let response2 = await axios.get(`${backendString}/machineorder/ponumber/${newpo}`);
                if (response1.data.length > 0 || response2.data.length > 0) {
                    numberOfdata += 1;
                    newpo = region + currentYear + numberOfdata.toString().padStart(5, '0');
                } else {
                    invalidPO = false;
                }
            }
            updatedFormData = { ...formData, ponumber: newpo, machines: machines };
            setFormData(updatedFormData);

        } catch (error) {
            console.error("Error Creating ponumber...", error);
            return;
        }
        //console.log("submit data");
        //console.log(updatedFormData);
        try {
            // add new machine order to database
            const response = await axios.post(`${backendString}/machineorder?region=${selectedRegion}`,
                updatedFormData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': sessionStorage.getItem('token') || "",
                    },
                }
            );
            //console.log(response.data);
            //add machine order to table
            handleAddMachine(response.data.data);

        } catch (error) {
            console.error("Error submitting machine order:", error);
            return;
        }

        // send email to CFO to notify CFO of new order
        try {
            await axios.post(backendString + `/machineorder/send-email/sent`, {
                "POnumber": updatedFormData.ponumber,
                "Email": CFOEmail,
            }, {
                headers: {
                    'Authorization': sessionStorage.getItem('token')
                }
            });
        } catch (error) {
            console.error("Error sending email to CFO!:", error);
            return;
        }
    };

    //update the machine order record in DB
    const handleEdit = async () => {
        formData.orderStatus = "Edited";
        try {
            var response;
            response = await axios.put(`${backendString}/machineorder/${formData._id}?region=${selectedRegion}`, formData, {
                headers: {
                    'Authorization': sessionStorage.getItem('token')
                }
            });
            onSave(response.data);
            onClose();
        } catch (error) {
        }
    };

    //text field
    const TextFieldTemplate = (fieldName, headerName, width = 6, row = 1, type = "text") => {
        return (
            <Grid item xs={width}>
                <TextField
                    fullWidth
                    required={requiredFields.includes(fieldName)}
                    error={!!validationErrors[fieldName]}
                    helperText={validationErrors[fieldName] || ""}
                    multiline={row > 1}
                    rows={row > 1 ? row : 1}
                    label={GetTextByRegion(headerName, selectedRegion)}
                    variant="outlined"
                    value={formData[fieldName]}
                    name={fieldName}
                    onChange={handleChange}
                    type={type}
                    InputLabelProps={
                        type === "date" ? { shrink: true } : undefined
                    }
                    disabled={fieldName === "owner" || fieldName === "region" || fieldName === "owneremail"}
                // InputProps={{                   
                //     readOnly: fieldName==="owner",
                // }}
                />
            </Grid>
        );
    }

    return (
        <Dialog open={onOpen} onClose={onClose}>
            <DialogTitle>{GetTextByRegion("Place a Machine Order", selectedRegion)}</DialogTitle>
            <DialogContent>

                <Grid container spacing={2} justifyContent="left" alignItems="center" mt={0}>
                    {columnFields.map((field, index) => {
                        if (field === "orderStatus" || field === "ponumber") { return null; }
                        else if (field === "paymentMethod") {
                            return (
                                <Grid item xs={6}>
                                    <MachineModelDropdown
                                        selectedRegion={selectedRegion}
                                        GetTextByRegion={GetTextByRegion}
                                        handleChange={handleChange}
                                        validationErrors={validationErrors}
                                        machine={formData}
                                        type="paymentmethod" // Set to "option"
                                    />
                                </Grid>
                            )
                        } else if (field === "shipVia") {
                            return (<Grid item xs={6}>
                                <MachineModelDropdown
                                    selectedRegion={selectedRegion}
                                    GetTextByRegion={GetTextByRegion}
                                    handleChange={handleChange}
                                    validationErrors={validationErrors}
                                    machine={formData}
                                    type="shipvia" // Set to "option"
                                />
                            </Grid>);
                        }
                        else if (field === "incoterm") {
                            return (
                                <>
                                    <Grid item xs={6}>
                                        <MachineModelDropdown
                                            selectedRegion={selectedRegion}
                                            GetTextByRegion={GetTextByRegion}
                                            handleChange={handleChange}
                                            validationErrors={validationErrors}
                                            machine={formData}
                                            type="incoterm" // Set to "option"
                                        />
                                    </Grid>
                                    <Grid item xs={6}></Grid> {/* Keeping this empty Grid if necessary */}
                                </>

                            );
                        } else if (field === "orderprice") {
                            return (
                                <Grid item xs={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel htmlFor="outlined-adornment-amount">
                                            {GetTextByRegion("Total Price", selectedRegion)}
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            onChange={handleChange}
                                            value={formData.orderprice}
                                            label="orderprice"
                                            name='orderprice'
                                        />
                                    </FormControl>
                                </Grid>
                            );
                        } else if (field === "specialrequirement" || field === 'vendoraddress' || field === "customeraddress") {
                            return TextFieldTemplate(field, columnLabels[index], 12, 1);
                        } else if (field === "applicationdate" || field === "expecteddeliverydate" || field === "actualdeliverydate") {
                            return TextFieldTemplate(field, columnLabels[index], 6, null, "date");
                        } else {
                            return TextFieldTemplate(field, columnLabels[index]);
                        }
                    })}
                    <Grid item xs={12} container justifyContent="flex-start">
                        <Button variant="contained" color="primary" onClick={openAddMachine} style={{ marginLeft: 8 }}>
                            {GetTextByRegion("Add Machine", selectedRegion)}
                        </Button>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>{GetTextByRegion("Model", selectedRegion)}</TableCell>
                                    <TableCell>{GetTextByRegion("Input Voltage", selectedRegion)}</TableCell>
                                    <TableCell>{GetTextByRegion("Warranty", selectedRegion)}</TableCell>
                                    <TableCell>{GetTextByRegion("Detail", selectedRegion)}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {machines.map((row, index) => (
                                    <React.Fragment key={index}>
                                        {/* main rows */}
                                        <TableRow>
                                            <TableCell>{index}</TableCell>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>{row.inputVoltage}</TableCell>
                                            <TableCell>{row.warranty ? "Yes" : "No"}</TableCell>
                                            <TableCell>
                                                <IconButton aria-label="approve" color="success" onClick={() => toggleHiddenCell(index)}>
                                                    <AssignmentTurnedInIcon />
                                                </IconButton>
                                                <IconButton aria-label="delete" color="secondary" onClick={() => handleDeleteMachine(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        {/* hidden cell */}
                                        {hiddenCells[index] && (
                                            <TableRow>
                                                <TableCell colSpan="5" style={{ backgroundColor: '#f9f9f9' }}>
                                                    <strong>Machine Information:</strong>
                                                    <br />
                                                    {
                                                        (() => {
                                                            return row.detail.map((item, index) => (
                                                                <span key={index}>
                                                                    {item.item} * {item.quantity}   {item.note}
                                                                    <br />
                                                                </span>
                                                            ));
                                                        })()
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12} container justifyContent="flex-end">
                        <Button variant="outlined" color="secondary" onClick={() => { onClose(); resetFormData(); }}>
                            {GetTextByRegion("Close", selectedRegion)}
                        </Button>
                        {!isEdit ?
                            (
                                <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginLeft: 8 }}>
                                    {GetTextByRegion("Submit", selectedRegion)}
                                </Button>
                            )
                            :
                            (
                                <Button variant="contained" color="primary" onClick={handleEdit} style={{ marginLeft: 8 }}>
                                    {GetTextByRegion("Edit", selectedRegion)}
                                </Button>
                            )
                        }

                    </Grid>
                </Grid>
            </DialogContent>
            {addMachineView && <AddMachineForm open={true} onClose={closeAddMachine} addMachine={addMachineToOrder} />}
        </Dialog>
    );
};

export default PurchaseOrder;
