import React, { useEffect, useCallback, useRef, useState } from 'react';
import axios from 'axios';
import { useUser, useValidateRegions } from '../hooks/UserContext';
import { Button } from '@mui/material';
import roles from '../hooks/Roles';
import ProductionPartTable from '../components/ProductionParts/ProductionPartTable';
import { GetTextByRegion } from '../hooks/Translation';
import ProductionPartForm from '../components/ProductionParts/ProductionPartForm';


const ProductionPartsView = () => {
    const validateSelectedRegion = ["USA", "MEXICO", "EU", "TAIWAN", "CHINA"];
    const prevSelectedRegionRef = useRef();
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const { selectedRegion, refreshUser, user: { username, userRights } } = useUser();
    const backendString = process.env.REACT_APP_BACKEND_STRING;

    const [data, setData] = useState([]);

    // Fetch machine order data
    const fetchData = useCallback(async () => {
        try {
            const response = await axios.get(`${backendString}/productionParts`);
            setData(response.data);

        } catch (error) {
            console.error('Error fetching machine orders:', error);
        }
    }, [backendString, userRights]);

    useEffect(() => {
        refreshUser().then(() => {
            const currentRegion = selectedRegion;
            prevSelectedRegionRef.current = currentRegion;
        }
        )

    }, [refreshUser, selectedRegion]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };

    const handleAddPart = (newPart) => {
        setData([...data, newPart]);
    };

    return (
        <div>
            <Button variant="contained" color="primary" style={{ margin: 8 }} onClick={() => setOpenAddDialog(true)}>
                {GetTextByRegion("New Part Order", selectedRegion)}
            </Button>
            <ProductionPartTable
                data={data}
                setData={setData}
                username={username}
                userRights={userRights}
                selectedRegion={selectedRegion}
            />
            {openAddDialog && (
                <ProductionPartForm open={openAddDialog} onClose={handleCloseAddDialog} onAdd={handleAddPart} />
            )}

        </div>
    );
};

export default ProductionPartsView;
