import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Box, Dialog, DialogTitle, DialogContent, DialogActions, Grid, FormControl, InputLabel, Select, MenuItem, Typography, FormHelperText } from '@mui/material';
import { useUser } from '../../hooks/UserContext';
import { GetTextByRegion } from '../../hooks/Translation';
import PartNumberTextField from '../PartNumberTextField';
import { generatePartPDF } from './GeneratePartPDF'
import MachineModelDropdown from '../ChinaProduction/MachineModelDropdown';


const backendString = process.env.REACT_APP_BACKEND_STRING;

const ProductionPartForm = ({ onAdd, onClose, editData, setData }) => {
    const today = new Date().toISOString().split('T')[0]; //get today's date
    const [formData, setFormData] = useState({
        poNumber: '',
        applicationDate: today,
        partNumber: [''],
        price: [''],
        quantity: [''],
        incoterm: '',
        paymentMethod: '',
        paymentTerms: '',
        notes: '',
        numParts: 1, // New field to control the number of parts
        vendorName: '',
        vendorAddress: '',
        rate: [''],
        shipVia: '',
    });
    const [validationErrors, setValidationErrors] = useState({});
    const { selectedRegion } = useUser();

    useEffect(() => {
        if (editData) {
            setFormData({
                poNumber: editData.poNumber || '',
                applicationDate: editData.applicationDate || today,
                partNumber: Array.isArray(editData.partNumber) ? editData.partNumber : [''],
                price: Array.isArray(editData.price) ? editData.price : [''],
                quantity: Array.isArray(editData.quantity) ? editData.quantity : [''],
                incoterm: editData.incoterm || '',
                paymentMethod: editData.paymentMethod || '',
                notes: editData.notes || '',
                numParts: editData.partNumber?.length || 1, // Ensure numParts matches the array length
                vendorName: editData.vendorName || '',
                vendorAddress: editData.vendorAddress || '',
                rate: Array.isArray(editData.rate) ? editData.rate : [''],
                shipVia: editData.shipVia || '',
            });
        } else {
            setFormData({
                poNumber: '',
                applicationDate: today,
                partNumber: [''],
                price: [''],
                quantity: [''],
                incoterm: '',
                paymentMethod: '',
                notes: '',
                numParts: 1,
                vendorName: '',
                vendorAddress: '',
                rate: [''],
                shipVia: '',

            });
        }
    }, [editData]);


    const addProductionPart = async () => {

        const isValidationError = validateForm();
        if (!isValidationError) {
            return;
        }

        if (editData) {
            try {
                let connectionString = '';
                connectionString = backendString + `/productionParts/${editData._id}`;

                const response = await axios.put(`${connectionString}`, formData, {
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                    },
                });

                // Update the specific entry in the existing data
                setData(prevData => prevData.map(item =>
                    item._id === response.data._id ? response.data : item
                ));

            }
            catch (error) {
                console.error('Error adding production part:', error);
                setValidationErrors({ general: error.response?.data?.error });
            }
        }
        else {


            try {
                let connectionString = backendString + '/productionParts';

                const response = await axios.post(`${connectionString}`, formData, {
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                    },
                });

                onAdd(response.data);

                // Generate the PDF and send the email
                handleGeneratePDF(response.data, async (emailAttachment) => {
                    try {
                        await axios.post(`${backendString}/productionParts/send-email/attachment`, emailAttachment, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                        });
                    } catch (error) {
                        console.error('Error sending email:', error);
                    }
                });

            } catch (error) {
                console.error('Error adding production part:', error);
                setValidationErrors({ general: error.response?.data?.error });
            }

        }
        setFormData({
            poNumber: '',
            applicationDate: '',
            partNumber: [''],
            price: [''],
            quantity: [''],
            incoterm: '',
            paymentMethod: '',
            notes: '',
            numParts: 1, // Reset the number of parts after submission
            vendorName: '',
            vendorAddress: '',
            rate: [''],
            shipVia: '',
        });
        onClose();
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleNumPartsChange = (event) => {
        const numParts = parseInt(event.target.value, 10) || 1; // Ensure it's a valid number
        setFormData((prevData) => {
            const updatedPartNumbers = [...prevData.partNumber, ...Array(Math.max(0, numParts - prevData.partNumber.length)).fill('')];
            const updatedQuantities = [...prevData.quantity, ...Array(Math.max(0, numParts - prevData.quantity.length)).fill('')];
            const updatedPrices = [...prevData.price, ...Array(Math.max(0, numParts - prevData.price.length)).fill('')];

            return {
                ...prevData,
                numParts,
                partNumber: updatedPartNumbers.slice(0, numParts),
                quantity: updatedQuantities.slice(0, numParts),
                price: updatedPrices.slice(0, numParts),
            };
        });
    };


    const validateForm = () => {
        const errors = {};
        if (!formData.poNumber) errors.poNumber = 'PO Number is required';
        if (!formData.applicationDate) errors.applicationDate = 'Application Date is required';
        if (formData.quantity.length && formData.quantity.some((q) => !q)) errors.quantity = 'Quantity is required';
        if (formData.price.length && formData.price.some((p) => !p)) errors.price = 'Price is required';
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleGeneratePDF = (report, callback) => {
        generatePartPDF(report, async (doc) => {

            const response = await axios.get(backendString + '/users/email/CFO_EMAIL');
            const pdfBlob = doc.output('blob'); // Generate PDF as Blob

            const formData = new FormData();
            formData.append('file', new Blob([pdfBlob], { type: 'application/pdf' }), 'Purchase Order.pdf');
            formData.append('email', response.data.email); // Adjust based on your API requirements

            callback(formData);
        }, (error) => {
            console.error('Failed to generate PDF:', error);
        }, selectedRegion);
    };


    return (
        <Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>{GetTextByRegion("Add New Production Part", selectedRegion)}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {/* Left Column */}
                    <Grid item xs={12} sm={6} mt={1}>
                        <Box display="flex" flexDirection="column" gap={2}>
                            <TextField
                                label={GetTextByRegion("PO Number", selectedRegion)}
                                name="poNumber"
                                value={formData.poNumber}
                                onChange={handleChange}
                                variant="outlined"
                                error={!!validationErrors.poNumber}
                                helperText={validationErrors.poNumber}
                                fullWidth
                            />
                            <TextField
                                label={GetTextByRegion("Application Date", selectedRegion)}
                                name="applicationDate"
                                type="date"
                                value={formData.applicationDate}
                                onChange={handleChange}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                error={!!validationErrors.applicationDate}
                                helperText={validationErrors.applicationDate}
                                fullWidth
                            />
                            <TextField
                                label={GetTextByRegion("Vendor Name", selectedRegion)}
                                name="vendorName"
                                value={formData.vendorName}
                                onChange={handleChange}
                                variant="outlined"
                                error={!!validationErrors.vendorName}
                                helperText={validationErrors.vendorName}
                                fullWidth
                            />
                            <TextField
                                label={GetTextByRegion("Vendor Address", selectedRegion)}
                                name="vendorAddress"
                                value={formData.vendorAddress}
                                onChange={handleChange}
                                variant="outlined"
                                error={!!validationErrors.vendorAddress}
                                helperText={validationErrors.vendorAddress}
                                fullWidth
                                multiline
                            />
                            <FormControl fullWidth error={!!validationErrors.numParts}>
                                <InputLabel>{GetTextByRegion("Number of Parts", selectedRegion)}</InputLabel>
                                <Select
                                    name="numParts"
                                    value={formData.numParts}
                                    onChange={handleNumPartsChange}
                                >
                                    {[...Array(20)].map((_, index) => (
                                        <MenuItem key={index + 1} value={index + 1}>
                                            {index + 1}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {validationErrors.numParts && <FormHelperText>{validationErrors.numParts}</FormHelperText>}
                            </FormControl>
                        </Box>
                    </Grid>

                    {/* Right Column */}
                    <Grid item xs={12} sm={6} mt={1}>
                        <Box display="flex" flexDirection="column" gap={2}>
                            {/* <FormControl variant="outlined" fullWidth>
                                <InputLabel>{GetTextByRegion("Incoterm", selectedRegion)}</InputLabel>
                                <Select
                                    label={GetTextByRegion("Incoterm", selectedRegion)}
                                    name="incoterm"
                                    value={formData.incoterm}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="FOB">FOB</MenuItem>
                                    <MenuItem value="CIF">CIF</MenuItem>
                                    <MenuItem value="EXW">EXW</MenuItem>
                                </Select>
                            </FormControl> */}
                            <MachineModelDropdown
                                selectedRegion={selectedRegion}
                                GetTextByRegion={GetTextByRegion}
                                handleChange={handleChange}
                                validationErrors={validationErrors}
                                machine={formData}
                                type="incoterm" // Set to "option"
                            />
                            {/* <TextField
                                label={GetTextByRegion("Payment Method", selectedRegion)}
                                name="paymentMethod"
                                value={formData.paymentMethod}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                            />
                            <TextField
                                label={GetTextByRegion("Ship Via", selectedRegion)}
                                name="shipVia"
                                value={formData.shipVia}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                            /> */}
                             <MachineModelDropdown
                                selectedRegion={selectedRegion}
                                GetTextByRegion={GetTextByRegion}
                                handleChange={handleChange}
                                validationErrors={validationErrors}
                                machine={formData}
                                type="paymentmethod" // Set to "option"
                            />
                             <MachineModelDropdown
                                selectedRegion={selectedRegion}
                                GetTextByRegion={GetTextByRegion}
                                handleChange={handleChange}
                                validationErrors={validationErrors}
                                machine={formData}
                                type="shipvia" // Set to "option"
                            />
                            <TextField
                                label={GetTextByRegion("Notes", selectedRegion)}
                                name="notes"
                                value={formData.notes}
                                onChange={handleChange}
                                variant="outlined"
                                multiline
                                fullWidth
                            />
                        </Box>
                    </Grid>
                </Grid>

                {/* Part Details Section */}
                {formData.numParts > 0 && (
                    <Box mt={3}>
                        <Typography variant="h6">{GetTextByRegion("Part Details", selectedRegion)}</Typography>
                        <Grid container spacing={2} mt={1}>
                            {Array.from({ length: formData.numParts }).map((_, index) => (
                                <React.Fragment key={index}>
                                    <Grid item xs={12} sm={6}>
                                        {/* <TextField
                                            label={GetTextByRegion(`Part Number ${index + 1}`, selectedRegion)}
                                            name={`partNumber-${index}`}
                                            value={formData.partNumber[index]}
                                            onChange={(e) => {
                                                const updatedPartNumbers = [...formData.partNumber];
                                                updatedPartNumbers[index] = e.target.value;
                                                setFormData({ ...formData, partNumber: updatedPartNumbers });
                                            }}
                                            variant="outlined"
                                            error={!!validationErrors.partNumber}
                                            helperText={validationErrors.partNumber}
                                            fullWidth
                                        /> */}

                                        <PartNumberTextField
                                            value={formData.partNumber[index]}
                                            onChange={(newPartNumber, newPrice) => {
                                                const newPartNumbers = [...formData.partNumber];
                                                newPartNumbers[index] = newPartNumber;
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    partNumber: newPartNumbers,
                                                }));

                                            }}
                                            variant="outlined"
                                            fullWidth
                                            selectedRegion={selectedRegion}
                                            index={index}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <TextField
                                            label={GetTextByRegion(`Quantity ${index + 1}`, selectedRegion)}
                                            name={`quantity-${index}`}
                                            type="number"
                                            value={formData.quantity[index]}
                                            onChange={(e) => {
                                                const updatedQuantities = [...formData.quantity];
                                                updatedQuantities[index] = e.target.value;
                                                setFormData({ ...formData, quantity: updatedQuantities });
                                            }}
                                            variant="outlined"
                                            error={!!validationErrors.quantity}
                                            helperText={validationErrors.quantity}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <TextField
                                            label={GetTextByRegion(`Price ${index + 1}`, selectedRegion)}
                                            name={`price-${index}`}
                                            type="number"
                                            value={formData.price[index]}
                                            onChange={(e) => {
                                                const updatedPrices = [...formData.price];
                                                updatedPrices[index] = e.target.value;
                                                setFormData({ ...formData, price: updatedPrices });
                                            }}
                                            variant="outlined"
                                            error={!!validationErrors.price}
                                            helperText={validationErrors.price}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <TextField
                                            label={GetTextByRegion(`Rate ${index + 1}`, selectedRegion)}
                                            name={`rate-${index}`}
                                            type="number"
                                            value={formData.rate[index]}
                                            onChange={(e) => {
                                                const updatedPrices = [...formData.rate];
                                                updatedPrices[index] = e.target.value;
                                                setFormData({ ...formData, rate: updatedPrices });
                                            }}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>
                    </Box>
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    {GetTextByRegion("Cancel", selectedRegion)}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={addProductionPart}
                    disabled={!formData.poNumber || !formData.applicationDate || !formData.quantity.length || !formData.price.length}
                >
                    {editData ? GetTextByRegion("Save", selectedRegion) : GetTextByRegion("Add Production Part", selectedRegion)}
                </Button>
            </DialogActions>
        </Dialog>

    );
};

export default ProductionPartForm;
