import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Button, IconButton, Tab, Tabs, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InventoryIcon from '@mui/icons-material/Inventory';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import ErrorDialog from '../ErrorDialog';
import EditMachineForm from './EditMachineForm';
import placeholderImage from "../../images/placeholder-image.jpg"
import useWindowSize from '../../hooks/useWindowSize';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import CustomDataGrid from '../CustomDataGrid';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import SearchBar from '../SearchBar';
import MobileViewComponent from '../MobileView';
import roles from '../../hooks/Roles';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
import StatusProgressBar from '../StatusProgressBar';
import Tooltip from '@mui/material/Tooltip';
import ImageDialog from '../Inventory/ImageDialog';

const backendString = process.env.REACT_APP_BACKEND_STRING;

// const MachineTable = ({ machines, setFilteredMachines, racks, setFilteredRacks, completed, setFilteredCompleted, openEditDialog, setOpenEditDialog, openDeleteDialog, setOpenDeleteDialog, isMachinePopupOpen, setIsMachinePopupOpen, tabValue, setTabValue }) => {
const MachineTable = ({ allMachines, setAllMachines, openEditDialog, setOpenEditDialog, openDeleteDialog, setOpenDeleteDialog, isMachinePopupOpen, setIsMachinePopupOpen, tabValue, setTabValue, fetchMachines }) => {
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredMachines, setFilteredMachines] = useState([]);
  const [filteredRacks, setFilteredRacks] = useState([]);
  const [filteredCompleted, setFilteredCompleted] = useState([]);
  const [filteredShipped, setFilteredShipped] = useState([]);
  const [filteredOnSite, setFilteredOnSite] = useState([]);
  const { windowSize, smallWidth } = useWindowSize();
  const { selectedRegion, user: { userRights } } = useUser();
  const connectionString = backendString + '/machines';
  const [machineColumnVisibilityConfig, setMachineColumnVisibilityModel] = useState();
  const [rackColumnVisibilityConfig, setRackColumnVisibilityModel] = useState();
  const [shippedColumnVisibilityConfig, setShippedColumnVisibilityModel] = useState();
  const [onsiteColumnVisibilityConfig, setOnsiteColumnVisibilityModel] = useState();
  const [completeColumnVisibilityConfig, setCompleteColumnVisibilityModel] = useState();
  const [openImageDialog, setOpenImageDialog] = useState(false); // State to control the dialog
  const [selectedImage, setSelectedImage] = useState(''); // State for the selected image

  // Memoize the urgencyOrder object
  const urgencyOrder = useMemo(() => ({
    1: 0,
    2: 1,
    3: 2,
    0: 3,
  }), []);


  const LoadColumns = (tabValue) => {
    let tableName = "machine";
    switch (tabValue) {
      case 1:
        tableName = "rack";
        break;
      case 2:
        tableName = "shipped";
        break;
      case 3:
        tableName = "onsite";
        break;
      case 4:
        tableName = "complete";
        break;
      default:
        tableName = "machine";
    }

    return axios
      .get(`${backendString}/loadTableColumns?tableName=${tableName}`, {
        headers: {
          Authorization: sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        const columns = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
        return columns;
      })
      .catch((error) => {

        console.error("Error loading columns:", error);
        return {};
      });
  };

  //init
  useEffect(() => {

    const Load = async () => {
      try {
        let columns = await LoadColumns(tabValue);

        switch (tabValue) {
          case 0:
            await setMachineColumnVisibilityModel(columns);
            break;
          case 1:
            await setRackColumnVisibilityModel(columns);
            break;
          case 2:
            await setShippedColumnVisibilityModel(columns);
            break;
          case 3:
            await setOnsiteColumnVisibilityModel(columns);
            break;
          case 4:
            await setCompleteColumnVisibilityModel(columns);
            break;
          default:
            await setMachineColumnVisibilityModel(columns);
        }

      } catch (error) {
        console.log("error");
        setError(error.response); // Set error state
      }
    }
    Load();
  }, [tabValue]);

  // FILTER MACHINES TAB
  useEffect(() => {
    const filteredByStatusAndModel = allMachines.filter(machine =>
      (machine.status !== 'Completed' && machine.status !== 'Disposed' && machine.status !== 'Sold' && machine.status !== 'Shipped' && !machine.status.startsWith('Installed') && !machine.status.startsWith('Demo')) &&
      !(machine.model && machine.isRack)
    );
    const filteredBySearchQuery = filteredByStatusAndModel.filter(machine =>
      (machine.serial && machine.serial.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.model && machine.model.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.poNumber && machine.poNumber.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.customer && machine.customer.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.status && machine.status.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.poStatus && machine.poStatus.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.origin && machine.origin.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.terms && machine.terms.toLowerCase().includes(searchQuery.toLowerCase()))
    );
    const sorted = [...filteredBySearchQuery].sort((a, b) => {
      const urgencyA = urgencyOrder[a.urgent] ?? 0; // Default to 6 if urgency is not found
      const urgencyB = urgencyOrder[b.urgent] ?? 0; // Default to 6 if urgency is not found

      if (urgencyA === urgencyB) {
        return a.model.localeCompare(b.model); // Sort alphabetically by model if urgency is the same
      }
      return urgencyA - urgencyB; // Sort by custom urgency order
    });

    // Update the state with the sorted array
    setFilteredMachines(sorted);
  }, [searchQuery, allMachines, urgencyOrder]);

  // FILTER RACKS TAB
  useEffect(() => {
    const filteredByStatusAndModel = allMachines.filter(machine =>
      (machine.status !== 'Completed' && machine.status !== 'Disposed' && machine.status !== 'Sold' && machine.status !== 'Shipped' && !machine.status.startsWith('Installed') && !machine.status.startsWith('Demo')) &&
      (machine.model && machine.isRack)
    );
    const filteredBySearchQuery = filteredByStatusAndModel.filter(machine =>
      (machine.serial && machine.serial.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.model && machine.model.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.poNumber && machine.poNumber.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.customer && machine.customer.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.status && machine.status.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.poStatus && machine.poStatus.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.origin && machine.origin.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.terms && machine.terms.toLowerCase().includes(searchQuery.toLowerCase()))
    );
    const sorted = [...filteredBySearchQuery].sort((a, b) => {
      const urgencyA = urgencyOrder[a.urgent] ?? 0; // Default to 6 if urgency is not found
      const urgencyB = urgencyOrder[b.urgent] ?? 0; // Default to 6 if urgency is not found
      if (urgencyA === urgencyB) {
        return a.model.localeCompare(b.model); // Sort alphabetically by model if urgency is the same
      }
      return urgencyA - urgencyB; // Sort by custom urgency order
    });
    setFilteredRacks(sorted);
  }, [searchQuery, allMachines, urgencyOrder]);

  //FILTER SHIPPED
  useEffect(() => {
    const filteredByStatusAndModel = allMachines.filter(machine =>
      machine.status === 'Shipped'
    );
    const filteredBySearchQuery = filteredByStatusAndModel.filter(machine =>
      (machine.serial && machine.serial.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.model && machine.model.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.poNumber && machine.poNumber.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.customer && machine.customer.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.status && machine.status.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.poStatus && machine.poStatus.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.origin && machine.origin.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.terms && machine.terms.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    setFilteredShipped(filteredBySearchQuery);
  }, [searchQuery, allMachines]);

  // ON SITE TAB
  useEffect(() => {
    const filteredByStatusAndModel = allMachines.filter(machine =>
      machine.status.startsWith('Installed') || machine.status.startsWith('Demo')
    );
    const filteredBySearchQuery = filteredByStatusAndModel.filter(machine =>
      (machine.serial && machine.serial.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.model && machine.model.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.poNumber && machine.poNumber.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.customer && machine.customer.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.status && machine.status.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.poStatus && machine.poStatus.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.origin && machine.origin.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.terms && machine.terms.toLowerCase().includes(searchQuery.toLowerCase()))
    );
    setFilteredOnSite(filteredBySearchQuery);
  }, [searchQuery, allMachines, setFilteredOnSite]);

  //COMPLETED TAB
  useEffect(() => {
    const filteredByStatusAndModel = allMachines.filter(machine =>
      machine.status === 'Completed' || machine.status === 'Sold' || machine.status === 'Disposed'
    );
    const filteredBySearchQuery = filteredByStatusAndModel.filter(machine =>
      (machine.serial && machine.serial.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.model && machine.model.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.poNumber && machine.poNumber.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.customer && machine.customer.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.status && machine.status.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.poStatus && machine.poStatus.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.origin && machine.origin.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.terms && machine.terms.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    setFilteredCompleted(filteredBySearchQuery);
  }, [searchQuery, allMachines]);


  const handleEdit = (params) => {
    setSelectedMachine(params.row);
    setOpenEditDialog(true);
  };

  const handleDelete = (params) => {
    setSelectedMachine(params.row);
    setOpenDeleteDialog(true);
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc); // Set the selected image
    setOpenImageDialog(true);   // Open the dialog
  };

  const handleRefresh = (selectedMachine, isEdit) => {
    const removeFromArray = (array, item) => {
      // Ensure item and item._id exist
      if (!item || !item._id) return array;
      return array.filter(i => i?._id !== item._id);
    };

    setFilteredCompleted(prevCompleted => removeFromArray(prevCompleted, selectedMachine));
    setFilteredRacks(prevRacks => removeFromArray(prevRacks, selectedMachine));
    setFilteredMachines(prevMachines => removeFromArray(prevMachines, selectedMachine));
    setFilteredShipped(prevMachines => removeFromArray(prevMachines, selectedMachine));
    setFilteredOnSite(prevMachines => removeFromArray(prevMachines, selectedMachine));

    if (isEdit) {
      if (selectedMachine.status === 'Completed' || selectedMachine.status === 'Sold') {
        setFilteredCompleted(prevCompleted => [...prevCompleted, selectedMachine]);
      } else if (selectedMachine.model && selectedMachine.model.isRack) {
        setFilteredRacks(prevRacks => [...prevRacks, selectedMachine]);
      } else if (selectedMachine.status === 'Shipped') {
        setFilteredShipped(prevRacks => [...prevRacks, selectedMachine]);
      } else if (selectedMachine.status.startsWith('Installed')) {
        setFilteredOnSite(prevRacks => [...prevRacks, selectedMachine]);
      } else {
        setFilteredMachines(prevMachines => [...prevMachines, selectedMachine]);
      }
    }

  };

  const handleEditDetailsSubmit = async (newUpdatedMachine) => {
    try {
      // If there's no image, remove it or set it as null
      if (!newUpdatedMachine.get('image')) {
        newUpdatedMachine.delete('image');
      }

      // Convert nested objects to JSON strings if necessary
      if (newUpdatedMachine.has('history')) {
        const history = newUpdatedMachine.get('history');
        if (Array.isArray(history) && history.every(item => typeof item === 'object')) {
          // Convert array of objects to JSON string
          newUpdatedMachine.set('history', JSON.stringify(history));
        }
      }

      // Modify the FormData based on status
      const status = newUpdatedMachine.get('status');
      if (status === 'Shipped' || status?.startsWith('Installed') || status === 'Completed' || status === 'Disposed') {
        newUpdatedMachine.set('urgent', 0);
      }

      const response = await axios.put(
        `${connectionString}/${newUpdatedMachine.get('_id')}?selectedRegion=${selectedRegion}`,
        newUpdatedMachine,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': sessionStorage.getItem('token'),
          },
        }
      );

      const updatedMachine = response.data.machine;

      setOpenEditDialog(false);
      setIsMachinePopupOpen(false);
      handleRefresh(updatedMachine, true);

      setAllMachines(prevMachines =>
        prevMachines.map(machine =>
          machine._id === updatedMachine._id ? updatedMachine : machine
        )
      );
      return null;
    } catch (error) {
      console.error('Error editing machine:', error);
      setError(error.response?.data?.error);
      return error;
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`${connectionString}/${selectedMachine._id}?selectedRegion=${selectedRegion}`, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
      setOpenDeleteDialog(false);
      handleRefresh(selectedMachine, false);
    } catch (error) {
      console.error('Error deleting machine:', error);
      setError(error.response?.data?.error);
    }
  };

  const handleTabChange = async (event, newValue) => {
    await setTabValue(newValue);
    try {
      const columns = LoadColumns(newValue);
      switch (newValue) {
        case 1:
          await setRackColumnVisibilityModel(columns);
          break;
        case 2:
          await setShippedColumnVisibilityModel(columns);
          break;
        case 3:
          await setOnsiteColumnVisibilityModel(columns);
          break;
        case 4:
          await setCompleteColumnVisibilityModel(columns);
          break;
        default:
          await setMachineColumnVisibilityModel(columns);
      }
    } catch (error) {
      console.log("Error loading columns:", error);
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedMachine(null);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedMachine(null);
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleOpenInventory = (params) => {
    setSelectedMachine(params.row);
    setIsMachinePopupOpen(true);
  };

  const handleCloseInventory = () => {
    setIsMachinePopupOpen(false);
  };

  const exportToExcel = () => {
    const dataToExport = filteredMachines.map(machine => {
      const { _id, ...rest } = machine;
      return rest;
    });
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'machine.xlsx');
  };

  const exportToCSV = () => {
    const dataToExport = filteredMachines.map(machine => {
      const { _id, ...rest } = machine;
      return rest;
    });
    const csvData = Papa.unparse(dataToExport);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'machine.csv';
    link.click();
  };

  // Inside your component
  const renderUrgencyIcon = (params) => {
    switch (params.row.urgent) {
      case 1:
        return <LooksOneIcon />;
      case 2:
        return <LooksTwoIcon />;
      case 3:
        return <Looks3Icon />;
      // Add more cases for other urgency levels if needed
      default:
        return null; // Or a default icon/component if needed
      // return <LooksOneIcon />;

    }
  };

  const columns = [
    (tabValue === 0 || tabValue === 1) ? {
      field: 'Urgent',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Urgency', selectedRegion)}<br /></Typography>),
      width: 75,
      headerAlign: 'center', align: 'center',
      renderCell: (params) => (
        <div>
          <Tooltip title={GetTextByRegion("Urgency", selectedRegion)} arrow>
            <IconButton aria-label="urgency" color='error'>
              {renderUrgencyIcon(params)}
            </IconButton>
          </Tooltip>
        </div>
      ),
    } : null,
    {
      field: 'image',
      headerAlign: 'center', align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Image', selectedRegion)}<br /></Typography>
      ),
      //headerName: GetTextByRegion('Image', selectedRegion),
      flex: 1,
      renderCell: (params) => (
        params.value ? (
          <img
            src={connectionString + `/images/${params.value}?selectedRegion=${selectedRegion}`}
            alt="Machine"
            onClick={() => handleImageClick(connectionString + `/images/${params.value}?selectedRegion=${selectedRegion}`)} // Open dialog on click
            style={{ width: '50%', height: '100%', objectFit: 'cover', borderRadius: '10%' }}
            onError={(e) => {
              console.error('Image load error:', e.target.src);
              e.target.onerror = null; // Prevent infinite loop in case of repeated errors
              e.target.src = placeholderImage; // Placeholder or default image
            }}
          />
        ) : (
          <img
            src={placeholderImage} // Placeholder or default image URL
            alt="Default"
            style={{ width: '50%', height: '100%', objectFit: 'cover', borderRadius: '10%' }}
          />
        )
      ),
    },
    {
      field: 'datereceived', flex: 0.6, align: 'center', headerAlign: 'center', renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Date received', selectedRegion)}<br /></Typography>
      ),
    },
    {
      field: 'serial', flex: 1, align: 'center', headerAlign: 'center', renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Serial Number', selectedRegion)}<br /></Typography>),
    },
    {
      field: 'model', flex: 1, align: 'center', headerAlign: 'center', renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Model', selectedRegion)}<br /></Typography>),
    },
    {
      field: 'status',
      flex: 1.2,
      headerAlign: 'center', align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Progress', selectedRegion)}<br /></Typography>),
      renderCell: (params) => {
        const status = params.row.status;
        const poStatus = params.row.poStatus || ''; // Assuming poStatus is available in the row

        // Append poStatus to 'Installed' only if poStatus is not 'N/A' or an empty string
        const displayStatus = status === 'Installed' && poStatus !== 'N/A' && poStatus
          ? `${status} : ${poStatus}`
          : status;

        return selectedRegion === "USA" ? (
          <StatusProgressBar status={displayStatus} component={'machine'} />
        ) : (
          <Typography variant="body2" mt={3}>{GetTextByRegion(`${displayStatus}`, selectedRegion)}</Typography>
        );
      },
    },
    {
      field: 'customer', flex: 1, headerAlign: 'center', align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Customer', selectedRegion)}<br /></Typography>),
    },
    {
      field: 'poNumber', flex: 1, headerAlign: 'center', align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('PO Number', selectedRegion)}<br /></Typography>),
    },
    {
      field: 'downpayment', flex: 1, headerAlign: 'center', align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Down Payment', selectedRegion)}<br /></Typography>),
    },
    {
      field: 'notes', flex: 2, headerAlign: 'center', align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Notes', selectedRegion)}<br /></Typography>),
    },
    {
      field: 'actions', headerAlign: 'center', align: 'center',
      width: 170,
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Actions', selectedRegion)}<br /></Typography>),
      renderCell: (params) => (
        <div>
          <Tooltip title={GetTextByRegion("See details", selectedRegion)} arrow>
            <IconButton aria-label="inventory" onClick={() => handleOpenInventory(params)}>
              <InventoryIcon />
            </IconButton>
          </Tooltip>
          {userRights >= roles['SERVICE'] && (
            <Tooltip title={GetTextByRegion("Edit", selectedRegion)} arrow>
              <IconButton aria-label="edit" color="primary" onClick={() => handleEdit(params)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {userRights >= roles['MANAGER'] && (
            <Tooltip title={GetTextByRegion("Delete", selectedRegion)} arrow>
              <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(params)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ),
    },

  ].filter(Boolean); // Removes null values from the columns array

  const mobileColumns = [
    { label: "Urgency", name: "urgent", type: "urgency", value: "urgent", required: false, InputLabelProps: {}, position: 'left' },
    { label: "Image", name: "image", type: "image", value: "image", required: false, InputLabelProps: {}, position: 'left' },
    { label: "Date received", name: "datereceived", type: "text", value: "datereceived", required: false, InputLabelProps: {}, position: 'left' },
    { label: "Serial Number", name: "serial", type: "text", value: "serial", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Model", name: "model", type: "text", value: "model", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Status", name: "status", type: "progress", value: "status", required: true, InputLabelProps: {}, position: 'left' },
    { label: selectedRegion === "USA" ? "PO Status" : "PO Number", name: "poStatus", type: "text", value: "poStatus", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Customer", name: "customer", type: "text", value: "customer", required: false, InputLabelProps: {}, position: 'left' },
    { label: "Notes", name: "notes", type: "text", value: "notes", required: false, InputLabelProps: {}, position: 'right' }
  ];

  return (
    <div style={{ height: windowSize.height * 0.71, width: windowSize.width * 1 }}>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: !windowSize.width > smallWidth ? 'flex-end' : 'center',
          flexDirection: windowSize.width <= smallWidth ? 'column' : 'row', // Column for mobile, row for larger screens
        }}
      >
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          placeholder={GetTextByRegion('Search Machines/Racks...', selectedRegion)}
          style={{ marginBottom: windowSize.width <= smallWidth ? '10px' : '0' }} // Add space below the search bar in mobile view
        />

        {userRights >= roles['PRODUCTION'] && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row', // Stack buttons in column for mobile
              position: windowSize.width > smallWidth ? 'absolute' : 'relative', // Absolute on larger screens
              left: windowSize.width > smallWidth ? 0 : 'auto', // Align left on larger screens
            }}
          >
            <Button onClick={exportToCSV}>
              {GetTextByRegion('Export to CSV', selectedRegion)}
            </Button>
            <Button onClick={exportToExcel}>
              {GetTextByRegion('Export to Excel', selectedRegion)}
            </Button>
          </div>
        )}
      </div>


      <>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label={GetTextByRegion("Machines", selectedRegion)} value={0} />
          <Tab label={GetTextByRegion("Racks", selectedRegion)} value={1} />
          {selectedRegion === 'USA' && <Tab label={GetTextByRegion("Shipped", selectedRegion)} value={2} />}
          <Tab label={GetTextByRegion("On Site", selectedRegion)} value={3} />
          {selectedRegion === 'USA' ? <Tab label={GetTextByRegion("Completed", selectedRegion)} value={4} />
            : <Tab label={GetTextByRegion("Sold", selectedRegion)} value={4} />}
        </Tabs>

        {windowSize.width > smallWidth ? (
          tabValue === 0 ? (
            <CustomDataGrid rows={filteredMachines} columns={columns} visibilityColumnConfig={machineColumnVisibilityConfig} setColumnVisibility={setMachineColumnVisibilityModel} tableName={'machine'} />
          ) : tabValue === 1 ? (
            <CustomDataGrid rows={filteredRacks} columns={columns} visibilityColumnConfig={rackColumnVisibilityConfig} setColumnVisibility={setRackColumnVisibilityModel} tableName={'rack'} />
          ) : tabValue === 2 ? (
            <CustomDataGrid rows={filteredShipped} columns={columns} visibilityColumnConfig={shippedColumnVisibilityConfig} setColumnVisibility={setShippedColumnVisibilityModel} tableName={'shipped'} />
          ) : tabValue === 3 ? (
            <CustomDataGrid rows={filteredOnSite} columns={columns} visibilityColumnConfig={onsiteColumnVisibilityConfig} setColumnVisibility={setOnsiteColumnVisibilityModel} tableName={'onsite'} />
          ) : (
            <CustomDataGrid rows={filteredCompleted} columns={columns} visibilityColumnConfig={completeColumnVisibilityConfig} setColumnVisibility={setCompleteColumnVisibilityModel} tableName={'complete'} />
          )
        ) : (
          <MobileViewComponent
            items={
              tabValue === 0 ? filteredMachines :
                tabValue === 1 ? filteredRacks :
                  tabValue === 2 ? filteredShipped :
                    tabValue === 3 ? filteredOnSite :
                      filteredCompleted
            }
            columnData={mobileColumns}
            component={'machine'}
            handleEditClick={handleEdit}
            handleDelete={handleDelete}
            handleView={handleOpenInventory}
            connectionString={connectionString}
            selectedRegion={selectedRegion}
          />
        )}
      </>


      {openEditDialog && //EDIT BUTTON
        <EditMachineForm
          open={openEditDialog}
          onClose={handleCloseEditDialog}
          machine={selectedMachine}
          onSubmit={handleEditDetailsSubmit}
          fetchMachines={fetchMachines}
        />
      }

      {openDeleteDialog &&
        <DeleteConfirmationDialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onConfirm={handleDeleteConfirm}
          selectedRegion={selectedRegion}
        />
      }

      {isMachinePopupOpen && //VIEW DETAILS BUTTON
        <EditMachineForm
          open={isMachinePopupOpen}
          onClose={handleCloseInventory}
          machine={selectedMachine}
          onSubmit={handleEditDetailsSubmit}
          initialIsView={isMachinePopupOpen}
          fetchMachines={fetchMachines}
        />
      }

      {/* Error Dialog */}
      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />

      <ImageDialog
        open={openImageDialog}
        onClose={() => setOpenImageDialog(false)}
        imageSrc={selectedImage}
      />
    </div>
  );
};

export default MachineTable;
