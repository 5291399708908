import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import EditEventForm from './EditEventForm';
import ErrorDialog from '../ErrorDialog';
import EventDetailsPopup from './EventDetailsPopup';
import EventForm from './EventForm';
import { Button, Checkbox, FormControlLabel, FormGroup, InputLabel, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'; // Import Dialog components
import SearchBar from '../SearchBar';
import roles from '../../hooks/Roles';
import useWindowSize from '../../hooks/useWindowSize';
import { useUser } from '../../hooks/UserContext';
import isEqual from 'lodash/isEqual';
import { GetTextByRegion } from '../../hooks/Translation';

const localizer = momentLocalizer(moment);
const backendString = process.env.REACT_APP_BACKEND_STRING;

const MyCalendar = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEventPopupOpen, setIsEventPopupOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isEventFormOpen, setIsEventFormOpen] = useState(false);
  const [technicianColors, setTechnicianColors] = useState({});
  const [selectedTechnicians, setSelectedTechnicians] = useState([]); // State to track selected technicians as an array
  const [filterDialogOpen, setFilterDialogOpen] = useState(false); // State for filter dialog
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const { windowSize, maxWidth } = useWindowSize();
  const { selectedRegion, refreshUser } = useUser();
  const { user: { userRights } } = useUser();

  let connectionString = backendString + '/events';

  // Fetch events from backend
  const fetchEvents = useCallback(async () => {
    try {
      const response = await axios.get(`${connectionString}?selectedRegion=${selectedRegion}`);
      const formattedEvents = response.data.map(event => ({
        id: event._id,
        title: event.title,
        start: new Date(event.startDate + "T" + event.startTime),
        end: new Date(event.endDate + "T" + event.endTime),
        customer: event.customer,
        serial: event.serial,
        model: event.model,
        address: event.address,
        serviceType: event.serviceType,
        description: event.description,
        technicians: event.technicians,
        addTechnicians: event.addTechnicians,
      }));

      // Filter events based on selected technicians and search term
      const filteredEvents = formattedEvents.filter(event =>
        (selectedTechnicians.length === 0 || selectedTechnicians.some(tech => event.technicians.includes(tech))) &&
        (event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          event.serial.toLowerCase().includes(searchTerm.toLowerCase()) ||
          event.customer.toLowerCase().includes(searchTerm.toLowerCase()))
      );

      setEvents(filteredEvents);
    } catch (error) {
      setError(error.response?.data?.error || 'Error fetching events');
      console.error('Error fetching events:', error);
    }
  }, [selectedTechnicians, searchTerm, selectedRegion, connectionString]);

  // Fetch technicians and their colors
  const fetchTechnicians = useCallback(async () => {
    try {
      const response = await axios.get(backendString + `/users/regions?selectedRegion=${selectedRegion}`, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
      const technicianData = response.data.reduce((acc, technician) => {
        acc[technician.username] = technician.color || '#d3d3d3'; // Default color if not set
        return acc;
      }, {});
      setTechnicianColors(technicianData);
    } catch (error) {
      setError(error.response?.data?.error || 'Error fetching technicians');
      console.error('Error fetching technicians:', error);
    }
  }, [selectedRegion]);

  const prevSelectedRegionRef = useRef();
  useEffect(() => {
    refreshUser().then(() => {
      const currentRegion = selectedRegion;
      if (prevSelectedRegionRef.current && !isEqual(prevSelectedRegionRef.current, currentRegion)) {
        setIsEventPopupOpen(false);
        setIsEditFormOpen(false);
        setIsEventFormOpen(false);
      }
      prevSelectedRegionRef.current = currentRegion;
    });
  }, [refreshUser, selectedRegion, isEventPopupOpen, isEditFormOpen, isEventFormOpen]);

  useEffect(() => {
    fetchEvents();
    fetchTechnicians();
  }, [fetchEvents, fetchTechnicians, selectedTechnicians, selectedRegion, connectionString]);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setIsEventPopupOpen(true);
  };

  const handleClosePopup = async () => {
    setIsEventPopupOpen(false);
  };

  const handleEditEvent = () => {
    setIsEventPopupOpen(false);
    setIsEditFormOpen(true);
  };

  const handleDeleteEvent = async () => {
    try {
      await axios.delete(`${connectionString}/${selectedEvent.id}?selectedRegion=${selectedRegion}`, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
      setEvents((prevEvents) => prevEvents.filter(event => event.id !== selectedEvent.id));
      setIsEventPopupOpen(false); // Close event details popup after deletion
    } catch (error) {
      setError(error.response?.data?.error);
      console.error('Error deleting event:', error);
    }
  };

  const handleEditSuccess = async (updatedEvent) => {
    setEvents((prevEvents) =>
      prevEvents.map((event) =>
        event.id === updatedEvent.id ? updatedEvent : event
      )
    );
    setIsEditFormOpen(false);
    setSelectedEvent(updatedEvent); // Update selected event for popup
    setIsEventPopupOpen(true); // Reopen event details popup with updated event
  };

  const handleAddEvent = async (newEvent) => {
    const formattedEvent = {
        id: newEvent._id,
        title: newEvent.title,
        start: new Date(`${newEvent.startDate}T${newEvent.startTime}`), 
        end: new Date(`${newEvent.endDate}T${newEvent.endTime}`),
        customer: newEvent.customer,
        serial: newEvent.serial,
        model: newEvent.model,
        address: newEvent.address,
        serviceType: newEvent.serviceType,
        description: newEvent.description,
        technicians: newEvent.technicians,
        addTechnicians: newEvent.addTechnicians,
    };
    setEvents((prevEvents) => {
      return [...prevEvents, formattedEvent];
  });
};

  // Function to handle technician checkbox change
  const handleTechnicianChange = (event) => {
    const technician = event.target.value;
    setSelectedTechnicians(prevTechs => {
      if (prevTechs.includes(technician)) {
        return prevTechs.filter(tech => tech !== technician);
      } else {
        return [...prevTechs, technician];
      }
    });
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  // Function to get event props based on technician
  const eventPropGetter = (event) => {
    const technician = event.technicians; // Assuming technicians is an array
    const color = technicianColors[technician] || '#d3d3d3'; // Default color if technician not found
    return { style: { backgroundColor: color } };
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: windowSize.height * 0.7, width: windowSize.width * 0.99, }}>
      <div style={{ flex: 1 }}>
        {userRights >= roles['SALES'] ? (
          <Button variant="contained" color="primary" onClick={() => setIsEventFormOpen(true)} style={{ marginBottom: '10px' }}>
            {GetTextByRegion("Add New Event", selectedRegion)}
          </Button>) : null}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <SearchBar searchQuery={searchTerm} setSearchQuery={setSearchTerm} placeholder={GetTextByRegion("Search Events...", selectedRegion)} />
          {windowSize.width < maxWidth && (
            <Button variant="outlined" onClick={() => setFilterDialogOpen(true)} style={{ marginLeft: '15px' }}>
              {GetTextByRegion("Filter", selectedRegion)}
            </Button>
          )}
        </div>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          timeslots={4}
          step={15}
          onSelectEvent={handleEventClick}
          eventPropGetter={eventPropGetter}
        />
      </div>
      {windowSize.width > maxWidth && (
        <div style={{ marginLeft: '20px' }}>
          <FormGroup>
            <InputLabel>{GetTextByRegion("Filter by Technicians", selectedRegion)}</InputLabel>
            {Object.keys(technicianColors).map((technician, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={selectedTechnicians.includes(technician)}
                    onChange={handleTechnicianChange}
                    value={technician}
                  />
                }
                label={technician}
              />
            ))}
          </FormGroup>
        </div>
      )}

      <Dialog open={filterDialogOpen} onClose={() => setFilterDialogOpen(false)}>
        <DialogTitle>{GetTextByRegion("Filter by Technicians", selectedRegion)}</DialogTitle>
        <DialogContent>
          <FormGroup>
            {Object.keys(technicianColors).map((technician, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={selectedTechnicians.includes(technician)}
                    onChange={handleTechnicianChange}
                    value={technician}
                  />
                }
                label={technician}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFilterDialogOpen(false)} color="primary">
            {GetTextByRegion("Close", selectedRegion)}
          </Button>
        </DialogActions>
      </Dialog>

      <EventDetailsPopup
        open={isEventPopupOpen}
        onClose={handleClosePopup}
        onEdit={handleEditEvent}
        onDelete={handleDeleteEvent}
        event={selectedEvent}
        seletedRegion={selectedRegion}
      />
      {isEditFormOpen && (
        <EditEventForm
          event={selectedEvent}
          onClose={() => setIsEditFormOpen(false)}
          onEditSuccess={handleEditSuccess}
        />
      )}
      {isEventFormOpen && (
        <EventForm
          onClose={() => setIsEventFormOpen(false)}
          onAdd={handleAddEvent}
        />
      )}
      {/* Error Dialog */}
      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
};

export default MyCalendar;
