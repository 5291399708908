import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions,
  Grid, Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import axios from 'axios';
import ErrorDialog from '../ErrorDialog';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
import MachineModelDropdown from './MachineModelDropdown';
import roles from '../../hooks/Roles';
import AlertDialog from './AlertDialog';
import LoadingSpinner from '../LoadingSpinner';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Theme, useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const ChinaProductionForm = ({ open, onClose, machineData, onSubmit, mode, onChange, region, viewOnly = false, tabValue }) => {
  const { selectedRegion, user: { userRights } } = useUser();
  const theme = useTheme();
  const [isView, setIsView] = useState(viewOnly);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false); // State to control the dialog
  const [deliveryDateAlertChecked, setDeliveryDateAlertChecked] = useState(false); // Track the checkbox state
  const [hasDeliveryDateChanged, setHasDeliveryDateChanged] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [names, setNames] = useState([]);
  const [machine, setMachine] = useState({
    machineModelAndConfig: '',
    machineNo: '',
    customer: '',
    sales: '',
    orderReceivedDate: '',
    leadTimeOrDeliveryDate: '',
    readyShipDate: '',
    actualShipDate: '',
    status: "Incoming materials pending",
    financialRelease: '',
    specialConfiguration: '',
    tube: '',
    detector: '',
    pc: '',
    monitor: '',
    poNumber: '',
    options: '',
    software: '',
    stage: tabValue === 0 ? 'Production' :
      tabValue === 1 ? 'Stock' :
        tabValue === 2 ? 'Shipped' :
          tabValue === 3 ? 'Demo' : '',
    regionTo: (region && region.region) || '', // Safely handle undefined region
    warranty: '',
    lastModifiedStatus: '',
    machineVoltage: '',
  });
  const [validationErrors, setValidationErrors] = useState({
    machineModelAndConfig: '',
    machineNo: '',
  });
  const [initialDeliveryDate, setInitialDeliveryDate] = useState(machineData?.leadTimeOrDeliveryDate || '');

   //fetch the all options 
   useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await axios.get(`${backendString}/dropdown?selectedRegion=${selectedRegion}&type=option`);
        setNames(response.data.map(item => item.ModelName));
      } catch (error) {

      }
    }
    fetchOptions();
  }, []);

  useEffect(() => {
    if ((mode === 'edit' || mode === 'view') && machineData) {
      setMachine({
        ...machineData,
      });
      if (!Array.isArray(machineData.options)) {
        
        let newOptions = machineData.options.split(",");
        newOptions.forEach(option => {
          if(!names.includes(option)){
            setNames([...names,option]);
          }
        });    
        setSelectedOptions(newOptions);
      }
    }
  }, [names]);

  useEffect(() => {
    if (machine.leadTimeOrDeliveryDate && machine.leadTimeOrDeliveryDate !== initialDeliveryDate) {
      setHasDeliveryDateChanged(true);
    } else {
      setHasDeliveryDateChanged(false);
    }
  }, [machine.leadTimeOrDeliveryDate, initialDeliveryDate]);

 

  const handleChange = (e) => {

    const { name, value, type, checked } = e.target;

    if (name === 'options') {
      setSelectedOptions(value);
    }
    setMachine({
      ...machine,
      [name]: type === 'checkbox' ? checked : name === 'options' ? typeof value === 'string' ? value.split(',') : value : value
    });

    if (onChange) {
      onChange(e);
    }

  };

  const handleCheckboxChange = (event) => {
    handleChange(event); // Existing handler for state updates
    setDeliveryDateAlertChecked(event.target.checked); // Track checkbox state
    if (event.target.checked) {
      setOpenAlert(true); // Open the dialog when checked
    }
  };

  const validateForm = () => {
    const errors = {
      machineModelAndConfig: '',
      machineNo: '',
    };
    let isValid = true;
    if (!machine.machineModelAndConfig) {
      errors.machineModelAndConfig = GetTextByRegion('Machine Model and Config is required', selectedRegion);
      isValid = false;
    }
    if (!machine.machineNo) {
      errors.machineNo = GetTextByRegion('Machine No. is required', selectedRegion);
      isValid = false;
    }
    setValidationErrors(errors);
    return isValid;
  };

  const handleClose = () => {
    setValidationErrors({});
    setMachine({
      machineModelAndConfig: '',
      machineNo: '',
      customer: '',
      sales: '',
      orderReceivedDate: '',
      leadTimeOrDeliveryDate: '',
      readyShipDate: '',
      actualShipDate: '',
      status: 'Hardware assembly and installation',
      financialRelease: '',
      specialConfiguration: '',
      tube: '',
      detector: '',
      pc: '',
      monitor: '',
      poNumber: '',
      options: '',
      software: '',
      stage: tabValue === 0 ? 'Production' :
        tabValue === 1 ? 'Stock' :
          tabValue === 2 ? 'Shipped' :
            tabValue === 3 ? 'Demo' : '',
      regionTo: '',
      warranty: '',
      lastModifiedStatus: '',
      machineVoltage: '',
    });
    onClose();
  }

  const handleSubmit = async (e) => {

    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    try {
      if (Array.isArray(machine.options)) {
        machine.options = machine.options.toString();
        machineData = machine;
      }
      if (mode === 'edit' || mode === 'view') {
        await onSubmit(machine);
      } else {
        const response = await axios.post(backendString + `/chinaproduction?`, machine, {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          }
        });
        onSubmit(response.data);
      }
      handleClose();
    } catch (error) {
      console.error('Error submitting machine:', error);
      setError(error.response?.data?.error || 'Error submitting machine');
    }
    finally {
      setLoading(false);
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleEdit = () => {
    setIsView(false)
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  function getStyles(name, selectedOptions, theme) {
    return {
      fontWeight: selectedOptions.includes(name)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    };
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>{mode === 'edit' || mode === 'view' ? GetTextByRegion('Edit Machine', selectedRegion) : GetTextByRegion('Add New Machine', selectedRegion)}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} mt={0.1}>
            <Grid item xs={12} sm={6}>
              <MachineModelDropdown  //CHINA ONLY
                selectedRegion={selectedRegion}
                isView={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}
                validationErrors={validationErrors}
                GetTextByRegion={GetTextByRegion}
                handleChange={handleChange}
                machine={machine}
                type="both" // Set to "both machine and racks"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label={GetTextByRegion("Machine No.", selectedRegion)}
                name="machineNo"
                value={machine.machineNo}
                onChange={handleChange}
                fullWidth
                disabled={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}
                error={!!validationErrors.machineNo && !machine.machineNo}
                helperText={!machine.machineNo ? validationErrors.machineNo : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={GetTextByRegion("Customer", selectedRegion)}
                name="customer"
                disabled={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}
                value={machine.customer}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={GetTextByRegion("PO Number", selectedRegion)}
                name="poNumber"
                disabled={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}
                value={machine.poNumber}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={GetTextByRegion("Sales", selectedRegion)}
                name="sales"
                disabled={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}
                value={machine.sales}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={GetTextByRegion("Order Received Date", selectedRegion)}
                name="orderReceivedDate"
                type="date"
                disabled={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}
                value={machine.orderReceivedDate}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={GetTextByRegion("Lead Time/Delivery Date", selectedRegion)}
                name="leadTimeOrDeliveryDate"
                type="date"
                disabled={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}
                value={machine.leadTimeOrDeliveryDate}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>

            {hasDeliveryDateChanged && mode === 'edit' && mode === 'add' && (
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="deliveryDateAlert"
                      checked={deliveryDateAlertChecked}
                      onChange={handleCheckboxChange}
                    />
                  }
                  disabled={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}
                  label={GetTextByRegion("Delivery Date Alert", selectedRegion)}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <TextField
                label={GetTextByRegion("Ready Ship Date", selectedRegion)}
                name="readyShipDate"
                type="date"
                disabled={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}
                value={machine.readyShipDate}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={GetTextByRegion("Actual Ship Date", selectedRegion)}
                name="actualShipDate"
                type="date"
                disabled={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}
                value={machine.actualShipDate}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>{GetTextByRegion("Financial Release", selectedRegion)}</InputLabel>
                <Select
                  name="financialRelease"
                  value={machine.financialRelease}
                  disabled={isView || (!isView && (userRights !== roles['SALES'])) || selectedRegion !== 'CHINA'}
                  onChange={handleChange}
                  label={GetTextByRegion("Financial Release", selectedRegion)}
                >
                  <MenuItem value="Deposit paid, ready to ship">{GetTextByRegion("Deposit paid, ready to ship", selectedRegion)}</MenuItem>
                  <MenuItem value="Final payment, permanent license">{GetTextByRegion("Final payment, permanent license", selectedRegion)}</MenuItem>
                  <MenuItem value="Demo Release">{GetTextByRegion("Demo Release", selectedRegion)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth >
                <InputLabel id="demo-multiple-name-label">{GetTextByRegion("Options", selectedRegion)}</InputLabel>
                <Select
                  disabled={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}
                  multiple
                  name='options'
                  value={selectedOptions}
                  onChange={handleChange}
                  input={<OutlinedInput label="Options" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                        width: 250,
                      },
                    },
                  }}
                >
                  {
                    names.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, selectedOptions, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>{GetTextByRegion("Status", selectedRegion)}</InputLabel>
                <Select
                  name="status"
                  value={machine.status}
                  disabled={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT'])) || selectedRegion !== 'CHINA'}
                  onChange={handleChange}
                  label={GetTextByRegion("Status", selectedRegion)}
                >
                  <MenuItem value="Incoming materials pending">{GetTextByRegion("Incoming materials pending", selectedRegion)}</MenuItem>
                  <MenuItem value="Hardware assembly and installation">{GetTextByRegion("Hardware assembly and installation", selectedRegion)}</MenuItem>
                  <MenuItem value="Electrical assembly and installation">{GetTextByRegion("Electrical assembly and installation", selectedRegion)}</MenuItem>
                  <MenuItem value="Test">{GetTextByRegion("Test", selectedRegion)}</MenuItem>
                  <MenuItem value="QA/QC">{GetTextByRegion("QA/QC", selectedRegion)}</MenuItem>
                  <MenuItem value="Re-work">{GetTextByRegion("Re-work", selectedRegion)}</MenuItem>
                  <MenuItem value="QC Release">{GetTextByRegion("QC Release", selectedRegion)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MachineModelDropdown
                selectedRegion={selectedRegion}
                isView={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}
                validationErrors={validationErrors}
                GetTextByRegion={GetTextByRegion}
                handleChange={handleChange}
                machine={machine}
                type="tube" // Set to "tube"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MachineModelDropdown
                selectedRegion={selectedRegion}
                isView={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}
                validationErrors={validationErrors}
                GetTextByRegion={GetTextByRegion}
                handleChange={handleChange}
                machine={machine}
                type="fpd" // Set to "fpd"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={GetTextByRegion("PC", selectedRegion)}
                name="pc"
                value={machine.pc}
                disabled={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MachineModelDropdown
                selectedRegion={selectedRegion}
                isView={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}
                validationErrors={validationErrors}
                GetTextByRegion={GetTextByRegion}
                handleChange={handleChange}
                machine={machine}
                type="monitor" // Set to "software"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MachineModelDropdown
                selectedRegion={selectedRegion}
                isView={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}
                validationErrors={validationErrors}
                GetTextByRegion={GetTextByRegion}
                handleChange={handleChange}
                machine={machine}
                type="software" // Set to "software"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth disabled={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}>
                <InputLabel>{GetTextByRegion("Stage", selectedRegion)}</InputLabel>
                <Select
                  name="stage"
                  value={machine.stage}
                  onChange={handleChange}
                  label={GetTextByRegion("Stage", selectedRegion)}
                >
                  <MenuItem value="Production">{GetTextByRegion("In Production", selectedRegion)}</MenuItem>
                  <MenuItem value="Stock">{GetTextByRegion("Stock", selectedRegion)}</MenuItem>
                  <MenuItem value="Shipped">{GetTextByRegion("Shipped", selectedRegion)}</MenuItem>
                  <MenuItem value="Demo">{GetTextByRegion("Demo", selectedRegion)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth disabled={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}>
                <InputLabel>{GetTextByRegion("Region", selectedRegion)}</InputLabel>
                <Select
                  name="regionTo"
                  value={machine.regionTo}
                  onChange={handleChange}
                  label={GetTextByRegion("Region", selectedRegion)}
                >
                  <MenuItem value="China">{GetTextByRegion("China", selectedRegion)}</MenuItem>
                  <MenuItem value="Overseas">{GetTextByRegion("Overseas", selectedRegion)}</MenuItem>
                  <MenuItem value="EU">{GetTextByRegion("EU", selectedRegion)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={GetTextByRegion("Machine Voltage", selectedRegion)}
                name="machineVoltage"
                disabled={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}
                value={machine.machineVoltage}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth disabled={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}>
                <InputLabel>{GetTextByRegion("Warranty", selectedRegion)}</InputLabel>
                <Select
                  name="warranty"
                  value={machine.warranty}
                  onChange={handleChange}
                  label={GetTextByRegion("Warranty", selectedRegion)}
                >
                  <MenuItem value="12 months">{GetTextByRegion("12 months", selectedRegion)}</MenuItem>
                  <MenuItem value="18 months">{GetTextByRegion("18 months", selectedRegion)}</MenuItem>
                  <MenuItem value="24 months">{GetTextByRegion("24 months", selectedRegion)}</MenuItem>
                  <MenuItem value="36 months">{GetTextByRegion("36 months", selectedRegion)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={GetTextByRegion("Special Configuration", selectedRegion)}
                name="specialConfiguration"
                value={machine.specialConfiguration}
                disabled={isView || (!isView && (userRights < roles['MODERATOR'] && userRights !== roles['SALESASSISTANT']))}
                onChange={handleChange}
                fullWidth
                multiline
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {(mode === 'add' || mode === 'edit' || !isView) && (
            <>
              <Button onClick={handleClose} color="secondary">
                {GetTextByRegion("Close", selectedRegion)}
              </Button>
              <Button onClick={handleSubmit} color="primary" variant="contained">
                {mode === 'edit' || mode === 'view' ? GetTextByRegion('Save', selectedRegion) : GetTextByRegion('Add New Machine', selectedRegion)}
              </Button>
            </>
          )}
          {isView && (
            <>
              <Button onClick={handleClose} color="secondary">
                {GetTextByRegion("Close", selectedRegion)}
              </Button>
              {(userRights >= roles['SALES'] && selectedRegion === 'CHINA' && (
                <Button onClick={handleEdit} variant="contained" color="primary">
                  {GetTextByRegion("Edit", selectedRegion)}
                </Button>
              ))}
            </>
          )}

        </DialogActions>
      </Dialog>

      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />

      <AlertDialog open={openAlert} onClose={() => setOpenAlert(false)} selectedRegion={selectedRegion} />
    </>
  );
};

export default ChinaProductionForm;
