import { Button, TextField,FormControlLabel, Checkbox, Dialog, DialogContent, DialogTitle, DialogActions, IconButton, TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, Grid } from "@mui/material";
import AddMachinePart from "./AddMachinePartView";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
import { useState, useRef, useEffect } from "react";
import ModelDropdown from '../ModelDropdown';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const AddMachineForm = ({ open, onClose, addMachine }) => {
    const { selectedRegion, user } = useUser();
    const [parts, setParts] = useState([]);
    //machine information
    const machineTemplate = {
        model: "",
        inputVoltage: "",
        warranty: false,
        parts: "",   //for parts
    }
    const [currentMachine, setCurrentMachine] = useState(machineTemplate);
    const [selectedPart, setSelectedPart] = useState(null);
    const [addMachinePartView, setAddMachinePartView] = useState(false);
    const requiredFields = [];
    const tableContainerRef = useRef(null);

    ///////////////////////////////////////////////////
    //open the add machine part window
    const openAddMachinePart = () => {
        setAddMachinePartView(true);
    }
    //close the add machine part window
    const closeAddMachinePart = () => {
        setAddMachinePartView(false);
    }
    ///////////////////////////////////////////////////
    //delete the part
    const handleDeleteMachinePart = (part) => {
        setParts((prevParts) => prevParts.filter(item => item !== part));
    }
    ///////////////////////////////////////////////////
    const handleCurrentMachineChange = (event) => {
        const { name, value,checked } = event.target;
        if(name==="warranty"){
            console.log(checked);
            setCurrentMachine((prevMachine) => ({
                ...prevMachine,
                [name]: checked
            }));
        }else{
            setCurrentMachine((prevMachine) => ({
                ...prevMachine,
                [name]: value
            }));
        }
      
    }
    ///////////////////////////////////////////////////
    const addMachineToOrder = ()=>{
        //convert parts to json and store it to machine.parts
        //const jsonString = JSON.stringify(parts, null, 2);
        const updatedMachine = {
            ...currentMachine,
            parts: parts
        };
        addMachine(updatedMachine);
    }

    return (
        <Dialog open={true}>
            <DialogTitle> {GetTextByRegion("Add machine information",selectedRegion)} </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} justifyContent="left" alignItems="center" mt={0}>
                    <Grid item xs={6}>
                        <ModelDropdown
                            value={currentMachine.model}
                            name="model"
                            required
                            onChange={handleCurrentMachineChange}
                            selectedRegion={selectedRegion}
                            type={'both'}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            required={requiredFields.includes("inputVoltage")}
                            label={GetTextByRegion("Input Voltage", selectedRegion)}
                            variant="outlined"
                            value={currentMachine.inputVoltage}
                            name="inputVoltage"
                            onChange={handleCurrentMachineChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={currentMachine.warranty} // Ensure it holds a boolean value
                                    name="warranty"
                                    onChange={handleCurrentMachineChange}
                                />
                            }
                            label={GetTextByRegion("Warranty", selectedRegion)}
                        />
                    </Grid>
                </Grid>
                <Button variant="contained" color="primary" onClick={openAddMachinePart}>
                    {GetTextByRegion("Add",selectedRegion)}
                </Button>
                <TableContainer
                    component={Paper}
                    ref={tableContainerRef}
                    sx={{
                        mt: 2,
                        maxHeight: 300,
                        overflow: 'auto'
                    }}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{GetTextByRegion("Model",selectedRegion)}</TableCell>
                                <TableCell>{GetTextByRegion("Category",selectedRegion)}</TableCell>
                                <TableCell>{GetTextByRegion("Item",selectedRegion)}</TableCell>
                                <TableCell>{GetTextByRegion("Quantity",selectedRegion)}</TableCell>
                                <TableCell>{GetTextByRegion("Notes",selectedRegion)}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {parts.map((part, index) => (
                                <TableRow key={index}>
                                    <TableCell>{part.model}</TableCell>
                                    <TableCell>{part.category}</TableCell>
                                    <TableCell>{part.item}</TableCell>
                                    <TableCell>{part.quantity}</TableCell>
                                    <TableCell>{part.note}</TableCell>
                                    <TableCell>                 
                                        <Button>
                                            <DeleteIcon sx={{ color: 'purple' }} onClick={() => handleDeleteMachinePart(part)} />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <DialogActions>
                    <Button variant="contained" color="secondary" onClick={() => { onClose(); }}>
                        {GetTextByRegion("Cancel", selectedRegion)}
                    </Button>
                    <Button variant="contained" color="primary" onClick={addMachineToOrder}>
                        {GetTextByRegion("Confirm", selectedRegion)}
                    </Button>
                </DialogActions>

            </DialogContent>
            {addMachinePartView && (
                <AddMachinePart open={addMachinePartView} onClose={closeAddMachinePart} model={currentMachine.model} setParts={setParts} />
            )}        
        </Dialog>

    );
}

export default AddMachineForm;