import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, IconButton, Tabs, Tab, Typography, Tooltip } from '@mui/material';
import ErrorDialog from '../ErrorDialog';
import EditIcon from '@mui/icons-material/Edit';
import InventoryIcon from '@mui/icons-material/Inventory';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useWindowSize from '../../hooks/useWindowSize';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import SearchBar from '../SearchBar';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import roles from '../../hooks/Roles';
import ChinaProductionForm from './ChinaProductionForm';
import MobileViewComponent from '../MobileView';
import { GetTextByRegion } from '../../hooks/Translation';
import ChinaProductionColumns from './ChinaProductionColumns';
import { useUser } from '../../hooks/UserContext';
import CustomDataGrid from '../CustomDataGrid'
import DateRangePicker from './DateRangePicker'; // Import your new component
import StatusProgressBar from '../StatusProgressBar';
import ChinaProductionHistoryLog from './ChinaProductionHistoryLog';
import LoadingSpinner from '../LoadingSpinner';
import { format } from 'date-fns';


const backendString = process.env.REACT_APP_BACKEND_STRING;

const ChinaProductionTable = ({ machines, setMachines, openEditDialog, setOpenEditDialog, openDeleteDialog, setOpenDeleteDialog, tabValue, setTabValue }) => {
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [initialMachine, setInitialMachine] = useState(null);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredMachines, setFilteredMachines] = useState([]);
  const [loading, setLoading] = useState(false);
  const { windowSize, smallWidth } = useWindowSize();
  const { selectedRegion, user: { userRights } } = useUser();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isMachineDetailsOpen, setIsMachineDetailsOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [productionColumnVisibilityConfig, setProductionColumnVisibilityModel] = useState();
  const [stockColumnVisibilityConfig, setStockColumnVisibilityModel] = useState();
  const [productionShippedColumnVisibilityConfig, setProductionShippedColumnVisibilityModel] = useState();
  const [demoColumnVisibilityConfig, setDemoColumnVisibilityModel] = useState();

  const LoadColumns = (tabValue) => {
    let tableName = "inproduction";
    switch (tabValue) {
      case 1:
        tableName = "stock";
        break;
      case 2:
        tableName = "productionShipped";
        break;
      case 3:
        tableName = "demo";
        break;
      default:
        tableName = "inproduction";
    }

    return axios
      .get(`${backendString}/loadTableColumns?tableName=${tableName}`, {
        headers: {
          Authorization: sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        const columns = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
        return columns;
      })
      .catch((error) => {

        console.error("Error loading columns:", error);
        return {};
      });
  };

  //init columns
  useEffect(() => {
    const Load = async () => {
      let columns = await LoadColumns(tabValue);
      try {
        switch (tabValue) {
          case 0:
            setProductionColumnVisibilityModel(columns);
            break;
          case 1:
            setStockColumnVisibilityModel(columns);
            break;
          case 2:
            setProductionShippedColumnVisibilityModel(columns);
            break;
          case 3:
            setDemoColumnVisibilityModel(columns);
            break;
          default:
            setProductionColumnVisibilityModel(columns);
            break;
        }

      } catch (error) {
        console.log("error");
        setError(error.response); // Set error state
      }
    }
    Load();
  }, [tabValue]);

  useEffect(() => {
    let filteredMachines;

    if (searchQuery.trim() === '') {
      // Default behavior: Filter by stage based on tabValue
      filteredMachines = machines.filter(machine => {
        if (tabValue === 3) return machine.stage === 'Demo';
        if (tabValue === 2) return machine.stage === 'Shipped';
        if (tabValue === 1) return machine.stage === 'Stock';
        return machine.stage === 'Production';
      });
    } else {
      // Search behavior: Include all machines and filter by search query
      const filteredBySearch = machines.filter(machine =>
        (machine.machineModelAndConfig && machine.machineModelAndConfig.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (machine.machineNo && machine.machineNo.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (machine.poNumber && machine.poNumber.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (machine.leadTimeOrDeliveryDate && machine.leadTimeOrDeliveryDate.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (machine.customer && machine.customer.toLowerCase().includes(searchQuery.toLowerCase()))||
        (machine.sales && machine.sales.toLowerCase().includes(searchQuery.toLowerCase()))
      );

      // Optional: Add date range filtering during search
      filteredMachines = filteredBySearch.filter(machine => {
        if (!startDate && !endDate) return true;
        const machineDate = new Date(machine.leadTimeOrDeliveryDate);
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;
        if (start && machineDate < start) return false;
        if (end && machineDate > end) return false;
        return true;
      });
    }

    filteredMachines.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

    console.log(filteredMachines.map(machine => machine.updatedAt)); // Log updatedAt values

    setFilteredMachines(filteredMachines);
  }, [searchQuery, machines, tabValue, startDate, endDate]);



  const handleEdit = (params) => {
    
 
    setSelectedMachine(params.row);
    setInitialMachine(params.row)
    setOpenEditDialog(true);
  };

  const handleDelete = (params) => {
    setSelectedMachine(params.row);
    setOpenDeleteDialog(true);
  };

  const handleEditSubmit = async (machine) => {
    console.log("hadle submit");
    console.log(machine);
    try {
      setLoading(true);
      if (selectedMachine?.status && initialMachine?.status) {

        if (initialMachine.status !== "Test" && selectedMachine.status === "Test") { //notify china test
          const response = await axios.get(backendString + `/users/email/China Testing`);
          await axios.post(backendString + `/chinaproduction/send-email/testing`, {
            email: response.data.email,
            serial: selectedMachine.machineNo
          });
        }
        else if (initialMachine.status !== "QA/QC" && selectedMachine.status === "QA/QC") { //notify china QA/QC
          const response = await axios.get(backendString + `/users/email/China QAQC`);
          await axios.post(backendString + `/chinaproduction/send-email/qaqc`, {
            email: response.data.email,
            serial: selectedMachine.machineNo
          });
        }
        else if (initialMachine.status !== "Re-work" && selectedMachine.status === "Re-work") { //notify china QA/QC
          const response = await axios.get(backendString + `/users/email/China Testing`);
          await axios.post(backendString + `/chinaproduction/send-email/rework`, {
            email: response.data.email,
            serial: selectedMachine.machineNo
          });
          const response1 = await axios.get(backendString + `/users/email/China QCRelease`);
          await axios.post(backendString + `/chinaproduction/send-email/qcrelease`, {
            email: response1.data.email,
            serial: selectedMachine.machineNo
          });
        }
        else if (initialMachine.status !== "QC Release" && selectedMachine.status === "QC Release") { //notify china QA/QC
          const response = await axios.get(backendString + `/users/email/China QCRelease`);
          await axios.post(backendString + `/chinaproduction/send-email/qcrelease`, {
            email: response.data.email,
            serial: selectedMachine.machineNo
          });
        }
      }

      //used to update the last Modified Status/ReadyShip column
      let updatedMachine = { ...machine };
      if(Array.isArray(updatedMachine.options)){
        updatedMachine.options=updatedMachine.options.toString();
      }

      if (initialMachine) {
        if (initialMachine.status !== selectedMachine.status) {
          updatedMachine.lastModifiedStatus = format(new Date(), 'yyyy-MM-dd');
        }
        if (initialMachine.readyShipDate !== selectedMachine.readyShipDate) {
          updatedMachine.lastModifiedStatus = format(new Date(), 'yyyy-MM-dd');
        }
      }

      const response = await axios.put(backendString + `/chinaproduction/${selectedMachine._id}`, updatedMachine, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        },
      });
      console.log(response);
      setOpenEditDialog(false);
      setMachines(prevData =>
        prevData.map(item =>
          item._id === selectedMachine._id ? response.data : item
        )
      );
    } catch (error) {
      console.error('Error editing machine:', error);
      setError(error.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true);
      await axios.delete(backendString + `/chinaproduction/${selectedMachine._id}`, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
      setOpenDeleteDialog(false);
      setMachines(prevData =>
        prevData.filter(item => item._id !== selectedMachine._id)
      );
    } catch (error) {
      console.error('Error deleting machine:', error);
      setError(error.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenInventory = (params) => {
    setSelectedMachine(params.row);
    setIsMachineDetailsOpen(true);
  };

  const handleCloseInventory = () => {
    setIsMachineDetailsOpen(false);
  };

  const handleOpenNotification = (params) => {
    setSelectedMachine(params.row);
    setIsNotificationOpen(true);
  };

  const handleCloseNotification = () => {
    setIsNotificationOpen(false);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedMachine(null);
    setInitialMachine(null);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedMachine(null);
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setSelectedMachine(prevMachine => ({
      ...prevMachine,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const exportToExcel = () => {
    const dataToExport = filteredMachines.map(machine => {
      const { _id, financialRelease, ...rest } = machine;
      return {
        ...rest,
        financialRelease: financialRelease ? 'Yes' : 'No'
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'chinaProduction.xlsx');
  };

  const exportToCSV = () => {
    const dataToExport = filteredMachines.map(machine => {
      const { _id, financialRelease, ...rest } = machine;
      return {
        ...rest,
        financialRelease: financialRelease ? 'Yes' : 'No'
      };
    });
    const csvData = Papa.unparse(dataToExport);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'chinaProduction.csv';
    link.click();
  };

  const handleTabChange = async (event, newValue) => {
    setTabValue(newValue);
    try {
      const columns = await LoadColumns(tabValue);

      switch (tabValue) {
        case 1:
          setProductionColumnVisibilityModel(columns);
          break;
        case 2:
          setStockColumnVisibilityModel(columns);
          break;
        case 3:
          setProductionShippedColumnVisibilityModel(columns);
          break;
        case 4:
          setDemoColumnVisibilityModel(columns);
          break;
        default:
          setProductionColumnVisibilityModel(columns);
      }
    } catch (error) {
      console.log("Error loading columns:", error);
    }
  };

  const tempColumns = ChinaProductionColumns
    .filter(column => column.required === true) // Filter by required: true
    .map(column => ({
      field: column.name,
      //headerName: GetTextByRegion(column.label, selectedRegion),
      flex: column.name === 'status' ? 2 : 1, // Increase flex for 'status' column
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => {
        return (
          <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion(column.label, selectedRegion)}<br /></Typography>
        );
      },
      renderCell: (params) => {
        if (column.name === 'financialRelease') {
          return params.value ? GetTextByRegion('Yes', selectedRegion) : GetTextByRegion('No', selectedRegion);
        } else if (column.name === 'status') {
          return <StatusProgressBar status={params.value} component={'chinaproduction'} selectedRegion={selectedRegion} />;
        } else {
          return params.value; // Render the cell value as-is for other columns
        }
      }
    }));


  const columns = [
    ...tempColumns,
    {
      field: 'actions',
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion("Actions", selectedRegion)}</Typography>
      ),
      minWidth: 160,
      renderCell: (params) => (
        <div>
          <Tooltip title={GetTextByRegion("Details", selectedRegion)} anrrow>
            <IconButton aria-label="inventory" onClick={() => handleOpenInventory(params)}>
              <InventoryIcon />
            </IconButton>
          </Tooltip>
          {(userRights === roles['SALES'] || userRights > roles['PRODUCTION']) && selectedRegion === "CHINA" && (
            <>
              <Tooltip title={GetTextByRegion("Edit", selectedRegion)} anrrow>
                <IconButton aria-label="edit" color="primary" onClick={() => handleEdit(params)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={GetTextByRegion("Notification", selectedRegion)} anrrow>
                <IconButton aria-label="notification" color='success' onClick={() => handleOpenNotification(params)}>
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          {userRights >= roles['MANAGER'] && selectedRegion === "CHINA" && (
            <Tooltip title={GetTextByRegion("Delete", selectedRegion)} anrrow>
              <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(params)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: windowSize.height * 0.72, width: windowSize.width * 1 }}>
      {loading && <LoadingSpinner />}
      <div style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: !windowSize.width > smallWidth ? 'flex-end' : 'center',
        flexDirection: windowSize.width <= smallWidth ? 'column' : 'row', // Column for mobile, row for larger screens
      }}>
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          placeholder={GetTextByRegion("Search Machines...", selectedRegion)}
        />
        {userRights >= roles['SERVICE'] && (
          <div style={{
            display: 'flex',
            flexDirection: 'row', // Stack buttons in column for mobile
            position: windowSize.width > smallWidth ? 'absolute' : 'relative', // Absolute on larger screens
            left: windowSize.width > smallWidth ? 0 : 'auto', // Align left on larger screens
          }}>
            <Button onClick={exportToCSV} sx={{ marginRight: 1 }}>
              {GetTextByRegion("Export to CSV", selectedRegion)}
            </Button>
            <Button onClick={exportToExcel}>
              {GetTextByRegion("Export to Excel", selectedRegion)}
            </Button>
          </div>
        )}

        {/* <DateRangePicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} /> */}
      </div>


      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label={GetTextByRegion("In Production", selectedRegion)} />
        <Tab label={GetTextByRegion("Stock", selectedRegion)} />
        <Tab label={GetTextByRegion("Shipped", selectedRegion)} />
        <Tab label={GetTextByRegion("Demo", selectedRegion)} />
      </Tabs>

      {windowSize.width > smallWidth ? (
        tabValue === 0 ? (<CustomDataGrid rows={filteredMachines} columns={columns} visibilityColumnConfig={productionColumnVisibilityConfig} setColumnVisibility={setProductionColumnVisibilityModel} tableName={'inproduction'} />) :
          tabValue === 1 ? (<CustomDataGrid rows={filteredMachines} columns={columns} visibilityColumnConfig={stockColumnVisibilityConfig} setColumnVisibility={setStockColumnVisibilityModel} tableName={'stock'} />) :
            tabValue === 2 ? (<CustomDataGrid rows={filteredMachines} columns={columns} visibilityColumnConfig={productionShippedColumnVisibilityConfig} setColumnVisibility={setProductionShippedColumnVisibilityModel} tableName={'productionShipped'} />) :
              (<CustomDataGrid rows={filteredMachines} columns={columns} visibilityColumnConfig={demoColumnVisibilityConfig} setColumnVisibility={setDemoColumnVisibilityModel} tableName={'demo'} />)

      ) : (<MobileViewComponent
        items={filteredMachines}
        columnData={ChinaProductionColumns}
        handleEditClick={selectedRegion === "CHINA" ? handleEdit : null}
        handleDelete={selectedRegion === "CHINA" ? handleDelete : null}
        handleView={handleOpenInventory}
        selectedRegion={selectedRegion}
        component={'chinaproduction'} />
      )}



      {openEditDialog && (
        <ChinaProductionForm
          open={openEditDialog}
          onClose={handleCloseEditDialog}
          machineData={selectedMachine}
          onChange={handleInputChange}
          onSubmit={handleEditSubmit}
          mode="edit"
          viewOnly={false}
        />
      )}

      {isMachineDetailsOpen && (
        <ChinaProductionForm
          open={isMachineDetailsOpen}
          onClose={handleCloseInventory}
          machineData={selectedMachine}
          onChange={handleInputChange}
          onSubmit={handleEditSubmit}
          mode="view"
          viewOnly={true}
        />
      )}

      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteConfirm}
        selectedRegion={selectedRegion}
      />

      <ChinaProductionHistoryLog
        open={isNotificationOpen}
        onClose={handleCloseNotification}
        selectedRegion={selectedRegion}
        machine={selectedMachine}
        setMachine={setMachines}
      />

      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
};

export default ChinaProductionTable;