import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, Checkbox, TextField } from '@mui/material';
import ErrorDialog from '../ErrorDialog';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
import axios from 'axios';
import SearchBar from '../SearchBar';
import CustomDataGrid from '../CustomDataGrid';
import MobileViewComponent from '../MobileView';
import useWindowSize from '../../hooks/useWindowSize';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import PlaceHolderImage from '../../images/placeholder-image.jpg';
import ImageDialog from './ImageDialog';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const InventoryCheckPopup = ({ open, onClose, items, setItems }) => {
    const [error, setError] = useState(null);
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [localItems, setLocalItems] = useState([""]);
    const [openResetDialog, setOpenResetDialog] = useState(false);
    const [isResetPressed, setIsResetPressed] = useState(false);
    const [openImageDialog, setOpenImageDialog] = useState(false); // State to control the dialog
    const [selectedImage, setSelectedImage] = useState(''); // State for the selected image
    const { selectedRegion, user: { userRights, username } } = useUser();
    const { windowSize, smallWidth } = useWindowSize();

    useEffect(() => {
        setLocalItems(
            items.map(item => ({
                ...item,
                inventoryCheck: item.inventoryCheck ?? false
            }))
        );
    }, [items]);


    useEffect(() => {
        const filtered = localItems.filter(item =>
            item.partnumber?.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredItems(filtered);
    }, [searchQuery, localItems]);

    const handleInventoryCheckChange = (id, checked) => {
        setLocalItems(prev =>
            prev.map(item =>
                item._id === id ? { ...item, inventoryCheck: checked } : item
            )
        );
    };

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc); // Set the selected image
        setOpenImageDialog(true);   // Open the dialog
    };

    const handleSave = async () => {
        //case when reset is pressed before the save.
        //if pressed then add to history log
        if (isResetPressed) {
            try {
                const historyEntry = {
                    partnumber: "Inventory Check Complete",
                    username: username,
                    action: "Inventory Check",
                    timestamp: new Date().toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                    }).replace(',', ''), // Remove the comma between date and time
                };

                await axios.post(`${backendString}/itemhistories?selectedRegion=${selectedRegion}`, historyEntry, {
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                    },
                });

            } catch (err) {
                console.log(err);
                setError("Failed to reset checkboxes.");
            } finally {
                setIsResetPressed(false);
            }
        }
        try {
            // Determine changes in inventoryCheck or quantity
            const updates = localItems
                .filter((item) => {
                    const originalItem = items.find((i) => i._id === item._id);
                    return (
                        item.inventoryCheck !== originalItem?.inventoryCheck ||
                        item.quantity !== originalItem?.quantity
                    );
                })
                .map(({ _id, partnumber, quantity, inventoryCheck }) => {
                    const originalItem = items.find((i) => i._id === _id);
                    const quantityChange = quantity - (originalItem?.quantity || 0);
                    return {
                        _id,
                        partnumber,
                        fields: { inventoryCheck, quantity },
                        quantityChange,
                    };
                });

            if (updates.length > 0) {
                await axios.put(`${backendString}/items/partnumber/bulk`, updates, {
                    params: { selectedRegion },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': sessionStorage.getItem('token'),
                    },
                });

                // Post a history entry for each updated quantity
                for (const { partnumber, quantityChange } of updates) {
                    if (quantityChange > 0) {
                        const historyEntry = {
                            partnumber: partnumber,
                            purpose: "Inventory Check",
                            action: GetTextByRegion('QUANTITY UPDATE', selectedRegion),
                            username: username,
                            timestamp: new Date().toLocaleString('en-US', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false,
                            }).replace(',', ''), // Remove the comma between date and time
                            quantity: quantityChange,
                        };

                        await axios.post(`${backendString}/itemhistories?selectedRegion=${selectedRegion}`, historyEntry, {
                            headers: {
                                'Authorization': sessionStorage.getItem('token'),
                            },
                        });
                    }

                }

                // Update parent state with changes
                setItems(localItems);
                onClose();
            } else {
                console.log('No changes to save.');
            }
        } catch (err) {
            console.error('Error saving inventory checks and quantities:', err);
            setError('Failed to save changes.');
        }
    };

    const resetConfirm = () => {
        setIsResetPressed(true);
        setLocalItems(prev =>
            prev.map(item => ({ ...item, inventoryCheck: false }))
        )
        setOpenResetDialog(false);
    }

    const handleCloseResetDialog = () => {
        setOpenResetDialog(false);
    };

    const handleQuantityChange = (id, value) => {
        setLocalItems((prev) =>
            prev.map((item) =>
                item._id === id ? { ...item, quantity: value } : item
            )
        );
    };

    // const columns = [
    //     {
    //         field: 'partnumber',
    //         flex: 4,
    //         headerAlign: 'center',
    //         align: 'center',
    //         renderHeader: () => (
    //             <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
    //                 {GetTextByRegion('Part Number', selectedRegion)}<br />
    //             </Typography>
    //         ),
    //     },
    //     {
    //         field: 'quantity',
    //         flex: 4,
    //         headerAlign: 'center',
    //         align: 'center',
    //         renderHeader: () => (
    //             <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
    //                 {GetTextByRegion('Quantity', selectedRegion)}<br />
    //             </Typography>
    //         ),
    //         renderCell: (params) => (
    //             <TextField
    //                 variant="standard"
    //                 size="small"
    //                 value={params.row.quantity ?? ""}
    //                 onChange={(e) => handleQuantityChange(params.row._id, e.target.value)}
    //                 inputProps={{
    //                     style: { textAlign: 'center' }, // Center-align the input
    //                 }}
    //             />
    //         ),
    //     },
    //     {
    //         field: 'inventoryCheck',
    //         flex: 3,
    //         headerAlign: 'center',
    //         align: 'center',
    //         renderHeader: () => (
    //             <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
    //                 {GetTextByRegion('Inventory Check', selectedRegion)}<br />
    //             </Typography>
    //         ),
    //         renderCell: (params) => (
    //             <Checkbox
    //                 checked={params.row.inventoryCheck}
    //                 onChange={(e) => handleInventoryCheckChange(params.row._id, e.target.checked)}
    //             />
    //         ),
    //     },
    // ];

    const columns = [
        {
            field: 'picture',
            flex: 3,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => (
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                    {GetTextByRegion('Image', selectedRegion)}<br />
                </Typography>
            ),
            renderCell: (params) => {
                // Construct the image URL
                const imageUrl = params.row.image
                    ? `${backendString}/items/images/${params.row.image}?selectedRegion=${selectedRegion}`
                    : PlaceHolderImage;

                return (
                    <img
                        src={imageUrl}
                        alt={`${imageUrl}`}
                        onClick={() => handleImageClick(`${imageUrl}`)} // Open dialog on click
                        style={{
                            width: '50px',
                            height: '50px',
                            objectFit: 'contain',
                        }}
                    />
                );
            },
        },
        {
            field: 'partnumber',
            flex: 4,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => (
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                    {GetTextByRegion('Part Number', selectedRegion)}<br />
                </Typography>
            ),
        },
        {
            field: 'quantity',
            flex: 4,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => (
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                    {GetTextByRegion('Quantity', selectedRegion)}<br />
                </Typography>
            ),
            renderCell: (params) => (
                <TextField
                    variant="standard"
                    size="small"
                    value={params.row.quantity ?? ""}
                    onChange={(e) => handleQuantityChange(params.row._id, e.target.value)}
                    inputProps={{
                        style: { textAlign: 'center' }, // Center-align the input
                    }}
                />
            ),
        },
        {
            field: 'inventoryCheck',
            flex: 3,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => (
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                    {GetTextByRegion('Inventory Check', selectedRegion)}<br />
                </Typography>
            ),
            renderCell: (params) => (
                <Checkbox
                    checked={params.row.inventoryCheck}
                    onChange={(e) => handleInventoryCheckChange(params.row._id, e.target.checked)}
                />
            ),
        },
    ];

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
                <DialogTitle>{GetTextByRegion("Inventory Check", selectedRegion)}</DialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column">
                        <SearchBar
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            placeholder={GetTextByRegion("Search Part Number...", selectedRegion)}
                        />
                        <Box height={windowSize.height * 0.65} display="flex" flexDirection="column">

                            <CustomDataGrid rows={filteredItems} columns={columns} />

                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box flex="1">
                        <Button
                            onClick={() => setOpenResetDialog(true)}
                            color="error"
                        >
                            {GetTextByRegion("Reset Checkboxes", selectedRegion)}
                        </Button>
                    </Box>
                    <Box display="flex" gap={1}>
                        <Button onClick={onClose}>
                            {GetTextByRegion("Close", selectedRegion)}
                        </Button>
                        <Button onClick={handleSave} variant="contained" color="primary">
                            {GetTextByRegion("Save", selectedRegion)}
                        </Button>
                    </Box>
                </DialogActions>
                <ErrorDialog
                    open={!!error}
                    onClose={() => setError(null)}
                    errorMessage={error}
                />
            </Dialog>

            <DeleteConfirmationDialog
                open={openResetDialog}
                onClose={handleCloseResetDialog}
                onConfirm={resetConfirm}
                selectedRegion={selectedRegion}
                title="Delete Item"
                content="Are you sure you want to reset checkboxes?"
                buttonText="Confirm"
            />

            <ImageDialog
                open={openImageDialog}
                onClose={() => setOpenImageDialog(false)}
                imageSrc={selectedImage}
            />
        </>
    );
};


export default InventoryCheckPopup;
