import React, { useState } from 'react';
import axios from 'axios';
import { Box, IconButton, Typography, Tooltip, Button } from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetTextByRegion } from '../../hooks/Translation';
import roles from '../../hooks/Roles';
import CustomDataGrid from '../CustomDataGrid';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import ErrorDialog from '../ErrorDialog';
import useWindowSize from '../../hooks/useWindowSize';
import ProductionPartForm from '../ProductionParts/ProductionPartForm';
import { generatePartPDF } from './GeneratePartPDF'


const ProductionPartTable = ({ data, setData, userRights, selectedRegion }) => {
    const backendString = process.env.REACT_APP_BACKEND_STRING || '';
    const [selectedItem, setSelectedItem] = useState();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openErrorDialog, setErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { windowSize } = useWindowSize();

    const commonStyles = { fontSize: '14px', fontWeight: 'bold' };

    const columns = [
        {
            field: 'poNumber',
            headerName: GetTextByRegion("PO Number", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>PO Number</Typography>,
        },
        {
            field: 'applicationDate',
            headerName: GetTextByRegion("Application Date", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>Application Date</Typography>,
        },
        {
            field: 'partNumber',
            headerName: GetTextByRegion("Part Number", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>Part Number</Typography>,
            renderCell: (params) => params.value?.join(', '), // Display array as a comma-separated string
        },
        {
            field: 'price',
            headerName: GetTextByRegion("Price", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>Price</Typography>,
            renderCell: (params) => params.value?.join(', '), // Handle multiple prices
        },
        {
            field: 'quantity',
            headerName: GetTextByRegion("Quantity", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>Quantity</Typography>,
            renderCell: (params) => params.value?.join(', '), // Handle multiple quantities
        },
        {
            field: 'rate',
            headerName: GetTextByRegion("Rate", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>Rate</Typography>,
            renderCell: (params) => params.value?.join(', '), // Handle multiple quantities
        },
        {
            field: 'incoterm',
            headerName: GetTextByRegion("Incoterm", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>Incoterm</Typography>,
        },
        {
            field: 'paymentMethod',
            headerName: GetTextByRegion("Payment Method", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>Payment Method</Typography>,
        },
        {
            field: 'paymentTerms',
            headerName: GetTextByRegion("Payment Term", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>Payment Terms</Typography>,
        },
        {
            field: 'vendorName',
            headerName: GetTextByRegion("Vendor Name", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>Vendor Name</Typography>,
        },
        {
            field: 'vendorAddress',
            headerName: GetTextByRegion("Vendor Address", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>Vendor Address</Typography>,
        },
        {
            field: 'notes',
            headerName: GetTextByRegion("Notes", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>Notes</Typography>,
        },
        {
            field: 'actions',
            headerName: GetTextByRegion("Actions", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>Actions</Typography>,
            renderCell: (params) => (
                userRights >= roles['SALES'] && (
                    <Box>
                        <Tooltip title={GetTextByRegion("Edit", selectedRegion)} arrow>
                            <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(params)}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={GetTextByRegion("download PDF", selectedRegion)} arrow>
                            <IconButton
                                color="success"
                                onClick={() => handleGeneratePDF(params)}
                                aria-label="generate-pdf"
                            >
                                <FileDownloadIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={GetTextByRegion("Delete", selectedRegion)} arrow>
                            <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(params)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>

                    </Box>
                )
            ),
        },
    ];

    const handleDelete = (params) => {
        setSelectedItem(params.row);
        setOpenDeleteDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setSelectedItem(null);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedItem(null);
    };

    const handleDeleteConfirm = async () => {
        try {
            await axios.delete(`${backendString}/productionParts/${selectedItem._id}`, {
                headers: {
                    'Authorization': sessionStorage.getItem('token'),
                },
            });
            setData(prevData => prevData.filter(item => item._id !== selectedItem._id));
            setOpenDeleteDialog(false);
        } catch (error) {
            setErrorMessage(error.response?.data?.error || "Error deleting record");
            handleOpenErrorDialog();
        }
    };

    const handleEditClick = (params) => {
        setSelectedItem(params.row);
        setOpenEditDialog(true);
    };

    const handleConfirmClick = (params) => {
        setSelectedItem(params.row);
        setOpenConfirmDialog(true);
    };

    const handleOpenErrorDialog = () => setErrorDialog(true);
    const handleCloseErrorDialog = () => {
        setErrorDialog(false);
        setErrorMessage('');
    };

    const handleGeneratePDF = (report) => {
        generatePartPDF(report.row, (doc) => {
            doc.save('productionPart_.pdf');
        }, (error) => {
            console.error('Failed to generate PDF:', error);
        }, selectedRegion);
    };


    return (
        <div style={{ height: windowSize.height * 0.77 }}>


            <CustomDataGrid rows={data} columns={columns} />

            {openDeleteDialog && (
                <DeleteConfirmationDialog
                    open={openDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                    onConfirm={handleDeleteConfirm}
                    selectedRegion={selectedRegion}
                />
            )}

            {openEditDialog && (
                <ProductionPartForm open={openEditDialog} onClose={handleCloseEditDialog} editData={selectedItem} setData={setData} />
            )}

            {openErrorDialog && (
                <ErrorDialog open={openErrorDialog} onClose={handleCloseErrorDialog} message={errorMessage} />
            )}
        </div>
    );
};

export default ProductionPartTable;
