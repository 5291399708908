const regionIndices = {
  "USA": 0,
  "CHINA": 1,
  "EU": 2,
  "MEXICO": 3,
  "TAIWAN": 4
};

const currencySymbols = {
  US: '$',
  EU: '$',
  MEXICO: '$',  // Mexico
  CHINA: '$',  // China (uses Yuan)
  TAIWAN: '$', // Taiwan (New Taiwan Dollar)
};

//Index order : USA , China, EU, Mexico, Taiwan

const translations = {
  //homePage
  "LOW QUANTITY ITEMS": ["LOW QUANTITY ITEMS", "低库存项目", "LOW QUANTITY ITEMS", "ARTÍCULOS DE BAJA CANTIDAD", "低庫存項目"],
  "Min Quantity": ["Min Quantity", "最小数量", "Min Quantity", "Cantidad Mínima", "最小數量"],
  "No low quantity items found": ["No low quantity items found", "未找到低库存项目", "No low quantity items found", "No se encontraron artículos de baja cantidad", "未找到低庫存項目"],


  //NavBar
  "Inventory": ["Inventory", "库存", "Inventory", "Inventario", "庫存"],
  "Part Requests": ["Part Requests", "部件请求", "Part Request", "Solicitud de Piezas", "部件請求"],
  "Machines": ["Machines", "机器", "Machines", "Máquinas", "機器"],
  "Search Machines...": ["Search Machines...", "搜索机器...", "Search Machines...", "Buscar Máquinas...", "搜尋機器..."],
  "Calendar": ["Calendar", "日历", "Calendar", "Calendario", "日曆"],
  "BOMs": ["BOMs", "物料清单(BOMs)", "BOMs", "Lista de Materiales", "物料清單(BOMs)"],
  "Explosion Drawings": ["Machine Drawings", "机器图", "Machine Drawings", "Diagramas de máquinas", "機器設計圖"],
  "Reports": ["Reports", "报告", "Reports", "Informes", "報告"],
  "China": ["China", "中国", "China", "China", "中國"],
  "Service Orders": ["Service Orders", "服务订单", "Service Orders", "Órdenes de Servicio", "服務訂單"],
  "Extra": ["Extra", "额外", "Extra", "Extra", "額外(Extra)"],
  "Todo": ["Todo", "待办事项", "Todo", "Por Hacer", "待辦事項(Todo)"],
  "Q&A": ["Q&A", "问答(Q&A)", "Q&A", "Preguntas y Respuestas", "問答(Q&A)"],
  "Production": ["Production", "生产部", "Production", "Producción", "生產部"],
  "Production-China": ["Production - China", "生产部 - 中国", "Production-China", "Producción-China", "生產部 - 中國"],
  "Production (Overseas)": ["Production - Overseas", "生产部 - 海外", "Production - Overseas", "Producción - exterior", "生產部 - 海外"],
  "Production-EU": ["Production - EU", "生产部 - 欧盟", "Production - EU", "Producción - EU", "生產部 - 歐盟"],
  "Remote Service": ["Remote Service", "远程服务", "Remote Service", "Servicio Remoto", "遠程服務"],
  "Part Request Log": ["Part Request Log", "部件索取日志", "Part Request Log", "Registro de Solicitud de Piezas", "部件索取日誌"],
  "Part Request Log - Overseas": ["Part Request Log - Overseas", "部件索取日志 - 海外", "Part Request Log - Overseas", "Registro de Solicitud de Piezas - exterior", "部件索取日誌 - 海外"],
  "Part Request Log - EU": ["Part Request Log - EU", "部件索取日志 - 欧盟", "Part Request Log - EU", "Registro de Solicitud de Piezas - EU", "部件索取日誌 - 歐盟"],
  "Part Request Log - China": ["Part Request Log - China", "部件索取日志 - 中国", "Part Request Log - China", "Registro de Solicitud de Piezas - China", "部件索取日誌 - 中國"],
  //Profile Page
  "Profile": ["Profile", "个人资料", "Profile", "Perfil", "個人檔案"],
  "Username": ["Username", "使用者", "Username", "Nombre de usuario", "使用者"],
  "Current Password": ["Current Password", "当前密码", "Current Password", "Contraseña actual", "當前密碼"],
  "New Password": ["New Password", "新密码", "New Password", "Nueva contraseña", "新密碼"],
  "Confirm Password": ["Confirm Password", "确认密码", "Confirm Password", "Confirmar contraseña", "確認密碼"],
  "Update Profile": ["Update Profile", "更新个人资料", "Update Profile", "Actualizar perfil", "更新個人檔案"],
  "Reset Columns": ["Reset Columns", "重置列表", "Reset Columns", "Para reiniciar las columnas", "重置列表"],

  //Profile Menu
  "Logout": ["Logout", "登出", "Sign out", "cerrar sesión", "登出"],
  "LogIn": ["LogIn", "登入", "Sign In", "iniciar sesión", "登入"],
  //Setting Menu
  "Models": ["Models", "型号", "Models", "Modelos", "型號"],
  "Users": ["Users", "使用者", "Users", "Usuarios", "使用者"],
  "Customers": ["Customers", "客戶", "Clients", "Clientes", "客戶"],
  //BarGraph 
  "Issue Types by Model": ["Issue Types by Model", "按机型划分的问题类型", "Issue Types by Model", "Tipos de problemas por modelo", "按機型劃分的問題類型"],
  "Machine Issues by Models": ["Machine Issues by Models", "按型号划分的机器问题", "Machine Issues by Models", "Problemas de Máquinas por Modelos", "按型號劃分的機器問題"],
  "Hardware": ["Hardware", "硬件", "Hardware", "hardware", "硬體"],
  "Software": ["Software", "軟件", "Software", "Software", "軟體"],
  "Cabinet": ["Cabinet", "机柜", "Cabinet", "Gabinete", "機殼"],
  "Scanner": ["Scanner", "扫描仪", "Scanner", "Escáner", "掃描儀"],
  "Number of Issues": ["Number of Issues", "问题数量", "Number of Issues", "Número de problemas", "問題數量"],
  //CompletedMachinePieChart
  "Completed Models": ["Completed Models", "已完成的机台", "Completed Models", "Modelos Completados", "已完成的機台"],
  //inhouse model pie chart
  "Models in Stock": ["Models in Stock", "库存中的型号", "Models in Stock", "Modelos en Inventario", "庫存中的型號"],
  //machine issues pie chart
  "Machine Issues by Model": ["Machine Issues by Model", "按模型划分的机器问题", "Machine Issues by Model", "Problemas de Máquina por Modelo", "按模型劃分的機器問題"],
  //AfterSalesView
  "Add New Record": ["Add New Record", "添加新记录", "Add New Record", "Agregar Nuevo Registro", "新增記錄"],
  //China Prodiction
  "Add New Machine": ["Add New Machine", "添加新机器", "Add New Machine", "Agregar Nueva Máquina", "新增機器"],
  "Demo Release": ["Demo Release", "Demo放行", "Demo Release", "Liberación Demo", "Demo放行"],
  "Yes": ["Yes", "是", "Yes", "Sí", "是"],
  "No": ["No", "否", "No", "No", "否"],

  //China production Table
  "Export to CSV": ["Export to CSV", "导出为CSV", "Export to CSV", "Exportar a CSV", "匯出為CSV"],
  "Export to Excel": ["Export to Excel", "导出为Excel", "Export to Excel", "Exportar a Excel", "匯出為Excel"],
  "Import CSV": ["Import CSV", "导入CSV", "Import CSV", "importar CSV", "導入CSV"],
  "Notification": ["Notification", "通知", "Notification", "Notificación", "通知"],

  //China Report
  "Date Reported": ["Date Reported", "报告日期", "Date Reported", "Fecha Reportada", "報告日期"],
  "Report Number": ["Report Number", "报告编号", "Report Number", "Número de Informe", "報告編號"],
  "On-Site Tech": ["On-Site Tech", "现场技术原", "On-Site Tech", "Técnico en el Sitio", "現場技術員"],
  "From": ["From", "来自", "From", "Desde", "來自"],
  "Problem": ["Problem", "问题", "Problem", "Problema", "問題"],
  "Report By": ["Report By", "报告人", "Report By", "Informe Por", "報告人"],
  "Reported By": ["Reported By", "报告人", "Reported By", "Informe Por", "報告人"],
  "Model": ["Model", "型号", "Model", "Modelo", "型號"],
  "Description": ["Description", "描述", "Description", "Descripción", "描述"],
  "Suggested Solution": ["Suggested Solution", "建议解决方案", "Suggested Solution", "Solución sugerida", "建議解決方案"],
  "Images": ["Image", "图片", "Image", "Imagen", "圖片"],
  "Date Resolved": ["Date Resolved", "解决日期", "Date Resolved", "Fecha de Resolución", "解決日期"],
  "Details": ["Details", "详细信息", "Details", "Detalles", "詳細信息"],
  "Date Resolved Permanent": ["Date Resolved Permanent", "永久解决日期", "Date Resolved Permanent", "Fecha de Resolución Permanente", "永久解決日期"],
  "ECN": ["Engineering Change Number", "工程变更编号", "Engineering Change Number", "Número de Cambio de Ingeniería", "工程變更編號"],
  "Nonconforming Report": ["Nonconforming Report", "不合格报告", "Nonconforming Report", "Informe de No Conformidad", "不合格報告"],
  "Pending Reports": ["Pending Reports", "待办報告", "Pending Reports", "Informes pendientes", "待办報告"],
  "Submitted Reports": ["Submitted Reports", "已提交報告", "Submitted Reports", "Informes enviados", "已遞交報告"],
  "Search Reports...": ["Search Reports...", "搜索报告...", "Search Reports...", "Buscar informes...", "搜尋報告..."],
  "Issue Type": ["Issue Type", "问题类型", "Issue Type", "Tipo de Problema", "問題類型"],
  "Mechanical": ["Mechanical", "机械", "Mechanical", "Mecánico", "機械"],
  "X-ray Parts": ["X-ray Parts", "X射线部件", "X-ray Parts", "Partes de Rayos X", "X光部件"],
  "Electrical": ["Electrical", "电气", "Electrical", "Eléctrico", "電氣"],
  "FinalNonconforming Form": ["FinalNonconforming Form", "最终不合格表单", "FinalNonconforming Form", "Formulario de No Conformidad Final", "最終不合格表單"],
  "No images to preview": ["No images to preview", "没有图像可预览", "No images to preview", "No hay imágenes para previsualizar", "沒有圖像可預覽"],
  "In Process": ["In Process", "进行中", "In Process", "En proceso", "進行中"],
  "in process": ["In Process", "进行中", "In Process", "En proceso", "進行中"],
  "Case Closed": ["Case Closed", "结案", "Case Closed", "Caso cerrado", "結案"],
  "Current Files": ["Current Files", "当前文件", "Current Files", "Archivos Actuales", "當前文件"],
  "No files uploaded": ["No files uploaded", "未上传文件", "No files uploaded", "No se han subido archivos", "未上傳文件"],
  "Files to Upload": ["Files to Upload", "待上传文件", "Files to Upload", "Archivos para subir", "待上傳文件"],
  "No new files uploaded": ["No new files uploaded", "没有上传文件", "No new files uploaded", "No se han subido nuevos archivos", "沒有上傳文件"],
  "Files": ["Files", "文件", "Files", "Archivos", "文件"],

  //China Production
  "Machine Model and Config": ["Machine Model", "机器型号", "Machine Model", "Modelo de la Máquina", "機器型號"],
  "Machine No.": ["Machine Number", "机器编号", "Machine Number", "Número de Máquina", "機器編號"],
  "Machine NO.": ["Machine Number", "机器编号", "Machine Number", "Número de Máquina", "機器編號"],
  "Customer": ["Customer", "客户", "Customer", "Cliente", "客戶"],
  "Order Received Date": ["Order Received Date", "订单接收日期", "Order Received Date", "Fecha de Recepción del Pedido", "訂單接收日期"],
  "Lead Time/Delivery Date": ["Lead Time", "交货日期", "Lead Time", "Fecha de Entrega", "交貨日期"],
  "Ready Ship Date": ["Ready Ship Date", "准备发货日期", "Ready Ship Date", "Fecha de Preparación para Envío", "準備發貨日期"],
  "Actual Ship Date": ["Actual Ship Date", "实际发货日期", "Actual Ship Date", "fecha de pago real", "實際發貨日期"],
  "Status": ["Status", "状态", "Status", "Estado", "狀態"],
  "In Production": ["In Production", "在制", "In Production", "en Producción", "在制"],
  "Financial Release": ["Order Release", "财务放行", "Order Release", "Liberación Financiera", "財務放行"],
  "Special Configuration": ["Special Configuration", "特殊配置", "Special Configuration", "Configuración Especial", "特殊配置"],
  "Tube": ["Tube", "光管", "Tube", "Tubo de Luz", "光管"],
  "Detector": ["Detector", "平板", "Detector", "Detector de Placa", "平板"],
  "PC": ["PC", "电脑", "PC", "PC", "電腦"],
  "pc": ["PC", "电脑", "PC", "PC", "電腦"],
  "Monitor": ["Monitor", "显示器", "Monitor", "Monitor", "顯示器"],
  "monitor": ["Monitor", "显示器", "Monitor", "Monitor", "顯示器"],
  "Cancel": ["Cancel", "取消", "Cancel", "Cancelar", "取消"],
  "Save": ["Save", "保存", "Save", "Guardar", "儲存"],
  "Overseas": ["Overseas", "海外", "Overseas", "Extranjero", "海外"],
  "Stage": ["Stage", "阶段", "Stage", "Etapa", "階段"],
  "Edit Machine": ["Edit Machine", "编辑机器资料", "Edit Machine", "Editar Máquina", "編輯機器資料"],
  "Machine Model and Config is required": ["Machine Model and Config is required", "机器型号和配置是必需的", "Machine Model and Config is required", "El modelo y la configuración de la máquina son requeridos", "機器型號和配置是必需的"],
  "Machine No. is required": ["Machine No. is required", "机器编号是必需的", "Machine No. is required", "Número de máquina es requerido", "機器編號是必需的"],
  "Options": ["Options", "選配", "Options", "opciones", "選配"],
  "Hardware assembly and installation": ["Hardware Assembly and Installation", "硬件组装", "Hardware Assembly and Installation", "Montaje e Instalación de Hardware", "硬件組裝"],
  "Electrical assembly and installation": ["Electrical Assembly and Installation", "电气组装", "Electrical Assembly and Installation", "Montaje e Instalación Eléctrica", "電氣組裝"],
  "Test": ["Test", "调试", "Test", "Prueba", "調試"],
  "QA/QC": ["QA/QC", "质检", "QA/QC", "Aseguramiento y Control de Calidad", "質量檢查"],
  "Incoming materials pending": ["Incoming materials pending", "待进料", "Incoming materials pending", "Materiales entrantes pendientes", "待进料"],
  "Re-work": ["Re-work", "返工", "Re-work", "Rehacer", "返工"],
  "QC Release": ["QC Release", "质检放行", "QC Release", "QC Release", "质检放行"],
  "Software Version": ["Software Version", "软件版本", "Software Version", "Versión del Software", "軟件版本"],
  "12 months": ["12 months", "12 个月", "12 months", "12 meses", "12個月"],
  "18 months": ["18 months", "18 个月", "18 months", "18 meses", "18個月"],
  "24 months": ["24 months", "24 个月", "24 months", "24 meses", "24個月"],
  "36 months": ["36 months", "36 个月", "36 months", "36 meses", "36個月"],
  "Delivery Date Alert": ["Delivery Date Alert", '交货日期提醒', 'Alerta de fecha de entrega', '交貨日期提醒'],
  "Deposit paid, ready to ship": ["Deposit paid, ready to ship", "定金已付，可发货", "Deposit paid, ready to ship", "定金已付，可发货 "],
  "Final payment, permanent license": ["Final payment, permanent license", "尾款已付，可终生授权", "Final payment, permanent license", "尾款已付，可终生授权"],
  "Confirm Notification Email": ["Confirm Notification Email", "确认通知电子邮件", "Confirm Notification Email", "確認通知電子郵件"],
  "Last Modified": ["Last Modified", "最后修改", "Last Modified", "Última modificación", "最後修改"],
  "Sales": ["Saleperson", "业务员", "Saleperson", "Vendedor", "業務員"],
  "Machine Voltage": ["Equipment Voltage", "机器电压", "Equipment Voltage", "Voltaje de la máquina", "機器電壓"],
  "Saleperson": ["Salesperson", "业务员", "Salesperson", "Vendedor", "業務員"],


  //user page
  "Add User": ["Add User", "添加用户", "Add User", "Añadir usuario", "新增用戶"],
  "Add New User": ["Add New User", "添加新用户", "Add New User", "Añadir nuevo usuario", "新增用戶"],
  "Add New Utility User": ["Add New Utility User", "添加新公用用户", "Add New Utility User", "Añadir nuevo Utilidad usuario", "添加新公用使用者"],
  "Email": ["Email", "电子邮件", "Email", "Correo electrónico", "電子郵件"],
  "Role": ["Role", "角色", "Role", "Rol", "角色"],
  "Region": ["Region", "地区", "Region", "Región", "地區"],
  "Color": ["Color", "颜色", "Color", "Color", "顏色"],
  "User name is required": ["User name is required", "用户名是必填项", "User name is required", "Se requiere nombre de usuario", "用戶名是必填項"],
  "Role is required": ["Role is required", "角色是必填项", "Role is required", "Se requiere rol", "角色是必填項"],
  "Email is required": ["Email is required", "电子邮件是必填项", "Email is required", "Se requiere correo electrónico", "電子郵件是必填項"],
  "At least one region must be selected": ["At least one region must be selected", "必须选择至少一个地区", "At least one region must be selected", "Debe seleccionar al menos una región", "必須選擇至少一個地區"],
  "Search Users...": ["Search Users...", "搜索用户...", "Search Users...", "Buscar usuarios...", "搜尋用戶..."],
  "Roles": ["Role", "角色", "Role", "Rol", "角色"],
  "Regions": ["Region", "地区", "Region", "Región", "地區"],
  "Password": ["Password", "密码", "Password", "Contraseña", "密碼"],
  "Edit User": ["Edit User", "编辑用户", "Edit User", "Editar usuario", "編輯用戶"],
  "USA": ["USA", "美国", "USA", "EE.UU.", "美國"],
  "MEXICO": ["MEXICO", "墨西哥", "MEXICO", "México", "墨西哥"],
  "EU": ["EU", "欧盟", "EU", "UE", "歐盟"],
  "CHINA": ["CHINA", "中国", "CHINA", "China", "中國"],
  "TAIWAN": ["TAIWAN", "台湾", "TAIWAN", "Taiwán", "台灣"],


  //after sales 
  "Application Date": ["Application Date", "申请日期", "Application Date", "Fecha de Solicitud", "申請日期"],
  "Order Number": ["Order Number", "订单号", "Order Number", "Número de Pedido", "訂單號"],
  "Customer Name": ["Customer Name", "客户名称", "Customer Name", "Nombre del Cliente", "客戶名稱"],
  "Machine ID": ["Machine ID", "机器编号", "Machine ID", "ID de Máquina", "機器編號"],
  "Machine Model": ["Machine Model", "机器型号", "Machine Model", "Modelo de Máquina", "機器型號"],
  "Material Name": ["Material Name", "材料名称", "Material Name", "Nombre del Material", "材料名稱"],
  "Material #": ["Material #", "材料编号", "Material #", "Número de Material", "材料編號"],
  "Request Quantity": ["Request Quantity", "请求数量", "Request Quantity", "Cantidad Solicitada", "請求數量"],
  "Charge Status": ["Charge Status", "费用状态", "Charge Status", "Estado de Carga", "費用狀態"],
  "Request Reason": ["Request Reason", "请求原因", "Request Reason", "Motivo de Solicitud", "請求原因"],
  "Shipped Quantity": ["Shipped Quantity", "发货数量", "Shipped Quantity", "Cantidad Enviada", "發貨數量"],
  "Delivery Note": ["Delivery Note", "送货单", "Delivery Note", "Nota de Entrega", "送貨單"],
  "Tracking #": ["Tracking #", "追踪号", "Tracking #", "Número de Seguimiento", "追蹤號"],
  "Dispatch Date": ["Dispatch Date", "发货日期", "Dispatch Date", "Fecha de Envío", "發貨日期"],
  "Recycle Status": ["Recycle Status", "回收状态", "Recycle Status", "Estado de Reciclaje", "回收狀態"],
  "Returned Date": ["Returned Date", "退货日期", "Returned Date", "Fecha de Devolución", "退貨日期"],
  "Warehouse Receipt #": ["Warehouse Receipt #", "仓库收据号", "Warehouse Receipt #", "Número de Recibo de Almacén", "倉庫收據號"],
  "Remarks": ["Remarks", "备注", "Remarks", "Observaciones", "備註"],
  "Edit After Sales Record": ["Edit After Sales Record", "编辑售后记录", "Edit After Sales Record", "Editar Registro de Postventa", "編輯售後記錄"],
  "Add After Sales Record": ["Add After Sales Record", "添加售后记录", "Add After Sales Record", "Agregar Registro de Postventa", "添加售後記錄"],
  "Add": ["Add", "添加", "Add", "Agregar", "添加"],
  "Search Data...": ["Search Data...", "搜索数据...", "Search Data...", "Rechercher des données...", "搜索資料"],
  "Application Date is required": ["Application Date is required", "申请日期栏位是必需的", "Application Date is required", "La fecha de aplicación es requerida", "申請日期欄位是必需的"],
  "Order Number is required": ["Order Number is required", "订单编号栏位是必需的", "Order Number is required", "Número de pedido es requerido", "訂單編號欄位是必需的"],
  "Customer Name is required": ["Customer Name is required", "客户名称栏位是必需的", "Customer Name is required", "Nombre del cliente es requerido", "客戶名稱欄位是必需的"],
  "Material Name is required": ["Material Name is required", "材料名称栏位是必需的", "Material Name is required", "Nombre del material es requerido", "材料名稱欄位是必需的"],
  "Quantity must be greater than zero": ["Quantity must be greater than zero", "数量必须大于零", "Quantity must be greater than zero", "La cantidad debe ser mayor que cero", "數量必須大於零"],
  "Request Reason is required": ["Request Reason is required", "请求原因栏位是必需的", "Request Reason is required", "Se requiere la razón de la solicitud", "請求原因欄位是必需的"],
  "Region is required": ["Region is required", "区域栏位是必需的", "Region is required", "Se requiere la región", "區域是必需的"],
  "In House": ["In House", "产内安置", "In House", "en casa", "產內安置"],
  "submittedDate": ["submittedDate", "提交日期", "submittedDate", "fecha de presentación", "遞交日期"],
  "customerName": ["customerName", "客户名称", "customerName", "nombre del cliente", "客戶名稱"],
  "model": ["model", "型号", "model", "modelo", "型號"],
  "serial": ["serial", "序列号", "serial", "número de serie", "序列號"],
  "Serial Number is required": ["Serial Number is required", "序列号栏位是必需的", "Serial Number is required", "número de serie es obligatorio", "序列號欄位是必需的"],
  "shippingAddress": ["shippingAddress", "送货地址", "shippingAddress", "dirección de envío", "送貨地址"],
  "shippingMethod": ["shippingMethod", "运输方式", "shippingMethod", "método de envío", "運輸方式"],
  "contactName": ["contactName", "联系人", "contactName", "nombre de contacto", "聯絡人"],
  "contactPhone": ["contactPhone", "联系电话", "contactPhone", "teléfono de contacto", "聯絡電話"],
  "purpose": ["purpose", "目的", "purpose", "objetivo", "目的"],
  "is required": [" is required", "栏位是必需的", " is required", " es requerido", "欄位是必需的"],
  "Status is required": ["Status is required", "状态栏位是必需的", "Status is required", "Statu es requerido", "狀態欄位是必需的"],
  "Under Warranty Service": ["Under Warranty Service", "保修服务", "Under Warranty Service", "Servicio bajo garantía", "保固服務"],
  "Loaner": ["Loaner", "借用", "Loaner", "Prestamista", "借用"],
  "Scienscope Stock": ["Scienscope Stock", "Scienscope库存", "Scienscope Stock", "Stock de Scienscope", "Scienscope庫存"],
  "Stock": ["Stock", "在库", "Stock", "Stock", "在庫"],
  "This field is required": ["This field is required", "此字段是必需的", "This field is required", "Este campo es obligatorio", "此欄位是必需的"],


  //add new model
  "Add Machine Model": ["Add Machine Model", "添加机器型号", "Add Machine Model", "agregar modelo de máquina", "添加機器型號"],
  "Model Name": ["Model Name", "型号名称", "Model Name", "nombre del modelo", "型號名稱"],
  "Are you sure you want to delete this model?": ["Are you sure you want to delete this model?", "您确定要删除此型号吗？", "Are you sure you want to delete this model?", "¿Está seguro de que desea eliminar este modelo?", "您確定要刪除此型號嗎？"],
  "Add New Model": ["Add New Model", "添加新型号", "Add New Model", "agregar nuevo modelo", "添加新型號"],
  "Delete Item": ["Delete Item", "删除项", "Delete Item", "eliminar artículo", "刪除項目"],
  "Delete Model": ["Delete Model", "删除型号", "Delete Model", "eliminar modelo", "刪除型號"],
  "Edit Model": ["Edit Model", "编辑型号", "Edit Model", "editar modelo", "編輯型號"],
  "Model Name is required": ["Model Name is required", "型号名称是必需的", "Model Name is required", "El nombre del modelo es requerido", "型號名稱是必需的"],

  //inventory

  "Choose Image": ["Choose Image", "选择图片", "Choose Image", "Elegir imagen", "選擇圖片"],
  "Part Number": ["Part Number", "零件编号", "Part Number", "Número de parte", "零件編號"],
  "Name": ["Name", "名称", "Name", "Nombre", "名稱"],
  "Location": ["Location", "位置", "Location", "Ubicación", "位置"],
  "Quantity": ["Quantity", "数量", "Quantity", "Cantidad", "數量"],
  "Minimum Quantity": ["Minimum Quantity", "最小数量", "Minimum Quantity", "Cantidad mínima", "最小數量"],
  "Price": ["Price", "价格", "Price", "Precio", "價格"],
  "Add Item": ["Add Item", "添加项目", "Add Item", "Agregar artículo", "添加項目"],
  "Image": ["Image", "图片", "Image", "Imagen", "圖片"],
  "Search Inventory...": ["Search Inventory...", "搜索零件...", "Search Inventory...", "Buscar inventario...", "搜索零件..."],
  "Show Low Quantity": ["Show Low Quantity", "显示低库存", "Show Low Quantity", "Mostrar cantidad baja", "顯示低庫存"],
  "Actions": ["Actions", "执行", "Actions", "Acciones", "執行"],
  "Edit Item": ["Edit Item", "编辑项目", "Edit Item", "Editar artículo", "編輯項目"],
  "Add New Item": ["Add New Item", "添加新项目", "Add New Item", "Agregar artículo nuevo", "添加新項目"],
  "Inbound/Outbound": ["Inbound/Outbound", "入库/出库", "Inbound/Outbound", "Entrante/Saliente", "入庫/出庫"],
  "History Log": ["HISTORY LOG", "历史记录", "HISTORY LOG", "REGISTRO HISTÓRICO", "歷史記錄"],
  "Equipment Type": ["Equipment Type", "设备类型", "Equipment Type", "Tipo de equipo", "設備類型"],
  "Search Part Number...": ["Search Part Number...", "搜索零件号...", "Search Part Number...", "Buscar número de parte...", "搜索零件號..."],
  "Reset Checkboxes": ["Reset Checkboxes", "重置复选框", "Reset Checkboxes", "Restablecer casillas de verificación", "重置複選框"],
  "Inventory Check": ["Inventory Check", "库存检查", "Inventory Check", "Verificación de inventario", "庫存檢查"],
  "Notification": ["Notification", "通知", "Notification", "Notificación", "通知"],


  //inventort history
  "Search History...": ["Search History...", "搜索历史...", "Search History...", "Historial de Búsqueda...", "搜索歷史..."],
  "Inventory History": ["Inventory History", "库存操作历史", "Inventory History", "Historial de Inventario", "庫存操作歷史"],
  "Action": ["Action", "行动", "Action", "Acción", "行動"],
  "Shipped To/From": ["Shipped To/From", "发货到/从", "Shipped To/From", "Enviado A/Desde", "發貨到/從"],
  "Time": ["Time", "时间", "Time", "Hora", "時間"],


  //inoutPopup
  "Inbound Inventory": ["Inbound Inventory", "入库库存", "Inbound Inventory", "Inventario de entrada", "入庫庫存"],
  "Outbound Inventory": ["Outbound Inventory", "出库库存", "Outbound Inventory", "Inventario de salida", "出庫庫存"],
  "INBOUND Inventory": ["Inbound Inventory", "入库库存", "Inbound Inventory", "Inventario de entrada", "入庫庫存"],
  "OUTBOUND Inventory": ["Outbound Inventory", "出库库存", "Outbound Inventory", "Inventario de salida", "出庫庫存"],
  "Inbound": ["Inbound", "入库", "Inbound", "Entrada", "入庫"],
  "Outbound": ["Outbound", "出库", "Outbound", "Salida", "出庫"],
  "Old Quantity": ["Old Quantity", "旧数量", "Old Quantity", "Cantidad antigua", "舊數量"],
  "Updated Quantity": ["Updated Quantity", "更新数量", "Updated Quantity", "Cantidad actualizada", "更新數量"],
  "Close": ["Close", "关闭", "Close", "Cerrar", "關閉"],
  "Item": ["Item", "项目", "Item", "Artículo", "項目"],

  //itemRequestDetail
  "Date Submitted": ["Date Submitted", "提交日期", "Date Submitted", "Fecha de envío", "遞交日期"],
  "Submitted Date": ["Submitted Date ", "提交日期", "Submitted Date ", "Fecha de envío", "遞交日期"],
  "Serial Number": ["Serial Number", "序列号", "Serial Number", "Número de serie", "序列號"],
  "Warranty": ["Warranty", "保修", "Warranty", "Garantía", "保固"],
  "Includes High Value Component": ["Includes High Value Component", "包含高价值组件", "Includes High Value Component", "Incluye componente de alto valor", "包含高價值組件"],
  "Shipping Address": ["Shipping Address", "收货地址", "Shipping Address", "Dirección de envío", "收貨地址"],
  "Shipping Account": ["Shipping Account", "运送账户", "Shipping Account", "Cuenta de envío", "運送帳戶"],
  "Notes": ["Notes", "备注", "Notes", "Notas", "備註"],
  "Approval Notes": ["Approval Notes", "审批备注", "Approval Notes", "Notas de aprobación", "核准備註"],
  "Submitted By": ["Submitted By", "提交人", "Submitted By", "Enviado por", "遞交人"],
  "Number of Part Numbers": ["Number of Part Numbers", "零件编号数量", "Number of Part Numbers", "Número de números de parte", "零件編號數量"],
  "Shipping Method": ["Shipping Method", "运送方式", "Shipping Method", "Método de envío", "運送方式"],
  "Submit": ["Submit", "提交", "Submit", "Enviar", "遞交"],
  "Submitted Requests": ["Submitted Requests", "", "Submitted Requests", "Solicitudes Enviados", ""],
  "Pending Requests": ["Pending Requests", "待处理请求", "Pending Requests", "Solicitudes Pendientes", "待處理請求"],
  "Approved Requests": ["Approved Requests", "已批准的请求", "Approved Requests", "Solicitudes Aprobadas", "已批准的請求"],
  "Approved": ["Approved", "已批准", "Approved", "Aprobadas", "已批准"],
  "Confirm Rejection": ["Confirm Rejection", "确认拒绝", "Confirm Rejection", "Confirmar Rechazo", "确认拒绝"],
  "Confirm Acceptance": ["Confirm Acceptance", "确认接受", "Confirm Acceptance", "Confirmar Aceptación", "確認接受"],
  "Request Spare Parts": ["Request Spare Parts", "请求备品", "Request Spare Parts", "Solicitar Piezas de Repuesto", "請求備件"],
  "Search Requests...": ["Search Requests...", "搜索请求...", "Search Requests...", "Buscar Solicitudes...", "搜尋請求..."],
  "Declined": ["Declined", "拒绝的请求", "Declined", "Rechazo", "拒絕的請求"],
  "Confirm": ["Confirm", "确认", "Confirm", "Confirmar", "確認"],
  "Contact Name": ["Contact Name", "连络者", "Contact Name", "Nombre de contacto", "連絡人"],
  "Contact Phone Number": ["Contact Phone Number", "连络电话", "Contact Phone Number", "Teléfono de contacto", "連絡電話"],
  "Purpose": ["Purpose", "目的", "Purpose", "Objetivo", "目的"],
  "Part Number is required": ["Part Number is required", "零件编号是必需的", "Part Number is required", "Número de pieza es requerido", "零件編號是必需的"],
  "Location is required": ["Location is required", "位置是必需的", "Location is required", "Ubicación es requerida", "位置是必需的"],
  "Edit Spare Part Request": ["Edit Spare Part Request", "编辑备件请求", "Edit Spare Part Request", "Editar solicitud de repuesto", "編輯備件請求"],
  "Submit Spare Part Request": ["Submit Spare Part Request", "提交备件请求", "Submit Spare Part Request", "Enviar solicitud de repuesto", "遞交備件請求"],
  "Filled Out by China": ["Filled Out by China", "中国填写", "Filled Out by China", "Rellenado por China", "中國填寫"],
  "Tracking Numbers": ["Tracking Numbers", "追踪号码", "Tracking Numbers", "Números de seguimiento", "追蹤號碼"],
  "Filled Out by USA": ["Filled Out by USA", "美国填写", "Filled Out by USA", "Rellenado por EE.UU.", "美國填寫"],
  "Shipping Notes": ["Shipping Notes", "运输备注", "Shipping Notes", "Notas de envío", "運輸備註"],
  "Tracking Notes": ["Tracking Notes", "追踪备注", "Tracking Notes", "Notas de seguimiento", "追蹤備註"],
  "Tracking Number": ["Tracking Number", "追踪号码", "Tracking Number", "Número de seguimiento", "追蹤號碼"],
  "Shipping Company": ["Shipping Company", "运输公司", "Shipping Company", "Compañía de transporte", "運輸公司"],
  "Send Item For Approval": ["Send Item For Approval", "发送项目以供审批", "Send Item For Approval", "Enviar Artículo Para Aprobación", "發送項目以供批准"],
  "Are you sure you want to send this item for approval?": ["Are you sure you want to send this item for approval?", "您确定要将此项发送进行审批吗？", "Are you sure you want to send this item for approval?", "¿Está seguro de que desea enviar este artículo para aprobación?", "您確定要將此項目發送以供批准嗎？"],
  "Completed Spare Part Request": ["Completed Spare Part Request", "完成的备件请求", "Completed Spare Part Request", "Solicitud de Repuesto Completa", "已完成的備件請求"],
  "Are you sure you want to mark the spare part request as completed?": ["Are you sure you want to mark the spare part request as completed?", "您确定要将备件请求标记为完成吗？", "Are you sure you want to mark the spare part request as completed?", "¿Está seguro de que desea marcar la solicitud de repuesto como completada?", "您確定要將備件請求標記為完成嗎？"],
  "Rejection Notes": ["Rejection Notes", "拒绝备注", "Rejection Notes", "Notas de Rechazo", "拒絕備註"],
  "Pending (CEO)": ["PENDING (CEO)", "待處理（CEO）", "PENDING (CEO)", "PENDIENTE (CEO)", "待處理（CEO）"],
  "Request ID": ["Request ID", "请求 ID", "Request ID", "ID de solicitud", "請求 ID"],


  //Shipping mode
  "Over-night": ["Over-night", "隔夜", "Over-night", "Nocturno", "隔夜"],
  "Ground": ["Ground", "地面运送", "Ground", "Terrestre", "地面運送"],
  "3 days": ["3 days", "3天", "3 days", "3 días", "3天"],
  "2 days": ["2 days", "2天", "2 days", "2 días", "2天"],
  "1 day": ["1 day", "1天", "1 day", "1 día", "1天"],


  //machine
  "Add New Machine/Rack": ["Add New Machine/Rack", "添加新机器/料架", "Add New Machine/Rack", "Agregar nueva máquina/rack", "新增新機器/料架"],
  "Add Machine": ["Add Machine", "添加新机器", "Add Machine", "Agregar máquina", "新增新機器"],
  "Add New Machine": ["Add Machine", "添加新机器", "Add Machine", "Agregar máquina", "新增新機器"],
  "Add New Rack": ["Add New Rack", "添加新料架", "Add New Rack", "Agregar rack", "新增新料架"],
  "Date received": ["Date received", "收到日期", "Date received", "Fecha recibida", "收到日期"],
  "Date Received": ["Date received", "收到日期", "Date received", "Fecha recibida", "收到日期"],
  "PO Number": ["PO Number", "PO号码", "PO Number", "Número de pedido", "PO號碼"],
  "PO Status": ["PO Status", "PO状态", "PO Status", "Estado del pedido", "PO狀態"],
  "Racks": ["Racks", "料架", "Racks", "Racks", "料架"],
  "rack": ["Rack", "料架", "Rack", "Rack", "料架"],
  "Search Machines/Racks...": ["Search Machines/Racks...", "搜索机器/机架...", "Search Machines/Racks...", "Buscar Máquinas/Racks...", "搜索机器/机架..."],
  "On Site": ["On Site", "现场", "On Site", "el Sitio", "現場"],


  //add new model
  "Add Model": ["Add Model", "添加型号", "Add Model", "Añadir Modelo", "新增型號"],
  "Apply for all regions": ["Apply for all regions", "加至所有地区", "Apply for all regions", "Solicitar para todas las regiones", "加至所有地區"],
  "machine": ["Machine", "机器", "Machine", "Máquina", "機器"],
  "Machine": ["Machine", "机器", "Machine", "Máquina", "機器"],
  "tube": ["Tube", "光管", "Tube", "Tubo", "光管"],
  "Tube": ["Tube", "光管", "Tube", "Tubo", "光管"],
  "fpd": ["X-Ray Detector", "X-Ray平板", "X-Ray Detector", "X-Ray Detector", "X-Ray平板"],
  "FPD": ["X-Ray Detector", "X-Ray平板", "X-Ray Detector", "X-Ray Detector", "X-Ray平板"],
  "software": ["Software", "软件", "Software", "Software", "軟體"],
  "Software": ["Software", "软件", "Software", "Software", "軟體"],
  "For Machine Models": ["For Machine Models", "适用于机器型号", "For Machine Models", "PARA MODELOS DE MÁQUINA", "適用於機器型號"],


  //machine details
  "Machine Details": ["Machine Details", "机器详情", "Machine Details", "Detalles de la máquina", "機器詳情"],
  "Terms": ["Terms", "条款", "Terms", "Términos", "條款"],
  "Origin": ["Origin", "生产来源", "Origin", "Origen", "生產來源"],
  "Printer": ["Printer", "打印机", "Printer", "Impresora", "印表機"],
  "barcodeprinter": ["Printer", "打印机", "Printer", "Impresora", "印表機"],
  "Barcode Scanner": ["Barcode Scanner", "扫码器", "Barcode Scanner", "Escáner de Códigos de Barras", "條碼掃描器"],
  "barcodescanner": ["Barcode Scanner", "扫码器", "Barcode Scanner", "Escáner de Códigos de Barras", "條碼掃描器"],
  "Tube Power Supply": ["Tube Power Supply", "光管电源", "Tube Power Supply", "Fuente de Alimentación de Tubo", "光管電源"],
  "Loader": ["Loader", "装料机", "Loader", "Cargador", "裝料機"],
  "Unloader": ["Unloader", "卸料机", "Unloader", "Descargador", "卸料機"],
  "Fix License": ["FIX License", "FIX 许可证", "FIX License", "FIX Licencia", "FIX 許可證"],
  "Eval": ["EVAL", "评估", "EVAL", "EVALUAR", "評估"],
  "Purchased": ["Purchased", "已付款", "Purchased", "COMPRADO", "已付款"],
  "Down Payment": ["Down Payment", "已付首款", "Down Payment", "Pago inicial", "已付訂金"],
  "Final Payment": ["Final Payment", "最终付款", "Final Payment", "Pago final", "最終付款"],
  "Permanent Cynthia": ["Permanent Cynthia", "永久的Cynthia许可证", "Permanent Cynthia", "Cynthia permanente", "永久的Cynthia許可證"],
  "Expected To Finish": ["Expected To Finish", "预计完成", "Expected To Finish", "Fecha de finalización esperada", "預計完成"],
  "Shipped Date": ["Shipped Date", "发货日期", "Shipped Date", "Fecha de envío", "發貨日期"],
  "Edit": ["Edit", "编辑", "Edit", "Editar", "編輯"],
  "Expected Finish": ["Expected Finish", "预计完成", "Expected Finish", "Fecha de finalización esperada", "預計完成"],
  "Sold": ["Sold", "已售", "Sold", "Vendido", "已售"],
  "Demo at Customer Site": ["Demo at Customer Site", "客户现场演示", "Demo at Customer Site", "Demostración en el sitio del cliente", "客戶現場演示"],
  "Demo": ["Demo", "Demo", "Demo", "Demo", "Demo"],
  "No Data": ["No Data", "無資料", "No Data", "No Data", "無資料"],
  "Check Current stock situation Quantity": ["Check Current Stock Situation Quantity", "检查当前库存情况数量", "Check Current Stock Situation Quantity", "Verificar la Cantidad de la Situación Actual del Inventario", "檢查當前庫存情況數量"],
  "Internal Training Date": ["Internal Training Date", "内部培训日期", "Internal Training Date", "Fecha de Capacitación Interna", "內部培訓日期"],
  "Request new parts based on ECN Date": ["Request New Parts Based on ECN Date", "根据 ECN 日期申请新零件", "Request New Parts Based on ECN Date", "Solicitar Nuevas Piezas Basadas en la Fecha de ECN", "根據 ECN 日期申請新零件"],
  "Notify and check Equipment of the current customers Date": ["Notify and Check Equipment of the Current Customers Date", "通知并检查当前客户的设备日期", "Notify and Check Equipment of the Current Customers Date", "Notificar y Revisar el Equipo de los Clientes Actuales", "通知並檢查當前客戶的設備日期"],
  "Recall Date": ["Recall Date", "召回日期", "Recall Date", "Fecha de Retiro", "召回日期"],
  "Search Events...": ["Search events...", "搜索事件...", "Search events...", "buscar eventos...", "搜尋事件..."],
  "Delete": ["Delete", "删除", "Delete", "borrar", "刪除"],
  "Camera": ["Camera", "相機", "Camera", "Cámara", "相機"],
  "MVP Software": ["MVP Software", "MVP 软件", "MVP Software", "Software MVP", "MVP 軟件"],
  "Start New Report": ["Start New Report", "开始新报告", "Start New Report", "Iniciar Nuevo Informe", "開始新報告"],
  "Begin a new report by filling out the necessary details.": ["Begin a new report by filling out the necessary details", "通过填写必要的详细信息开始一个新报告", "Begin a new report by filling out the necessary details", "Comience un nuevo informe completando los detalles necesarios", "透過填寫必要的詳細信息開始一個新報告"],
  "Start New Non-Conforming Report": ["Start New Non-Conforming Report", "开始新的不合格报告", "Start New Non-Conforming Report", "Iniciar Nuevo Informe de No Conformidad", "開始新的不合格報告"],
  "Resume Saved Reports": ["Resume Saved Reports", "恢复保存的报告", "Resume Saved Reports", "Reanudar Informes Guardados", "恢復儲存的報告"],
  "Continue with a previously saved report.": ["Continue with a previously saved report", "继续处理之前保存的报告", "Continue with a previously saved report", "Continuar con un informe guardado previamente", "繼續處理之前儲存的報告"],
  "Pending/Submitted": ["Pending/Submitted", "待处理/已提交的报告", "Pending/Submitted", "Pendientes/Enviados", "待處理/已遞交的報告"],
  "Pending": ["Pending", "待处理", "Pending", "Pendientes", "待處理"],
  "Submitted": ["Submitted", "已提交", "Submitted", "Enviados", "已遞交"],
  "submitted": ["Submitted", "已提交", "Submitted", "Enviados", "已遞交"],
  "Progress": ["Progress", "进度", "Progress", "Progress", "進度"],
  "Upload File": ["Upload File", "上传档案", "Upload File", "Upload File", "上傳檔案"],
  "Upload Images": ["Upload Images", "上传图片", "Upload Images", "imágenes", "上傳圖片"],
  "Edit Nonconforming Report": ["Edit Nonconforming Report", "编辑不合格报告", "Edit Nonconforming Report", "Editar Informe de No Conformidad", "編輯不合格報告"],
  "Add Nonconforming Report": ["Add Nonconforming Report", "添加不合格报告", "Add Nonconforming Report", "Añadir Informe de No Conformidad", "新增不合格報告"],
  "Saved Forms": ["Saved Forms", "已保存的表单", "Saved Forms", "Formularios Guardados", "已儲存的表單"],
  "No drafts available": ["No drafts available.", "没有可用的草稿。", "No drafts available.", "No hay borradores disponibles.", "沒有可用的草稿。"],
  "Form Name": ["Form Name", "表单名称", "Form Name", "Nombre del Formulario", "表單名稱"],
  "No quantities available": ["No quantities available", "没有可用数量", "No quantities available", "No hay cantidades disponibles", "沒有可用數量"],
  "No part numbers available": ["No part numbers available", "没有可用的零件号码", "No part numbers available", "No hay números de parte disponibles", "沒有可用的零件編號"],
  "Please confirm outbound part numbers and quantities": ["Please confirm outbound part numbers and quantities", "请确认出库零件编号和数量", "Please confirm outbound part numbers and quantities", "Por favor confirme los números de parte y las cantidades de salida", "請確認出庫零件編號和數量"],
  "Confirm Item Request": ["Confirm Item Request", "确认项目请求", "Confirm Item Request", "Confirmar Solicitud de Artículo", "確認項目請求"],
  "Filled in by CHINA": ["Filled in by China", "此处由中国填写", "Filled in by China", "Completado por China", "此處由中國填寫"],
  "Follow Up": ["Follow Up", "跟进", "Follow Up", "Seguimiento", "跟進"],
  "Add Entry": ["Add Entry", "添加条目", "Add Entry", "Agregar entrada", "新增條目"],

  //Calendar
  "Filter by Technicians": ["Filter by Technicians", "按技术员筛选", "Filter by Technicians", "Filtrar por Técnicos", "按技術員篩選"],
  "Add New Event": ["Add New Event", "添加新事件", "Add New Event", "Agregar nuevo evento", "添增新事件"],
  "Add Event": ["Add Event", "添加事件", "Add Event", "Agregar evento", "新增事件"],
  "Filter": ["Filter", "筛选", "Filter", "Filtrar", "篩選"],
  "End Time": ["End Time", "结束时间", "End Time", "Hora de finalización", "結束時間"],
  "Start Time": ["Start Time", "开始时间", "Start Time", "Hora de inicio", "開始時間"],
  "End": ["End", "结束时间", "End", "finalización", "結束時間"],
  "Start": ["Start", "开始时间", "Start", "inicio", "開始時間"],
  "Title": ["Title", "标题", "Title", "Título", "標題"],
  "Address": ["Address", "地址", "Address", "Dirección", "地址"],
  "Type of Service": ["Type of Service", "服务类型", "Type of Service", "Tipo de servicio", "服務類型"],
  "Additional Technician": ["Additional Technician", "额外技术员", "Additional Technician", "Técnico adicional", "額外技術員"],
  "Technician": ["Technician", "技术员", "Technician", "Técnico", "技術員"],
  "End Date": ["End Date", "结束日期", "End Date", "Fecha de finalización", "結束日期"],
  "Start Date": ["Start Date", "开始日期", "Start Date", "Fecha de inicio", "開始日期"],
  "1 Year PM": ["1 Year PM", "1年定期维护", "1 Year PM", "Mantenimiento de 1 año", "1年定期維護"],
  "3 Year PM": ["3 Year PM", "3年定期维护", "3 Year PM", "Mantenimiento de 3 año", "3年定期維護"],
  "Installation": ["Installation", "安装", "Installation", "Instalación", "安裝"],
  "Repair": ["Repair", "维修", "Repair", "Reparación", "維修"],
  "Other": ["Other", "其他", "Other", "Otro", "其他"],
  "Technicians": ["Technician", "技术员", "Technician", "Técnico", "技術員"],
  "Additional Technicians": ["Additional Technician", "额外技术员", "Additional Technician", "Técnico adicional", "額外技術員"],
  "Model(s)": ["Model(s)", "型号", "Model(s)", "Modelos", "型號"],

  //progress bar
  "completed": ["completed", "已完成", "completed", "completado", "已完成"],
  "finished": ["finished", "完成", "finished", "terminado", "完成"],
  "approved": ["approved", "已批准", "approved", "aprobado", "已批准"],
  "pending": ["pending", "待处理", "pending", "pendiente", "待處理"],
  "declined": ["Declined", "已拒绝", "Declined", "Rechazado", "已拒絕"],
  "On Order": ["On Order", "已订购", "On Order", "en pedido", "已訂購"],
  "In Crate": ["In Crate", "在托盘中", "In Crate", "en caja", "在托盤中"],
  "On Floor: Assembling": ["On Floor: Assembling", "在生产线上：组装中", "On Floor: Assembling", "En el suelo: En ensamblaje", "在生產線上：組裝中"],
  "On Floor: Assembled": ["On Floor: Assembled", "在生产线上：已组装", "On Floor: Assembled", "En el suelo: Ensamblado", "在生產線上：已組裝"],
  "On Floor: Testing": ["On Floor: Testing", "在生产线上：测试中", "On Floor: Testing", "En el suelo: En prueba", "在生產線上：測試中"],
  "On Floor: Software Testing": ["On Floor: Software Testing", "在生产线上：软件测试中", "On Floor: Software Testing", "En el suelo: Pruebas de software", "在生產線上：軟件測試中"],
  "On Floor: 1st Round": ["On Floor: 1st Round", "在生产线上：第一轮", "On Floor: 1st Round", "En el suelo: Primera ronda", "在生產線上：第一輪"],
  "On Floor: 2nd Round": ["On Floor: 2nd Round", "在生产线上：第二轮", "On Floor: 2nd Round", "En el suelo: Segunda ronda", "在生產線上：第二輪"],
  "Packing: No Documentation": ["Packing: No Documentation", "包装：无文档", "Packing: No Documentation", "Empaque: Sin documentación", "包裝：無文檔"],
  "Packing: Documentation Completed": ["Packing: Documentation Completed", "包装：文档完成", "Packing: Documentation Completed", "Empaque: Documentación completada", "包裝：文檔完成"],
  "Packed": ["Packed", "已包装", "Packed", "Empacado", "已包裝"],
  "Shipped": ["Shipped", "已发货", "Shipped", "Enviado", "已發貨"],
  "Completed": ["Completed", "已完成", "Completed", "Completado", "已完成"],
  "Complete": ["Complete", "完成", "Complete", "Completar", "完成"],
  "Download PDF": ["Download PDF", "下载 PDF", "Download PDF", "Descargar PDF", "下載 PDF"],
  "download PDF": ["Download PDF", "下载 PDF", "Download PDF", "Descargar PDF", "下載 PDF"],
  "Reject": ["Reject", "拒绝", "Reject", "Rechazar", "拒絕"],
  "Accept": ["Accept", "接受", "Accept", "Aceptar", "接受"],
  "View": ["View", "查看", "View", "Ver", "查看"],
  "Mark as urgent": ["Mark as urgent", "标记为紧急", "Mark as urgent", "Marcar como urgente", "標記為緊急"],
  "See details": ["See details", "查看详细信息", "See details", "Ver detalles", "查看詳細信息"],
  "Customer Address": ["Customer Address", "客户地址", "Customer Address", "Dirección del cliente", "客戶地址"],
  "Urgency": ["Urgency", "优先等级", "Urgency", "Urgencia", "優先等級"],
  "Uploaded PDFs": ["UPLOAD PDFs", "上传PDF", "UPLOAD PDFs", "Cargar PDF", "上傳PDF"],
  "Machine History Log": ["Machine History Log", "机器历史记录", "Machine History Log", "Registro de historial de la máquina", "機器歷史記錄"],

  //Machine history Log
  "New History Entry": ["New History Entry", "新历史记录", "New History Entry", "Nueva entrada de historial", "新歷史記錄"],
  "Add New Entry": ["Add New Entry", "添加新条目", "Add New Entry", "Agregar nueva entrada", "添加新條目"],
  "Date": ["Date", "日期", "Date", "Fecha", "日期"],
  "Job Description": ["Job Description", "工作描述", "Job Description", "Descripción del job", "工作描述"],
  "Time In": ["Time In", "开始时间", "Time In", "Hora de inicio", "開始時間"],
  "Time Out": ["Time Out", "结束时间", "Time Out", "Hora de finalización", "結束時間"],
  "Total Time": ["Total Time", "总花费时间", "Total Time", "Tiempo total gastado", "總花費時間"],
  "Notes/Comment/SN": ["Notes/Comment/SN", "备注/评论/序列号", "Notes/Comment/SN", "Notas/Comentario/SN", "備註/評論/序列號"],

  //Inventory history Log
  "INBOUND": ["INBOUND", "入库", "INBOUND", "ENTRANTE", "入庫"],
  "OUTBOUND": ["OUTBOUND", "出库", "OUTBOUND", "SALIENTE", "出庫"],
  "ADD ITEM": ["ADD ITEM", "添加项目", "ADD ITEM", "AGREGAR ARTÍCULO", "添加项目"],
  "EDIT ITEM": ["EDIT ITEM", "编辑项目", "EDIT ITEM", "EDITAR ARTÍCULO", "編輯項目"],
  "DELETE ITEM": ["DELETE ITEM", "删除项目", "DELETE ITEM", "ELIMINAR ARTÍCULO", "刪除項目"],

  //inbound outbound
  "Shipped From": ["Shipped From", "发货地点", "Shipped From", "ENVIADO DESDE", "發貨地點"],
  "Shipped to": ["Shipped To", "发货至", "Shipped To", "ENVIADO A", "發貨至"],

  //Machine Order
  "Machine Orders": ["Machine Orders", "机器订单", "Machine Orders", "Orden de máquina", "機器訂單"],
  "Place a Machine Order": ["Place a Machine Order", "机器订单", "Place a Machine Order", "Realizar un pedido de máquina", "機器訂單"],
  "PO": ["PO", "采购订单", "PO", "Orden de compra", "採購訂單"],
  "Date of application": ["Date of application", "申请日期", "Date of application", "Fecha de solicitud", "申請日期"],
  "PO Date": ["PO Date", "采购订单日期", "PO Date", "Fecha de la orden de compra", "採購訂單日期"],
  "Expected Delivery Date": ["Expected Delivery Date", "预计交货日期", "Expected Delivery Date", "Fecha estimada de entrega", "預計交貨日期"],
  "Cabinet Assembly": ["Cabinet Assembly", "機殼主體", "Cabinet Assembly", "Ensamblaje de armario", "機殼主體"],
  "cabinetassembly": ["Cabinet Assembly", "機殼主體", "Cabinet Assembly", "Ensamblaje de armario", "機殼主體"],
  "Standard": ["Standard", "标准", "Standard", "Estándar", "標準"],
  "Labeling Machine": ["Labeling Machine", "贴标机", "Labeling Machine", "Máquina de etiquetado", "貼標機"],
  "Conveyor": ["Conveyor", "传送带", "Conveyor", "Transportador", "傳送帶"],
  "BarCode Printer": ["BarCode Printer", "条码打印机", "BarCode Printer", "Impresora de código de barras", "條碼列印機"],
  "Base Type": ["Base Type", "底座类型", "Base Type", "Tipo de base", "底座類型"],
  "Operation Mode": ["Operation Mode", "操作模式", "Operation Mode", "Modo de operación", "操作模式"],
  "Optional": ["Optional", "选配", "Optional", "Opcional", "選配"],
  "Others": ["Others", "其他", "Others", "Otros", "其他"],
  "Value Of Input Voltage": ["Value Of Input Voltage", "机器输入电压值", "Value Of Input Voltage", "Valor de voltaje de entrada", "機器輸入電壓值"],
  "Special Requirement": ["Special Requirement", "特殊要求", "Special Requirement", "Requisito especial", "特殊要求"],
  "Machine Serial": ["Machine Serial", "机器序列号", "Machine Serial", "Serial de la máquina", "機器序列號"],
  "Optional Barcode Printer": ["Optional Barcode Printer", "选配条码打印机", "Optional Barcode Printer", "Impresora de códigos de barras opcional", "選配條碼列印機"],
  "Optional Barcode Scanner": ["Optional Barcode Scanner", "选配条码扫描器", "Optional Barcode Scanner", "Escáner de códigos de barras opcional", "選配條碼掃描器"],
  "Barcode Printer": ["Barcode Printer", "条码打印机", "Barcode Printer", "Impresora de códigos de barras", "條碼列印機"],
  "Optional Base Type": ["Optional Base Type", "选配基础类型", "Optional Base Type", "Tipo de base opcional", "選配基礎類型"],
  "Optional Operation Mode": ["Optional Operation Mode", "选配操作模式", "Optional Operation Mode", "Modo de operación opcional", "選配操作模式"],
  "Input Voltage": ["Input Voltage", "机器输入电压", "Input Voltage", "Voltaje de entrada", "機器輸入電壓"],
  "Machine Serial Number": ["Machine Serial Number", "机器序列号", "Machine Serial Number", "Número de serie de la máquina", "機器序列號"],
  "Actual Delivery Date": ["Actual Delivery Date", "实际交货日期", "Actual Delivery Date", "Fecha de entrega real", "實際交貨日期"],
  "New Order": ["New Order", "新订单", "New Order", "Nuevo pedido", "新訂單"],
  "Requested Region": ["Requested Region", "请求区域", "Requested Region", "Región solicitada", "請求區域"],
  "Request By": ["Requested By", "请求者", "Requested By", "Solicitado Por", "請求者"],
  "Approve": ["Approve", "批准", "Approve", "APROBAR", "批准"],
  "Machine Order Confirmation": ["Machine Order Confirmation", "机器订单确认", "Machine Order Confirmation", "CONFIRMACIÓN DE ORDEN DE MÁQUINA", "機器訂單確認"],
  "Please Fill all serial number": ["Please Fill all serial number", "请填写所有序列号", "Please Fill all serial number", "POR FAVOR LLENE TODOS LOS NÚMEROS DE SERIE", "請填寫所有序列號"],
  "Confirm and Create": ["Confirm and Create", "确认并创建", "Confirm and Create", "CONFIRMAR Y CREAR", "確認並創建"],
  "Sender Email": ["Sender Email", "发件人邮箱", "Sender Email", "Correo electrónico del remitente", "發件人Email"],
  "Total Price": ["Total Price", "总价", "Total Price", "Precio total", "總價"],
  "Payment Terms": ["Payment Terms", "付款条款", "Payment Terms", "Términos de pago", "付款條款"],
  "Ship To": ["Ship To", "收货地址", "Ship To", "Enviar a", "收貨地址"],
  "Vendor": ["Vendor", "供应商", "Vendor", "Proveedor", "供應商"],
  "Vendor Address": ["Vendor Address", "供应商地址", "Vendor Address", "Dirección del proveedor", "供應商地址"],
  "Payment Method": ["Payment Method", "支付方式", "Payment Method", "Método de pago", "支付方式"],
  "Detail": ["Detail", "细节", "Detail", "Detalle", "細節"],
  "Machine Information": ["Machine Information", "机器信息", "Machine Information", "Información de la máquina", "機器信息"],
  "Category": ["Category", "类别", "Category", "Categoría", "類別"],
  "Item Name or Part Number": ["Item Name or Part Number", "项目名称或零件号", "Item Name or Part Number", "Nombre del artículo o número de parte", "項目名稱或零件號"],
  "Add a part to machine": ["Add a part to machine", "添加零件到机器", "Add a part to machine", "Agregar una pieza a la máquina", "添加零件到機器"],
  "Add machine information": ["Add machine information", "添加机器信息", "Add machine information", "Agregar información de la máquina", "添加機器信息"],
  "Ship Via": ["Ship Via", "运输方式", "Ship Via", "Enviar vía", "運輸方式"],
  "Incoterm": ["Incoterm", "国际贸易术语", "Incoterm", "Término Incoterm", "國際貿易術語"],
  "Production Parts Order": ["Production Parts Order", "生产零件订单", "Production Parts Order", "Orden de piezas de producción", "生產零件訂單"],

  //Prodution Parts
  "NEW PART ORDER": ["NEW PART ORDER", "新零件订单", "NEW PART ORDER", "Nuevo pedido de piezas", "新零件訂單"],
  "PO Number": ["PO Number", "采购订单号", "PO Number", "Número de pedido", "採購訂單號"],
  "Application Date": ["Application Date", "申请日期", "Application Date", "Fecha de solicitud", "申請日期"],
  "PaymentMethod": ["PAYMENTMETHOD", "支付方式", "PAYMENTMETHOD", "Método de pago", "支付方式"],
  "paymentmethod": ["PAYMENTMETHOD", "支付方式", "PAYMENTMETHOD", "Método de pago", "支付方式"],
  "ShipVia": ["Ship Via", "运输方式", "Ship Via", "Enviar vía", "運輸方式"],
  "shipvia": ["Ship Via", "运输方式", "Ship Via", "Enviar vía", "運輸方式"],
  "incoterm": ["Incoterm", "国际贸易术语", "Incoterm", "Término Incoterm", "國際貿易術語"],

  //messages
  "Are you sure you want to reject this item request?": [
    "Are you sure you want to reject this item request?",
    "您确定要拒绝此项请求吗？",
    "Are you sure you want to reject this item request?",
    "¿Está seguro de que desea rechazar esta solicitud de artículo?",
    "您確定要拒絕此商品要求嗎？"
  ],
  "Are you sure you want to approve this item request?": [
    "Are you sure you want to approve this item request?",
    "您确定要批准此项请求吗？",
    "Are you sure you want to approve this item request?",
    "¿Está seguro de que desea aprobar esta solicitud de artículo?",
    "您確定要批准此項請求嗎？"
  ],
  "Are you sure you want to delete this item?": [
    "Are you sure you want to delete this item?",
    "您确定要删除此项吗？",
    "Are you sure you want to delete this item?",
    "¿Está seguro de que desea eliminar este artículo?",
    "您確定要刪除此項目嗎？"
  ],


};

function GetCurrencySymbol(selectedRegion) {
  return currencySymbols[selectedRegion] || '$';
}

//selectedRegion must be "USA","CHINA","EU","MEXICO","TAIWAN"
function GetTextByRegion(text, selectedRegion) {
  const RegionIndex = regionIndices[selectedRegion] !== undefined ? regionIndices[selectedRegion] : 0;
  return translations[text] ? translations[text][RegionIndex] : text;
}

export { translations, GetTextByRegion, GetCurrencySymbol };