import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Grid, Button, Typography, Card, CardContent, IconButton, Box, Tab, Tabs,
  Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';
import NonConformingReportForm from '../components/Reports/NonConformingReportForm';
import DraftsList from '../components/Reports/DraftsList';
import DeleteConfirmationDialog from '../components/DeleteConfirmationDialog';
import CustomDataGrid from '../components/CustomDataGrid';
import StatusProgressBar from '../components/StatusProgressBar';
import USFinalReportForm from '../components/Reports/USFinalReportForm';
import { generateNonconformingPDF } from '../components/Reports/GenerateNonconformingPDF';
import useWindowSize from '../hooks/useWindowSize';
import ErrorDialog from '../components/ErrorDialog';
import SearchBar from '../components/SearchBar';
import MobileViewComponent from '../components/MobileView';
import { GetTextByRegion } from '../hooks/Translation';
import { useUser, useValidateRegions } from '../hooks/UserContext';
import isEqual from 'lodash/isEqual';
import roles from '../hooks/Roles';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const FormsPage = () => {
  const [isSavedFormsOpen, setIsSavedFormsOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [isFinalReportOpen, setIsFinalReportOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [viewFinalReportOpen, setViewFinalReportOpen] = useState(false);
  const [drafts, setDrafts] = useState([]);
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState(null); // State for error messages
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const { windowSize, smallWidth } = useWindowSize();
  const validateSelectedRegion = ["USA", "MEXICO", "EU", "TAIWAN"];
  const { selectedRegion, refreshUser, user: { username, userRights } } = useUser();
  useValidateRegions(selectedRegion, validateSelectedRegion);
  const connectionStringReport = `${backendString}/nonconforming`;

  const prevSelectedRegionRef = useRef();
  useEffect(() => {
    refreshUser().then(() => {
      const currentRegion = selectedRegion;
      if (prevSelectedRegionRef.current && !isEqual(prevSelectedRegionRef.current, currentRegion)) {
        handleCloseAll();
      }
      prevSelectedRegionRef.current = currentRegion;
    });
  }, [refreshUser, selectedRegion, isSavedFormsOpen, isReportOpen, isFinalReportOpen, viewFinalReportOpen, confirmDeleteOpen, tabValue]);

  const handleCloseAll = () => {
    setIsSavedFormsOpen(false);
    setIsReportOpen(false);
    setIsFinalReportOpen(false);
    setViewFinalReportOpen(false);
    setConfirmDeleteOpen(false);
    setSelectedReport(null);
    setTabValue(0);
  }

  const fetchReports = useCallback(async () => {
    const status = tabValue === 0 ? 'pendingsubmitted' : 'completed';
    let connectionString = '';

    try {
      connectionString = `${backendString}/nonconforming/status`;

      // Set the final URL based on the status
      const url = `${connectionString}/${status}?selectedRegion=${selectedRegion}`;
      const response = await axios.get(url, {
        headers: {
          'Authorization': sessionStorage.getItem('token'),
        },
      });

      setReports(response.data);
    } catch (error) {
      console.error('Error fetching reports:', error);
      setError(error.response?.data?.error); // Set error state
    }
  }, [tabValue, selectedRegion]);


  const fetchDrafts = useCallback(async () => {
    let connectionString = '';

    try {
      connectionString = `${backendString}/nonconforming`;


      const nonconformingResponse = await axios.get(`${connectionString}?selectedRegion=${selectedRegion}`, {
        headers: {
          'Authorization': sessionStorage.getItem('token'),
        },
      });


      const nonconformingDrafts = nonconformingResponse.data.map(draft => ({ ...draft, type: 'nonconforming' }));

      const allDrafts = [...nonconformingDrafts];
      const filteredDrafts = allDrafts.filter(draft =>
        (draft.type === 'nonconforming' && draft.reportBy === username)
      );

      setDrafts(filteredDrafts);
    } catch (error) {
      console.error('Error fetching drafts:', error.message);
      setError(error.response?.data?.error); // Set error state
    }
  }, [username, selectedRegion]);

  useEffect(() => {
    if (isSavedFormsOpen) {
      fetchDrafts();
    }
  }, [isSavedFormsOpen, fetchDrafts]);

  useEffect(() => {
    fetchReports();
  }, [fetchReports, selectedRegion]);

  useEffect(() => {
    let reportsToFilter = reports.filter(report =>
      (report.model && report.model.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (report.reportNumber && report.reportNumber.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    setFilteredReports(reportsToFilter);
  }, [searchQuery, reports]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOpenSavedForms = () => setIsSavedFormsOpen(true);
  const handleCloseSavedForms = () => setIsSavedFormsOpen(false);

  const handleSelectForm = (form) => {
    setIsSavedFormsOpen(false);
  };

  const handleOpenNonConformingReport = () => {
    setIsReportOpen(true);
    setSelectedReport(null);
  };

  const handleCloseNonConformingReport = () => {
    setIsReportOpen(false);
    setSelectedReport(null);
  };

  const handleCloseFinalReport = () => {
    setViewFinalReportOpen(false);
    setIsFinalReportOpen(false);
    setSelectedReport(null);
  };

  const handleSelectReport = (report) => {
    setSelectedReport(report);
    setIsReportOpen(true);
  };

  const handleOpenConfirmDeleteDialog = (report) => {
    setReportToDelete(report.row);
    setConfirmDeleteOpen(true);
  };

  const handleCloseConfirmDeleteDialog = () => {
    setReportToDelete(null);
    setConfirmDeleteOpen(false);
  };

  const handleDelete = async () => {
    handleCloseConfirmDeleteDialog();
    if (!reportToDelete) return;
    let connectionString = '';

    try {
      // Set the base connection string based on the selected region
      connectionString = `${backendString}/nonconforming/${reportToDelete._id}?selectedRegion=${selectedRegion}`;

      await axios.delete(connectionString, {
        headers: {
          'Authorization': sessionStorage.getItem('token'),
        },
      });
      setReports(prevData =>
        prevData.filter(item => item._id !== reportToDelete._id)
      );
    } catch (error) {
      console.error('Error deleting report:', error);
      setError(error.response?.data?.error); // Set error state
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null); // Reset error state
  };

  const handleEdit = (row) => {
    setSelectedReport(row.row);
    console.log(row.row.status);
    if (row.row.status === "Approved") {setIsFinalReportOpen(true)}
    else setIsReportOpen(true);
  };

  const handleView = (row) => {
    setSelectedReport(row.row);
    setViewFinalReportOpen(true);
  }

  const handleGeneratePDF = (report) => {
    generateNonconformingPDF(report.row, (doc) => {
      doc.save('nonconforming_report.pdf');
    }, (error) => {
      console.error('Failed to generate PDF:', error);
    }, selectedRegion);
  };

  function handleReportUpdate(updatedReport, post) {
    if (post) {
      setReports(prevReports => [...prevReports, updatedReport]);
    }
    else {
      setReports(prevReports =>
        prevReports.map(report =>
          report._id === updatedReport._id ? updatedReport : report
        )
      );
    }

  }

  const columns = [
    { field: 'dateReported',  flex: 1, headerAlign: 'center',      align:'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Date Reported', selectedRegion)}<br /></Typography>), },
    { field: 'model', flex: 1, headerAlign: 'center',      align:'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Model', selectedRegion)}<br /></Typography>), },
    { field: 'reportNumber', flex: 1, headerAlign: 'center',      align:'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Report Number', selectedRegion)}<br /></Typography>), },
    {
      field: 'status',
      flex: 1,
      headerAlign: 'center',      align:'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Progress', selectedRegion)}<br /></Typography>),
        renderCell: (params) => {
          // Map status values
          const mappedStatus = params.row.status === 'Pending'
            ? 'In Process'
            : params.row.status === 'Approved'
            ? 'Case Closed'
            : params.row.status;
      
          // Render StatusProgressBar with the mapped status
          return <StatusProgressBar status={mappedStatus} component={'reportpage'} selectedRegion={selectedRegion} />;
        },
    },
    {
      field: 'actions',
      headerAlign: 'center',      align:'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Actions', selectedRegion)}<br /></Typography>),
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{
            justifyContent: 'flex-start',
          }}
        >
          <Tooltip title={GetTextByRegion("View", selectedRegion)} arrow>
            <IconButton
              color="info"
              onClick={() => handleView(params)}
              aria-label="view"
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          {/* Edit Icon or Placeholder */}
          {params.row.status === 'Approved' || params.row.status === 'Pending' ? (
            <Tooltip title={GetTextByRegion("Edit", selectedRegion)} arrow>
              <IconButton
                color="primary"
                onClick={() => handleEdit(params)}
                aria-label="edit"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          ) : tabValue === 0 ? ( //HIDDEN
            <Tooltip title={GetTextByRegion("Edit", selectedRegion)} arrow>
              <IconButton
                disabled
                sx={{ opacity: 0 }} // Invisible placeholder
                aria-label="edit-placeholder"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          ) : null}

          {params.row.status === 'Completed' && (
            <>
              <Tooltip title={GetTextByRegion("download PDF", selectedRegion)} arrow>
                <IconButton
                  color="success"
                  onClick={() => handleGeneratePDF(params)}
                  aria-label="generate-pdf"
                >
                  <FileDownloadIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          {userRights >= roles['MANAGER'] && (
          <Tooltip title={GetTextByRegion("Delete", selectedRegion)} arrow>
            <IconButton
              color="secondary"
              onClick={() => handleOpenConfirmDeleteDialog(params)}
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  const mobileColumns = [
    { label: "Date Reported", name: "dateReported", type: "text", value: "dateReported", required: false, InputLabelProps: {}, position: 'left' },
    { label: "Model", name: "model", type: "text", value: "model", required: false, InputLabelProps: {}, position: 'left' },
    { label: "Report Number", name: "reportNumber", type: "text", value: "reportNumber", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Progress", name: "status", type: "progress", value: "status", required: true, InputLabelProps: {}, position: 'left' },
  ];

  return (
    <div style={{ height: windowSize.height * 0.7, width: windowSize.width }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h6" gutterBottom>
                {GetTextByRegion("Start New Report", selectedRegion)}
              </Typography>
              <Typography paragraph>
                {GetTextByRegion("Begin a new report by filling out the necessary details.", selectedRegion)}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenNonConformingReport}
                sx={{ mt: 1 }}
              >
                {GetTextByRegion("Start New Non-Conforming Report", selectedRegion)}
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h6" gutterBottom>
                {GetTextByRegion("Resume Saved Reports", selectedRegion)}
              </Typography>
              <Typography paragraph>
                {GetTextByRegion("Continue with a previously saved report.", selectedRegion)}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleOpenSavedForms}
              >
                {GetTextByRegion("Resume Saved Reports", selectedRegion)}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end" paddingRight={3}>
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder={GetTextByRegion("Search Reports...", selectedRegion)} />
      </Box>


      <Card>
        <CardContent>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="report tabs"
          >
            <Tab label={GetTextByRegion("In Process", selectedRegion)} />
            <Tab label={GetTextByRegion("Completed", selectedRegion)} />

          </Tabs>

          {windowSize.width > smallWidth ? (
            <div style={{ height: windowSize.height * 0.54, width: windowSize.width }}>
              <CustomDataGrid rows={filteredReports} columns={columns} />
            </div>
          ) : (
            <MobileViewComponent
              items={
                tabValue === 0 ? filteredReports :
                  filteredReports
              }
              columnData={mobileColumns}
              handleView={handleView}
              component={'reportpage'}
              handleEditClick={tabValue === 0 && handleEdit}
              handleDelete={tabValue === 1 && handleOpenConfirmDeleteDialog}
              handleGeneratePDF={tabValue === 1 && handleGeneratePDF}
              connectionString={connectionStringReport}
              selectedRegion={selectedRegion}
              isReport={true}
            />
          )}
        </CardContent>
      </Card>

      {isReportOpen && (
        <NonConformingReportForm
          open={isReportOpen}
          onClose={handleCloseNonConformingReport}
          onSave={fetchDrafts}
          onSubmit={handleReportUpdate}
          report={selectedReport}
        />
      )}


      {/* Edit Final Report Form */}
      <USFinalReportForm
        open={isFinalReportOpen}
        onClose={handleCloseFinalReport}
        onSubmit={handleReportUpdate}
        report={selectedReport}
      />
      <DraftsList
        open={isSavedFormsOpen}
        onClose={handleCloseSavedForms}
        drafts={drafts}
        onSelectForm={handleSelectForm}
        onSelectReport={handleSelectReport}
        fetchDrafts={fetchDrafts}
      />
      <DeleteConfirmationDialog
        open={confirmDeleteOpen}
        onClose={handleCloseConfirmDeleteDialog}
        onConfirm={handleDelete}
      />
      {/* View Final Report Form */}
      <USFinalReportForm
        open={viewFinalReportOpen}
        onClose={handleCloseFinalReport}
        report={selectedReport}
        viewOnly={true}
      />

      {/* Error Dialog */}
      <ErrorDialog
        open={!!error} // Ensure this is a boolean
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
};

export default FormsPage;
