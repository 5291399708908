// This is machine model page. You can add , delete , update machine model on this page.
import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import MachineModelTable from '../components/MachineModel/MachineModelTable';
import MachineModelForm from '../components/MachineModel/MachineModelForm';
import { Button } from '@mui/material';
import { useUser, useValidateRegions } from '../hooks/UserContext';
import isEqual from 'lodash/isEqual';
import ErrorDialog from '../components/ErrorDialog';
import { GetTextByRegion } from '../hooks/Translation';
import MachineConfiguration from '../components/MachineConfiguration';

const backendString = process.env.REACT_APP_BACKEND_STRING;

function MachineModel() {
  const [models, setModels] = useState([]);
  const [racks, setRacks] = useState([]);
  const [tubes, setTubes] = useState([]);
  const [detector, setDetector] = useState([]);
  const [pc, setPC] = useState([]);
  const [software, setSoftware] = useState([]);
  const [monitor, setMonitor] = useState([]);
  const [cabinetassembly, setCabinetAssembly] = useState([]);
  const [barcodeprinter, setBarcodePrinter] = useState([]);
  const [barcodescanner, setBarcodeScanner] = useState([]);
  const [options, setOptions] = useState([]);
  const [incoterm, setIncoterm] = useState([]);
  const [paymentmethod, setPaymentMethod] = useState([]);
  const [shipvia, setShipVia] = useState([]);
  const [isMachineModelPopupOpen, setIsMachineModelPopupOpen] = useState(false);
  const [isMachineConfigurationPopupOen, setIsMachineConfigurationPopupOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0); // State for tab selection
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const validateSelectedRegion = ["USA", "MEXICO", "EU", "CHINA", "TAIWAN"];
  const { selectedRegion, refreshUser } = useUser();
  useValidateRegions(selectedRegion, validateSelectedRegion);

  const prevSelectedRegionRef = useRef();
  useEffect(() => {
    refreshUser().then(() => {
      const currentRegion = selectedRegion;
      if (prevSelectedRegionRef.current && !isEqual(prevSelectedRegionRef.current, currentRegion)) {
        setIsMachineModelPopupOpen(false);
        setOpenEditDialog(false)
        setOpenDeleteDialog(false);
      }
      prevSelectedRegionRef.current = currentRegion;
    });
  }, [refreshUser, selectedRegion, isMachineModelPopupOpen, openEditDialog, openDeleteDialog]);

  const fetchItems = useCallback(async () => {
    try {
      let endpoint;
      // Determine the type of item to fetch based on tabValue
      let type;
      switch (tabValue) {
        case 0: // Machines tab
          type = 'machine';
          break;
        case 1: // Tubes tab
          type = 'rack';
          break;
        case 2: // Tubes tab
          type = 'tube';
          break;
        case 3: // Software tab
          type = 'fpd';
          break;
        case 4:
          type = 'software';
          break;
        case 5:
          type = 'monitor';
          break;
        case 6:
          type = 'cabinetassembly';
          break;
        case 7:
          type = 'barcodeprinter';
          break;
        case 8:
          type = 'barcodescanner';
          break;
        case 9:
          type = 'option';
          break;
        case 10:
          type = 'incoterm';
          break;
        case 11:
          type = 'paymentmethod';
          break;
        case 12:
          type = 'shipvia';
          break;
        case 13:
          type = 'pc';
          break;
        default:
          type = 'machine'; // Default to machines
      }

      // Construct the endpoint dynamically based on selected region and type
      endpoint = `${backendString}/dropdown?selectedRegion=${selectedRegion}&type=${type}`;
      const response = await axios.get(endpoint);
      // Set data based on the current tab
      switch (tabValue) {
        case 0:
          setModels(response.data); // Machines
          break;
        case 1:
          setRacks(response.data); // Racks
          break;
        case 2:
          setTubes(response.data); // Tubes
          break;
        case 3:
          setDetector(response.data); // FPD/Detector
          break;
        case 4:
          setSoftware(response.data); // software
          break;
        case 5:
          setMonitor(response.data); // monitor
          break;
        case 6:
          setCabinetAssembly(response.data); // cabinet
          break;
        case 7:
          setBarcodePrinter(response.data); // barcode printer
          break;
        case 8:
          setBarcodeScanner(response.data); //barcode scanner
          break;
        case 9:
          setOptions(response.data); // options
          break;
        case 10:
          setIncoterm(response.data); // incoterm
          break;
        case 11:
          setPaymentMethod(response.data); // payment
          break;
        case 12:
          setShipVia(response.data); // ship via
          break;      
        case 13:
          setPC(response.data); // PC
          break;
        default:
          setModels(response.data); // Default to machines
          break;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError("Error fetching data");
    }
  }, [selectedRegion, tabValue]);


  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const handleOpenPopup = () => {
    setIsMachineModelPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsMachineModelPopupOpen(false);
  };

  const handleAddMachineModel = (newModel) => {
    switch (tabValue) {
      case 0:
        setModels((prevModels) => [...prevModels, newModel]); // Machines
        break;
      case 1:
        setRacks((prevRacks) => [...prevRacks, newModel]); // Racks
        break;
      case 2:
        setTubes((prevTubes) => [...prevTubes, newModel]); // Tubes
        break;
      case 3:
        setDetector((prevDetector) => [...prevDetector, newModel]); // Detectors
        break;
      case 4:
        setSoftware((prevSoftware) => [...prevSoftware, newModel]); // Software
        break;
      case 5:
        setMonitor((prevMonitor) => [...prevMonitor, newModel]); // Monitor
        break;
      case 6:
        setCabinetAssembly((prevCabinetAssembly) => [...prevCabinetAssembly, newModel]); // Cabinet
        break;
      case 7:
        setBarcodePrinter((prevBarcodePrinters) => [...prevBarcodePrinters, newModel]); // printer
        break;
      case 8:
        setBarcodeScanner((prevBarcodeScanners) => [...prevBarcodeScanners, newModel]); // scanner
        break;
      case 9:
        setOptions((prevOptions) => [...prevOptions, newModel]); // options
        break;
      case 10:
        setIncoterm((prevIncoterms) => [...prevIncoterms, newModel]); // incoterm
        break;
      case 11:
        setPaymentMethod((prevPaymentMethods) => [...prevPaymentMethods, newModel]); // payment method
        break;
      case 12:
        setShipVia((prevShipVia) => [...prevShipVia, newModel]); // ship via
        break;
      case 13:
        setPC((prevPC) => [...prevPC, newModel]); // PCs
        break;
      default:
        setModels((prevModels) => [...prevModels, newModel]); // Default to Machines
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleMachineConfigurationPopup = () => {
    setIsMachineConfigurationPopupOpen(true);
  }
  const handleCloseMachineConfiguration = () => {
    setIsMachineConfigurationPopupOpen(false);
  }

  return (

    <div className="machine-model-container">
      <Button variant="contained" color="primary" sx={{ mb: 1 }} onClick={handleOpenPopup}>
        {GetTextByRegion("Add New Model", selectedRegion)}
      </Button>
      {/* <Button variant="contained" color="primary" sx={{ mb: 1, ml:2 }} onClick={handleMachineConfigurationPopup}>
      {GetTextByRegion("Add Machine Configurations", selectedRegion)}
      </Button> */}
      {isMachineModelPopupOpen && (
        <MachineModelForm tabValue={tabValue} onAdd={handleAddMachineModel} onClose={handleClosePopup} />
      )
      }
      {isMachineConfigurationPopupOen && (

        <MachineConfiguration open={isMachineConfigurationPopupOen} onClose={handleCloseMachineConfiguration} />
      )}
      <MachineModelTable
        data={models}
        setData={setModels}
        racks={racks}
        setRacks={setRacks}
        tubes={tubes}
        setTubes={setTubes}
        detector={detector}
        setDetector={setDetector}
        software={software}
        setSoftware={setSoftware}
        monitor={monitor}
        setMonitors={setMonitor}
        cabinetassembly={cabinetassembly}
        setCabinetAssembly={setCabinetAssembly}
        barcodeprinter={barcodeprinter}
        setBarcodePrinter={setBarcodePrinter}
        barcodescanner={barcodescanner}
        setBarcodeScanner={setBarcodeScanner}
        options={options}
        setOptions={setOptions}
        incoterm={incoterm}
        setIncoterm={setIncoterm}
        paymentmethod={paymentmethod}
        setPaymentMethod={setPaymentMethod}
        shipvia={shipvia}
        setShipVia={setShipVia}
        pc={pc}
        setPC={setPC}
        openEditDialog={openEditDialog}
        setOpenEditDialog={setOpenEditDialog}
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />

      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
}

export default MachineModel;