import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  Typography,
  IconButton,
  Divider,
  Box
} from '@mui/material';
import { GetTextByRegion } from '../../hooks/Translation';
import AddIcon from '@mui/icons-material/Add';
import { useUser } from '../../hooks/UserContext';
import axios from 'axios';
import DeleteIcon from "@mui/icons-material/Delete"; // Import Material-UI Delete Icon


const backendString = process.env.REACT_APP_BACKEND_STRING;
const today = new Date().toISOString().split('T')[0]; // Get today's date

const ChinaReportForm = ({ open, onClose, onSubmit, initialValues }) => {
  const [formValues, setFormValues] = useState({
    dateReported: '',
    model: '',
    reportNumber: '',
    serialNumber: '',
    customer: '',
    reportBy: '',
    issueType: '',
    problem: '',
    description: '',
    suggestedSolution: '',
    formName: '',
    reportedRegion: '',
    // dateResolved: '',
    // details: '',
    entry: [{ _id: 0, dateResolved: today, details: '' }], // initialize as array
    files: [],
    dateResolvedPermanent: '',
    ECN: '',
  });

  const { selectedRegion } = useUser();
  const [newFiles, setNewFiles] = useState([]);
  const [fileNames, setFileNames] = useState({});
  const [filesToDelete, setFilesToDelete] = useState([]);

  useEffect(() => {
    if (initialValues) {
      setFormValues(initialValues);
    }
    else {
      setFormValues({
        dateReported: '',
        model: '',
        reportNumber: '',
        serialNumber: '',
        customer: '',
        reportBy: '',
        issueType: '',
        problem: '',
        description: '',
        suggestedSolution: '',
        formName: '',
        reportedRegion: '',
        // dateResolved: '',
        // details: '',
        entry: [{ _id: 0, dateResolved: today, details: '' }], // initialize as array
        files: [],
        dateResolvedPermanent: '',
        ECN: '',
      });
    }
  }, [initialValues]);

  useEffect(() => {
    const fetchFileNames = async () => {
      if (!formValues.files || formValues.files.length === 0) return;

      try {
        const fileIds = formValues.files.filter(file => typeof file === 'string'); // Existing file IDs

        // Fetch names for existing files
        const names = await Promise.all(
          fileIds.map(id =>
            axios
              .get(`${backendString}/nonconforming/files/names/${id}?selectedRegion=${formValues.reportedRegion}`)
              .then(response => ({ _id: id, filename: response.data }))
              .catch(error => {
                console.error(`Error fetching file name for ${id}:`, error);
                return { _id: id, filename: "Unknown File" }; // Fallback filename
              })
          )
        );


        // Create a map of fileId -> filename
        const namesMap = names.reduce((acc, file) => {
          acc[file._id] = file.filename;
          return acc;
        }, {});

        setFileNames(namesMap);
      } catch (err) {
        console.error("Error fetching file names:", err);
      }
    };

    fetchFileNames();
  }, [formValues.files, selectedRegion]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleEntryChange = (index, field, value) => {
    setFormValues(prevValues => {
      const updatedEntries = [...prevValues.entry];
      updatedEntries[index] = { ...updatedEntries[index], [field]: value };
      return { ...prevValues, entry: updatedEntries };
    });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files); // Get the selected files
    setNewFiles((prevFiles) => [...prevFiles, ...files]); // Append new files
    e.target.value = null;
  };

  const addNewEntry = () => {
    setFormValues(prevValues => ({
      ...prevValues,
      entry: [...prevValues.entry, { _id: prevValues.entry.length, dateResolved: today, details: '' }],
    }));
  };

  const handleSubmit = async () => {
    if (filesToDelete && filesToDelete.length > 0) {
      try {
        // Send all the files to delete in one request
        const deleteRequest = {
          files: filesToDelete, // List of files to delete
          formId: formValues._id, // Form ID
          selectedRegion: formValues.reportedRegion, // Region
        };

        console.log(deleteRequest);

        // Send the DELETE request with the files to be deleted
        await axios.delete(`${backendString}/nonconforming/files`, { data: deleteRequest });

        console.log('Files deleted successfully');
      } catch (error) {
        console.error('Error deleting files:', error);
      }
    }

    // Submit the form with the remaining files (or new ones)
    onSubmit(formValues, newFiles);
  };



  const handleDeleteNewFile = (fileToDelete) => {
    const updatedNewFiles = newFiles.filter(file => file !== fileToDelete);
    setNewFiles(updatedNewFiles); // Update the newFiles state
  };

  const handleDeleteFile = (file) => {
    // Remove the file from formValue.files
    setFormValues((prevFormValue) => ({
      ...prevFormValue,
      files: prevFormValue.files.filter((item) => item !== file),
    }));
    // Remove the file from fileNames
    setFileNames((prevFileNames) => {
      const newFileNames = { ...prevFileNames }; // Make a shallow copy of the object
      delete newFileNames[file]; // Delete the file (key) from the object
      return newFileNames; // Return the updated object
    });
    setFilesToDelete((prevFilesToDelete) => [...prevFilesToDelete, file]);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{GetTextByRegion("Nonconforming Report", selectedRegion)}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              label={GetTextByRegion("Date Reported", selectedRegion)}
              type="date"
              name="dateReported"
              value={formValues.dateReported}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              label={GetTextByRegion("Model", selectedRegion)}
              name="model"
              value={formValues.model}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              label={GetTextByRegion("Report Number", selectedRegion)}
              name="reportNumber"
              value={formValues.reportNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              label={GetTextByRegion("Serial Number", selectedRegion)}
              name="serialNumber"
              value={formValues.serialNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              label={GetTextByRegion("Customer", selectedRegion)}
              name="customer"
              value={formValues.customer}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              label={GetTextByRegion("Report By", selectedRegion)}
              name="reportBy"
              value={formValues.reportBy}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              label={GetTextByRegion("Issue Type", selectedRegion)}
              name="issueType"
              value={formValues.issueType}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              label={GetTextByRegion("Problem", selectedRegion)}
              name="problem"
              value={formValues.problem}
              onChange={handleChange}
              multiline
              rows={2}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              label={GetTextByRegion("Description", selectedRegion)}
              name="description"
              value={formValues.description}
              onChange={handleChange}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              label={GetTextByRegion("Suggested Solution", selectedRegion)}
              name="suggestedSolution"
              value={formValues.suggestedSolution}
              onChange={handleChange}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12} mb={6}>
            <label>{GetTextByRegion("Images", selectedRegion)}:</label>
            <Grid container spacing={2}>
              {initialValues.images && initialValues.images.length > 0 ? (
                initialValues.images.map((image, index) => (
                  <Grid item xs={3} key={index}>
                    <a
                      href={`${backendString}/nonconforming/images/` + image + `?reportedRegion=${formValues.reportedRegion}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={`${backendString}/nonconforming/images/` + image + `?reportedRegion=${formValues.reportedRegion}`} // Assuming `image` contains the URL or base64 encoded data
                        alt={`Preview ${index + 1}`}
                        style={{ width: '100%', height: '100%', borderRadius: 8 }}
                      />
                    </a>
                  </Grid>
                ))
              ) : (
                <Typography mt={3} ml={2} >{GetTextByRegion("No images to preview", selectedRegion)}</Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {GetTextByRegion("Filled in by CHINA", selectedRegion)}
            </Typography>
            <Divider fullWidth />
          </Grid>

          {formValues.entry.map((entry, index) => (
            <React.Fragment key={index}>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label={GetTextByRegion("Date Resolved", selectedRegion)}
                  type="date"
                  value={entry.dateResolved}
                  onChange={(e) => handleEntryChange(index, 'dateResolved', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  multiline
                  label={GetTextByRegion("Details", selectedRegion)}
                  value={entry.details}
                  onChange={(e) => handleEntryChange(index, 'details', e.target.value)}
                />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12} >
            <Box display="flex" alignItems="center">
              <IconButton onClick={addNewEntry} color="primary">
                <AddIcon />
              </IconButton>
              <Typography>{GetTextByRegion("Add Entry", selectedRegion)}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1"> {GetTextByRegion("Upload Images", selectedRegion)}:</Typography>
            <Button
              variant="contained"
              component="label"
              color="primary"
            >
              {GetTextByRegion("Upload File", selectedRegion)}
              <input
                type="file"
                name='files'
                hidden
                multiple
                onChange={(e) => handleFileChange(e)} // Pass the index here
              />
            </Button>

            <Grid item xs={12} mb={6} mt={2}>
              <label>{GetTextByRegion("Files", selectedRegion)}:</label>
              <Grid container spacing={2} alignItems="center" direction="row">
                {formValues.files && formValues.files.length > 0 && (
                  formValues.files.map((file, index) => {
                    const fileId = typeof file === "string" ? file : file._id; // Handle both existing and new files
                    const fileUrl = `${backendString}/nonconforming/files/${fileId}`;


                    return (
                      <Grid item key={index}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                              <Typography variant="body2">{fileNames[fileId]?.name || "Unknown File"}</Typography>
                            </a>
                          </Grid>
                          <Grid item>
                            <IconButton onClick={() => handleDeleteFile(fileId)} color="error">
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })
                )}
                {newFiles.length > 0 && (
                  newFiles.map((file, index) => (
                    <Grid item key={index}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <Typography variant="body2">{file.name}</Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={() => handleDeleteNewFile(file)}
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
                )}
              </Grid>

              <Grid container spacing={2} direction="row" alignItems="center">

              </Grid>
            </Grid>





          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label={GetTextByRegion("Date Resolved Permanent", selectedRegion)}
              type="date"
              name="dateResolvedPermanent"
              value={formValues.dateResolvedPermanent}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={GetTextByRegion("ECN", selectedRegion)}
              name="ECN"
              value={formValues.ECN}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">{GetTextByRegion("Cancel", selectedRegion)}</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">{GetTextByRegion("Submit", selectedRegion)}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChinaReportForm;
