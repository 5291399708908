import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, IconButton, Tabs, Tab, Tooltip, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';
import CustomDataGrid from '../components/CustomDataGrid';
import ChinaReportForm from '../components/Reports/ChinaReportForm';
import USFinalReportForm from '../components/Reports/USFinalReportForm'; // Assuming you have this component
import useWindowSize from '../hooks/useWindowSize';
import ErrorDialog from '../components/ErrorDialog';
import MobileViewComponent from '../components/MobileView';
import SearchBar from '../components/SearchBar'; // Import the SearchBar component
import { GetTextByRegion } from '../hooks/Translation';
import { useUser, useValidateRegions } from '../hooks/UserContext';
import roles from '../hooks/Roles';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const ChinaReportPage = () => {
  const [reports, setReports] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [filteredReports, setFilteredReports] = useState([]);
  const { windowSize, smallWidth } = useWindowSize();
  const [error, setError] = useState(null); // State for error messages
  const [searchQuery, setSearchQuery] = useState(""); // State for the search query
  const validateSelectedRegion = ["CHINA"];
  const { selectedRegion, refreshUser, user: { userRights } } = useUser();
  useValidateRegions(selectedRegion, validateSelectedRegion);

  useEffect(() => {
    refreshUser();
  }, [refreshUser, openDialog, tabValue]);

  // Fetch reports based on the selected tab
  const fetchReports = useCallback(async () => {
    const status = tabValue === 0 ? 'pending' : 'approvedcompleted'; // Tab 0 is for pending, Tab 1 is for completed
    try {
      const response = await axios.get(`${backendString}/nonconforming/status/${status.toLowerCase()}?selectedRegion=${selectedRegion}`, {
        headers: {
          'Authorization': sessionStorage.getItem('token'),
        },
      });
      setReports(response.data);
    } catch (error) {
      console.error('Error fetching reports:', error);
      setError(error.response?.data?.error); // Set error state
    }
  }, [selectedRegion, tabValue]);

  useEffect(() => {
    fetchReports();
  }, [tabValue, fetchReports]); // Fetch reports when the tab value changes

  // Filter reports based on the search query
  useEffect(() => {
    setFilteredReports(
      reports.filter((report) =>
        report.model.toLowerCase().includes(searchQuery.toLowerCase()) ||
        report.reportNumber.toLowerCase().includes(searchQuery.toLowerCase())
        // Add more fields to filter on if necessary
      )
    );
  }, [reports, searchQuery]);

  const handleEditClick = (report) => {
    setSelectedReport(report);
    setOpenDialog(true);
  };

  const handleViewClick = (report) => {
    setSelectedReport(report);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedReport(null);
  };

  const handleCloseErrorDialog = () => {
    setError(null); // Reset error state
  };

  const handleFormSubmit = async (updatedReport, newFiles) => {
    try {

      const formData = new FormData();

      let reportWithApprovedStatus = updatedReport
      if (updatedReport.ECN) {
        // Modify the updatedReport to set its status to 'approved'
        reportWithApprovedStatus = {
          ...updatedReport,
          status: 'Approved',
        };
        formData.append('status', reportWithApprovedStatus['status']);
      }


      // Append all fields except files and images
      // Object.keys(reportWithApprovedStatus).forEach((key) => {
      //   if (key !== 'files' && key !== 'images' && key !== 'entry') {
      //     formData.append(key, reportWithApprovedStatus[key]); // Append as is, no stringifying
      //   }
      // });

      if (reportWithApprovedStatus['dateResolvedPermanent']) {
        formData.append('dateResolvedPermanent', reportWithApprovedStatus['dateResolvedPermanent']);
      }

      if (reportWithApprovedStatus['ECN']) {
        formData.append('ECN', reportWithApprovedStatus['ECN']);
      }

      // Serialize the `entry` array to JSON and append as a single field
      if (updatedReport.entry && updatedReport.entry.length > 0) {
        formData.append('entry', JSON.stringify(updatedReport.entry));
      }


      // Appending files
      if (updatedReport.files && updatedReport.files.length > 0) {
        updatedReport.files.forEach((file) => {
          formData.append('files', file);  // Using array-like naming for backend
        });
      }

      // // Appending images (if any)
      // if (updatedReport.images && updatedReport.images.length > 0) {
      //   updatedReport.images.forEach((image) => {
      //     formData.append('images', image);  // Using array-like naming for backend
      //   });
      // }

      if (newFiles && newFiles.length > 0) {
        newFiles.forEach((file) => {
          formData.append('newFiles', file);
        });
      }

      // Make the PUT request to update the report on the server
      const response = await axios.put(
        `${backendString}/chinanonconforming/${updatedReport._id}?reportedRegion=${updatedReport.reportedRegion}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: sessionStorage.getItem('token'),
          },
        }
      );

      // Update the reports list with the updated report and change its status
      setReports((prevReports) =>
        prevReports
          .map((report) =>
            report._id === updatedReport._id ? response.data : report
          )
          // Optionally remove the report from the current tab if it shouldn't be shown there anymore
          .filter((report) =>
            tabValue === 0 ? report.status === 'Pending' : report.status === 'Approved'
          )
      );
      if (updatedReport.ECN) {
        // Get the submitted by user's email
        const emailresponse = await axios.get(
          `${backendString}/users/email/${updatedReport.reportBy}`
        );

        // Send email saying approved
        if (emailresponse.data.email) {
          const emailData = {
            email: emailresponse.data.email,
          };

          // Send the email
          await axios.post(
            `${backendString}/nonconforming/send-email/approved?reportedRegion=${updatedReport.reportedRegion}`,
            emailData
          );
        }
      }

      // Close the dialog
      handleCloseDialog();
    } catch (error) {
      console.error('Error updating report:', error);
      setError(error.response?.data?.error); // Set error state

      if (updatedReport.ECN) {
        // Modify the updatedReport to set its status to 'pending'
        const reportWithPendingStatus = {
          ...updatedReport,
          status: 'Pending',
        };

        // Make the PUT request to update the report on the server
        await axios.put(
          `${backendString}/nonconforming/${updatedReport._id}?reportedRegion=${updatedReport.reportedRegion}`,
          reportWithPendingStatus,
          {
            headers: {
              Authorization: sessionStorage.getItem('token'),
            },
          }
        );
      }
    }
  };



  const exportToExcel = () => {
    const dataToExport = filteredReports.map(report => {
      const { _id, ...rest } = report;
      return {
        ...rest,
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'chinaReport.xlsx');
  };

  const exportToCSV = () => {
    const dataToExport = filteredReports.map(report => {
      const { _id, ...rest } = report;
      return {
        ...rest,
      };
    });
    const csvData = Papa.unparse(dataToExport);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'chinaReport.csv';
    link.click();
  };

  const columns = [
    {
      field: 'dateReported', headerName: 'Date Reported', flex: 1, renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>{GetTextByRegion("Date Reported", selectedRegion)}</Typography>
      ), headerAlign: 'center', align: 'center'
    },
    {
      field: 'model', headerName: 'Model', flex: 1, renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>{GetTextByRegion("Model", selectedRegion)}</Typography>
      ), headerAlign: 'center', align: 'center'
    },
    {
      field: 'reportNumber', headerName: 'Report Number', flex: 1, renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>{GetTextByRegion("Report Number", selectedRegion)}</Typography>
      ), headerAlign: 'center', align: 'center'
    },
    // Add more columns as needed
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      flex: 1,
      align: 'center', // Center the cell content
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>{GetTextByRegion("Actions", selectedRegion)}</Typography>
      ),
      renderCell: (params) => (
        tabValue === 0 ? (
          <Tooltip title={GetTextByRegion("Edit", selectedRegion)}>
            <IconButton color="primary" onClick={() => handleEditClick(params.row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={GetTextByRegion("View", selectedRegion)}>
            <IconButton color="primary" onClick={() => handleViewClick(params.row)}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        )
      ),
    },
  ];
  const mobileColumns = [
    { label: "Date Reported", label_chinese: "报告日期", name: "dateReported", type: "text", value: "dateReported", required: false, InputLabelProps: {}, position: 'left', flex: 1, headerAlign: 'center' },
    { label: "Model", label_chinese: "模型", name: "model", type: "text", value: "model", required: false, InputLabelProps: {}, position: 'left', flex: 1, headerAlign: 'center' },
    { label: "Report Number", label_chinese: "报告编号", name: "reportNumber", type: "text", value: "reportNumber", required: false, InputLabelProps: {}, position: 'left', flex: 1, headerAlign: 'center' },
  ];

  return (
    <div style={{ height: windowSize.height * 0.8, width: windowSize.width }}>
      {userRights >= roles['SERVICE'] && (
        <div style={{
          display: 'flex',
          flexDirection: 'row', // Stack buttons in column for mobile
          position: windowSize.width > smallWidth ? 'absolute' : 'relative', // Absolute on larger screens
          left: windowSize.width > smallWidth ? 0 : 'auto', // Align left on larger screens
        }}>
          <Button onClick={exportToCSV} sx={{ marginRight: 1 }}>
            {GetTextByRegion("Export to CSV", selectedRegion)}
          </Button>
          <Button onClick={exportToExcel}>
            {GetTextByRegion("Export to Excel", selectedRegion)}
          </Button>
        </div>)}

      <Box sx={{ my: 4 }}>
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder={"查詢..."} />
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          aria-label="report status tabs"
        >
          <Tab label={GetTextByRegion("In Process", selectedRegion)} style={{ fontWeight: 'bold', fontSize: 14 }} />
          <Tab label={GetTextByRegion("Case Closed", selectedRegion)} style={{ fontWeight: 'bold', fontSize: 14 }} />
        </Tabs>

        {windowSize.width > smallWidth ? (
          <div style={{ height: windowSize.height * 0.7, width: '100%', fontWeight: 'bold' }}>
            <CustomDataGrid
              rows={filteredReports}
              columns={columns}
            />
          </div>
        ) : tabValue === 0 ? (<MobileViewComponent items={filteredReports} columnData={mobileColumns} handleEditClick={handleEditClick} selectedRegion={selectedRegion} />
        ) : tabValue === 1 ? (<MobileViewComponent items={filteredReports} columnData={mobileColumns} handleView={handleViewClick} selectedRegion={selectedRegion} />
        ) : null}
      </Box>
      {selectedReport && (
        tabValue === 0 ? (
          <ChinaReportForm
            open={openDialog}
            onClose={handleCloseDialog}
            onSubmit={handleFormSubmit}
            initialValues={selectedReport}
          />
        ) : (
          <USFinalReportForm
            open={openDialog}
            onClose={handleCloseDialog}
            report={selectedReport}
            viewOnly={true} // Assuming USFinalReportForm has a prop for read-only mode
          />
        )
      )}

      {/* Error Dialog */}
      <ErrorDialog
        open={!!error} // Ensure this is a boolean
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
};

export default ChinaReportPage;
