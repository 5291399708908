import React, { useState, useEffect, useRef } from 'react';
import Resizer from 'react-image-file-resizer'; // Import the Resizer
import axios from 'axios';
import ErrorDialog from '../ErrorDialog'; // Import the ErrorDialog component
import IconDownloadPDFButton from '../IconButtons/IconDownloadPDFButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingSpinner from '../LoadingSpinner';
import placeholderImage from "../../images/placeholder-image.jpg"
import { Button, TextField, FormControlLabel, Checkbox, Box, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, Grid, Tabs, Tab, IconButton, Typography, Card, CardContent, CardActions, Autocomplete } from '@mui/material';
import { GetTextByRegion } from '../../hooks/Translation';
import ModelDropdown from '../ModelDropdown';
import USStatusDropdown from '../USStatusDropdown';
import { useUser } from '../../hooks/UserContext';
import MachineHistoryLog from './MachineHistoryLog';
import PackingListForm from '../PackingList/PackingListForm';
import roles from '../../hooks/Roles';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const EditMachineForm = ({ open, onClose, machine: originalMachine, onSubmit, initialIsView = false, fetchMachines }) => {
  const [isView, setIsView] = useState(initialIsView);
  const [machine, setMachine] = useState(originalMachine);
  const [machineCopy] = useState({ ...originalMachine });
  const [imagePreview, setImagePreview] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [originalImage, setOriginalImage] = useState(null);
  const [loading, setLoading] = useState(false); // New loading state
  // const [pdfFiles, setPdfFiles] = useState(originalMachine.pdfs||[]); // Add state for PDF files
  const [pdfNames, setPdfNames] = useState({}); // State to hold PDF names
  const [existingPdfs, setExistingPdfs] = useState([]);
  const [newPdfs, setNewPdfs] = useState([]);
  const [pdfFilesToDelete, setPdfFilesToDelete] = useState([]); // Add state for PDF files
  const [error, setError] = useState(null); // Add error state
  const [validationErrors, setValidationErrors] = useState({});
  const { selectedRegion, userRights } = useUser();
  const [packingListConfirmationDialog, setPackingListConfirmationDialog] = useState(false)
  const [tubeModels, setTubeModels] = useState([]);
  const [detectorModels, setDetectorModels] = useState([]);
  const [softwareModels, setSoftwareModels] = useState([]);

  useEffect(() => {
    const fetchData = async (type, setter) => {
      try {
        const response = await axios.get(
          `${backendString}/dropdown?selectedRegion=${selectedRegion}&type=${type}`,
          {
            headers: {
              Authorization: sessionStorage.getItem("token"),
            },
          }
        );
        const models = response.data.map((item) => item.ModelName);
        setter(models);
      } catch (error) {
        console.error(`Error fetching ${type}:`, error);
      }
    };

    fetchData("tube", setTubeModels);
    fetchData("fpd", setDetectorModels);
    fetchData("software", setSoftwareModels);
    //...add as many as you can
  }, []);

  useEffect(() => {
    if (machine && machine.image) {
      if (typeof machine.image === 'string') {
        setImagePreview(backendString + `/machines/images/${machine.image}?selectedRegion=${selectedRegion}`);
      } else {
        setImagePreview(URL.createObjectURL(machine.image));
      }
    } else {
      setImagePreview(null);
    }
  }, [machine, selectedRegion]);

  useEffect(() => {
    const fetchPdfNames = async () => {
      setLoading(true);
      try {
        const pdfIds = machine.pdfs.filter(pdf => typeof pdf === 'string'); // Existing PDFs
        const newPdfObjects = machine.pdfs.filter(pdf => typeof pdf !== 'string'); // Newly uploaded PDFs

        // Fetch names for existing PDFs
        const names = await Promise.all(pdfIds.map(id =>
          axios.get(`${backendString}/machines/files/names/${id}?selectedRegion=${selectedRegion}`)
            .then(response => response.data)
        ));

        const namesMap = pdfIds.reduce((acc, id, index) => {
          acc[id] = names[index];
          return acc;
        }, {});

        setExistingPdfs(pdfIds);
        setPdfNames(namesMap);
        setNewPdfs(newPdfObjects);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPdfNames();
  }, [machine.pdfs, selectedRegion]);

  const handleOpenPacking = () => setPackingListConfirmationDialog(true);
  const handleClosePacking = () => setPackingListConfirmationDialog(false);

  const getMachinePackingListTemplate = async () => {
    try {

      const response = await axios.get(
        `${backendString}/packingList/${machine.model}`
        // Uncomment if you need authorization
        // , { headers: { 'Authorization': sessionStorage.getItem('token') } }
      );

      const { description, partnumber, quantity } = response.data;

      const updatedMachine = {
        ...machine,
        packing_descriptions: description || [],
        packing_partnumbers: partnumber || [],
        packing_quantities: quantity || [],
      };

      setMachine(updatedMachine);
      handleOpenPacking();

    } catch (error) {
      setError(error.response?.data?.message);
      console.error("Error fetching packing list:", error);
    }
  };

  useEffect(() => {
    // Only update originalImage if imagePreview has changed
    setOriginalImage(imagePreview);
  }, [imagePreview]);

  const handleChange = (e) => {
    const { name, files, value } = e.target;

    if (name === 'image' && files && files.length > 0) {
      const file = files[0];
      Resizer.imageFileResizer(
        file,
        300,
        300,
        'JPEG',
        100,
        0,
        (uri) => {
          setMachine({
            ...machine,
            [name]: uri,
          });
          setOriginalImage(file); // Store the original File object
          setImagePreview(URL.createObjectURL(file)); // Update image preview with Object URL
        },
        'blob'
      );
    } else if (name === 'image' && files && files.length === 0) {
      // File input cleared, revert to original image
      setMachine({
        ...machine,
        image: originalImage || ''
      });
      if (originalImage) {
        setImagePreview(URL.createObjectURL(originalImage)); // Update image preview with original image
      } else {
        setImagePreview(placeholderImage); // Clear image preview if no original image
      }
    } else if (name === 'pdfs' && files && files.length > 0) {
      const newPdf = Array.from(files);
      setMachine((prevMachine) => ({
        ...prevMachine,
        pdfs: prevMachine.pdfs ? [...prevMachine.pdfs, ...newPdf] : [...newPdf],
      }));
    } else {
      setMachine((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleDeletePdf = (identifier, isExisting) => {
    if (isExisting) {
      // Remove existing PDF
      setMachine((prevMachine) => {
        const updatedPdfFiles = prevMachine.pdfs.filter(pdf => pdf !== identifier);
        return {
          ...prevMachine,
          pdfs: updatedPdfFiles,
        };
      });

      setPdfFilesToDelete((prevPdfFilesToDelete) => [
        ...prevPdfFilesToDelete,
        identifier,
      ]);

    } else {
      // Remove new PDF
      setMachine((prevMachine) => {
        const updatedPdfFiles = prevMachine.pdfs.filter((_, index) => index !== identifier);
        return {
          ...prevMachine,
          pdfs: updatedPdfFiles,
        };
      });
    }
  };

  const fileInputRef = useRef(null);

  const handleFileInputClick = () => {
    // Clear file input value to trigger onChange when cancel is clicked
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!machine.serial) errors.serial = 'Serial Number is required';
    if (!machine.model) errors.model = 'Model is required';
    if (!machine.status) errors.status = 'Status is required';
    if (!machine.poStatus) errors.poStatus = 'PO Status is required';
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleSubmit = async () => {
    const isValidationError = validateForm();
    if (!isValidationError) {
      return;
    }
    setLoading(true);
    try {
      // Delete files
      await Promise.all(
        pdfFilesToDelete.map(async (file) => {
          try {
            await axios.delete(`${backendString}/machines/files/${file}?selectedRegion=${selectedRegion}`, {
              headers: {
                'Authorization': sessionStorage.getItem('token'),
              },
            });
          } catch (error) {
            console.error(`Error deleting file with ID ${file}:`, error);
            setError(error.response?.data?.error);
            // Continue with the next file deletion if this one fails
          }
        })
      );

      // Prepare FormData
      const formData = new FormData();
      for (const key in machine) {
        if (key !== 'pdfs' && key !== 'history') { // Exclude 'pdfs' and 'history'
          formData.append(key, machine[key]);
        }
      }

      // Handle pdfs separately
      machine.pdfs.forEach((pdf, index) => {
        if (typeof pdf === 'string') {
          // Append existing PDF IDs
          formData.append('existingPdfsIds', pdf);
        } else {
          // Append new PDF files
          const trimmedName = pdf.name.split('.').slice(0, -1).join('.'); // Get the name before the extension
          formData.append('pdfs', pdf, `${trimmedName}(${index})`);
        }
      });

      // Convert history array to JSON string and append to FormData
      if (machine.history) {
        formData.append('history', JSON.stringify(machine.history));
      }
      // Log FormData contents
      console.log("FormData content:");
      for (let pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }
      await onSubmit(formData);
    } catch (error) {
      console.error('Error editing machine:', error);
      setError(error.response?.data?.error); // Adjust error handling based on your API response
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = () => {
    setIsView(false)
  }

  const handleCloseDetailEdit = () => { //Cancel edit and revert changes
    setIsView(true);
    setMachine({ ...machineCopy }); // Reset machine to the original state
    setImagePreview(originalImage); // Optionally reset the image preview as well
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };



  return (

    <div>
      {loading && <LoadingSpinner />}
      <Dialog open={open} onClose={onClose} fullWidth maxWidth={tabValue === 0 ? "md" : "xl"}>
        {isView ?
          <DialogTitle>{GetTextByRegion("Machine Details", selectedRegion)}: {machine.serial}</DialogTitle>
          :
          <DialogTitle>{GetTextByRegion("Edit Machine", selectedRegion)}: {machine.serial}</DialogTitle>
        }
        <DialogContent>
          {isView && (
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={8}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="scrollable"  // Adds horizontal scrolling on small screens
                  scrollButtons="auto"
                >
                  <Tab label={GetTextByRegion("Details", selectedRegion)} />
                  <Tab label={GetTextByRegion("Machine History Log", selectedRegion)} />
                </Tabs>
              </Grid>

              {selectedRegion === "USA" && (
                <Grid item xs={12} sm={4} style={{ textAlign: 'right' }}>
                  Download PackingList
                  <IconDownloadPDFButton onClickEvent={getMachinePackingListTemplate} params={machine} />

                </Grid>
              )}

            </Grid>
          )}

          {tabValue === 0 ? (
            <Grid container spacing={2}>
              {/* Left Column */}
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" gap={2} mt={1}>
                  {!isView && (
                    <label htmlFor="upload-button-file">
                      <input
                        accept="image/jpeg, image/png"
                        id="upload-button-file"
                        type="file"
                        name="image"
                        onChange={handleChange}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onClick={handleFileInputClick}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        fullWidth
                      >
                        {GetTextByRegion("Choose Image", selectedRegion)}
                      </Button>
                    </label>
                  )}
                  {imagePreview ? (
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{ marginTop: '16px', maxHeight: '200px', objectFit: 'contain' }}
                    />
                  ) : (
                    <img
                      src={placeholderImage}
                      alt="Default"
                      style={{ marginTop: '16px', maxHeight: '200px', objectFit: 'contain' }}
                    />
                  )}
                  <TextField
                    label={GetTextByRegion("Serial Number", selectedRegion)}
                    name="serial"
                    value={machine?.serial || ''}
                    onChange={handleChange}
                    required
                    variant="outlined"
                    error={!!validationErrors.serial && !machine.serial}
                    helperText={machine?.serial ? '' : validationErrors.serial}
                    disabled={isView}
                    className="custom-disabled"
                  />
                  <ModelDropdown
                    selectedRegion={selectedRegion}
                    value={machine?.model}
                    name="model"
                    onChange={handleChange}
                    required
                    errors={!!validationErrors.model && !machine.model}
                    helperText={machine?.model ? '' : validationErrors.model}
                    disabled={isView}
                    type={machine?.isRack ? 'rack' : 'machine'}
                  />
                  {selectedRegion === 'USA' ? (
                    <USStatusDropdown
                      formData={machine?.status || ''}
                      handleChange={handleChange}
                      validationErrors={validationErrors}
                      disabled={isView}
                    />
                  ) : (
                    <FormControl variant="outlined" fullWidth required error={!!validationErrors.status} disabled={isView}>
                      <InputLabel>{GetTextByRegion("Status", selectedRegion)}</InputLabel>
                      <Select
                        label={GetTextByRegion("Status", selectedRegion)}
                        name="status"
                        value={machine?.status || ''}
                        onChange={handleChange}
                        disabled={isView}
                      >
                        <MenuItem value="In House">{GetTextByRegion("In House", selectedRegion)}</MenuItem>
                        <MenuItem value="Demo at Customer Site">{GetTextByRegion("Demo at Customer Site", selectedRegion)}</MenuItem>
                        <MenuItem value="Sold">{GetTextByRegion("Sold", selectedRegion)}</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  <TextField
                    label={GetTextByRegion("Date Received", selectedRegion)}
                    name="datereceived"
                    value={machine?.datereceived || ''}
                    onChange={handleChange}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("Customer", selectedRegion)}
                    name="customer"
                    value={machine?.customer || ''}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("Customer Address", selectedRegion)}
                    name="customerAddress"
                    value={machine?.customerAddress || ''}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    disabled={isView}
                  />
                  <Autocomplete
                    freeSolo
                    disabled={isView}
                    options={['Eval', 'Demo', 'Purchased', 'N/A']}
                    value={machine?.poStatus || ''}
                    onChange={(event, newValue) => {
                      // Handle when a value is selected from the dropdown
                      handleChange({ target: { name: 'poStatus', value: newValue } });
                    }}
                    onInputChange={(event, newInputValue) => {
                      // Handle when the user types into the input field
                      handleChange({ target: { name: 'poStatus', value: newInputValue } });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={GetTextByRegion("PO Status", selectedRegion)}
                        name="poStatus"
                        fullWidth
                        required
                        variant="outlined"
                        disabled={isView}
                        error={!!validationErrors.poStatus}
                      />
                    )}
                  />

                  <TextField
                    label={GetTextByRegion("PO Number", selectedRegion)}
                    name="poNumber"
                    value={machine?.poNumber || ''}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("Terms", selectedRegion)}
                    name="terms"
                    value={machine?.terms || ''}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("Origin", selectedRegion)}
                    name="origin"
                    value={machine?.origin || ''}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("Down Payment", selectedRegion)}
                    name="downpayment"
                    value={machine?.downpayment || ''}
                    onChange={handleChange}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    type='date'
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("Final Payment", selectedRegion)}
                    name="finalpayment"
                    value={machine?.finalpayment || ''}
                    onChange={handleChange}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    type='date'
                    disabled={isView}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isPermanentCynthia"
                        checked={machine?.isPermanentCynthia}
                        onChange={(e) =>
                          handleChange({
                            target: { name: e.target.name, value: e.target.checked },
                          })
                        }
                        color="primary"
                      />
                    }
                    label={GetTextByRegion("Permanent Cynthia", selectedRegion)}
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("Notes", selectedRegion)}
                    name="notes"
                    value={machine?.notes || ''}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    rows={4}
                    disabled={isView}
                  />
                </Box>
              </Grid>
              {/* Right Column */}
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" gap={2} mt={1}>
                  <Autocomplete
                    fullWidth
                    freeSolo
                    options={tubeModels}
                    getOptionLabel={(option) => option.label || option}
                    value={machine?.tube || ''}
                    disabled={isView}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: {
                          name: "tube",
                          value: newValue || "",
                        },
                      });
                    }}
                    onInputChange={(event, newInputValue) => {
                      handleChange({
                        target: {
                          name: "tube",
                          value: newInputValue || "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={GetTextByRegion("Tube", selectedRegion)}
                        variant="outlined"
                        name="tube"
                      />
                    )}
                  />
                  <TextField
                    label={GetTextByRegion("Tube Power Supply", selectedRegion)}
                    name="tubePS"
                    value={machine?.tubePS || ''}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={isView}
                  />
                  <Autocomplete
                    fullWidth
                    freeSolo
                    options={detectorModels}
                    getOptionLabel={(option) => option.label || option}
                    value={machine?.detector || ''}
                    disabled={isView}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: {
                          name: "detector",
                          value: newValue || "",
                        },
                      });
                    }}
                    onInputChange={(event, newInputValue) => {
                      handleChange({
                        target: {
                          name: "detector",
                          value: newInputValue || "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={GetTextByRegion("Detector", selectedRegion)}
                        variant="outlined"
                        name="detector"
                      />
                    )}
                  />
                  <TextField
                    label={GetTextByRegion("PC", selectedRegion)}
                    name="pc"
                    value={machine?.pc || ''}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("Printer", selectedRegion)}
                    name="printer"
                    value={machine?.printer || ''}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("Barcode Scanner", selectedRegion)}
                    name="barcodeScanner"
                    value={machine?.barcodeScanner || ''}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("Monitor", selectedRegion)}
                    name="monitor"
                    value={machine?.monitor || ''}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("Camera", selectedRegion)}
                    name="camera"
                    value={machine?.camera || ''}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("MVP Shell", selectedRegion)}
                    name="mvpShell"
                    value={machine?.mvpShell || ''}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("MVP Software", selectedRegion)}
                    name="mvpSoftware"
                    value={machine?.mvpSoftware || ''}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("DLC", selectedRegion)}
                    name="dlc"
                    value={machine?.dlc || ''}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("Fix License", selectedRegion)}
                    name="fix"
                    value={machine?.fix || ''}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("Loader", selectedRegion)}
                    name="loader"
                    value={machine?.loader || ''}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("Unloader", selectedRegion)}
                    name="unloader"
                    value={machine?.unloader || ''}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("Expected Finish", selectedRegion)}
                    name="expectedfinish"
                    value={machine?.expectedfinish || ''}
                    onChange={handleChange}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    type='date'
                    disabled={isView}
                  />
                  <TextField
                    label={GetTextByRegion("Shipped Date", selectedRegion)}
                    name="shippeddate"
                    value={machine?.shippeddate || ''}
                    onChange={handleChange}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    type='date'
                    disabled={isView}
                  />
                  <FormControl variant="outlined" fullWidth disabled={isView}>
                    <InputLabel>{GetTextByRegion("Urgency", selectedRegion)}</InputLabel>
                    <Select
                      label={GetTextByRegion("Urgency", selectedRegion)}
                      name="urgent"
                      value={machine?.urgent || ''}
                      onChange={handleChange}
                      disabled={isView}
                    >
                      {[...Array(4).keys()].map((num) => (
                        <MenuItem key={num} value={num}>
                          {num}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box display="flex" flexDirection="column" gap={2} mt={2}>
                  {!isView && (
                    <>
                      <input
                        accept="application/pdf"
                        type="file"
                        name="pdfs"
                        multiple
                        onChange={handleChange}
                        style={{ display: 'none' }}
                        id="upload-pdfs"
                      />
                      <label htmlFor="upload-pdfs">
                        <Button variant="contained" component="span" fullWidth>
                          {GetTextByRegion("Uploaded PDFs", selectedRegion)}
                        </Button>
                      </label>
                    </>
                  )}

                  {machine.pdfs.length > 0 && (
                    <Box mt={2}>
                      <Typography variant="h5">{GetTextByRegion("Uploaded PDFs", selectedRegion)}</Typography>
                      <Grid container spacing={2}>
                        {/* Render existing PDFs */}
                        {existingPdfs.map((pdf, index) => (
                          <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card style={{ display: 'flex', flexDirection: 'column', minHeight: '125px' }}>
                              <CardContent style={{ flexGrow: 1 }}>
                                <Typography variant="body1">
                                  <a
                                    href={`${backendString}/machines/files/${pdf}?selectedRegion=${selectedRegion}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {typeof pdfNames[pdf] === 'string'
                                      ? pdfNames[pdf]
                                      : pdfNames[pdf]?.name || pdf}
                                  </a>
                                </Typography>
                              </CardContent>
                              {!isView && (
                                <CardActions>
                                  <IconButton
                                    color="secondary"
                                    onClick={() => handleDeletePdf(pdf, true)} // Pass true for existing PDFs
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </CardActions>
                              )}
                            </Card>
                          </Grid>
                        ))}

                        {/* Render newly uploaded PDFs */}
                        {newPdfs.map((pdf, index) => (
                          <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card style={{ display: 'flex', flexDirection: 'column', minHeight: '125px' }}>
                              <CardContent style={{ flexGrow: 1 }}>
                                <Typography variant="body1">
                                  {pdf.name || 'New PDF'}
                                </Typography>
                              </CardContent>
                              {!isView && userRights >= roles['MANAGER'] && (
                                <CardActions>
                                  <IconButton
                                    color="secondary"
                                    onClick={() =>
                                      handleDeletePdf(index + existingPdfs.length, false) // Pass false for new PDFs
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </CardActions>
                              )}
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}


                </Box>
              </Grid>
            </Grid>
          ) : (
            <MachineHistoryLog machine={machine} selectedRegion={selectedRegion} setMachine={setMachine} fetchMachines={fetchMachines} />
          )}
        </DialogContent>
        <DialogActions>
          {initialIsView && !isView ? ( //details page and editing
            <Button onClick={handleCloseDetailEdit} color="secondary">
              {GetTextByRegion("Cancel", selectedRegion)}
            </Button>
          ) : ( //details page and not edit OR not in details page
            <Button onClick={onClose} color="secondary">
              {isView ? GetTextByRegion("Close", selectedRegion) : GetTextByRegion("Cancel", selectedRegion)}
            </Button>
          )}

          {!isView ? (
            <Button onClick={handleSubmit} variant="contained" color="primary"
              disabled={!machine.serial || machine.serial === "" ||
                !machine.model || machine.model === "" ||
                !machine.status || machine.status === ""}>
              {GetTextByRegion("Save", selectedRegion)}
            </Button>
          ) : tabValue === 0 && (
            <Button onClick={handleEdit} variant="contained" color="primary"
              disabled={!machine.serial || machine.serial === "" ||
                !machine.model || machine.model === "" ||
                !machine.status || machine.status === ""}>
              {GetTextByRegion("Edit", selectedRegion)}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Error Dialog */}
      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />

      <PackingListForm
        open={packingListConfirmationDialog}
        handleClose={handleClosePacking}
        machine={machine} // Pass the machine data to the dialog
      />
    </div>


  );
};

export default EditMachineForm;
