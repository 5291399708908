import React from 'react';
import { HashRouter as Router, Route, Routes, Outlet, Navigate } from 'react-router-dom';
import NavBar from './components/Navigation/NavBar';
import './styles/App.css';
import Inventory from './views/Inventory';
import Machine from './views/Machine';
import Calendar from './views/Calendar';
import Homepage from './views/Homepage';
import LoginForm from './components/Navigation/LoginForm';
import Forum from './views/Forum';
import User from './views/User';
import MachineModel from './views/MachineModel';
import ServiceOrder from './views/ServiceOrder';
import { AuthProvider } from './components/AuthContext';
import AdminProtectedRoute from './components/AdminProtectedRoute';
import UserProtectedRoute from './components/UserProtectedRoute';
import ChinaProduction from './views/ChinaProduction';
import WorkInProgressPage from './views/WorkInProgressPage';
import ProfilePage from './views/ProfilePage';
import { UserProvider } from './hooks/UserContext';
import RemoteServicePage from './views/RemoteServicePage';
import ReportsPage from './views/ReportsPage';
import AfterSalesView from './views/AfterSalesView';
import ItemRequestPage from './views/ItemRequestPage';
import CustomerPage from './views/CustomerPage';
import TodoPage from './views/TodoPage';
import Bom from './views/BomView';
import Explosion from './views/ExplosionDrawingsView';
import ChinaReportPage from './views/ChinaReportPage';
import ChinaItemRequestPage from './views/ChinaItemRequestPage'
import QoutationView from './components/Quotation/QuotationView';
import FormBuilder from './views/FormBuilder'
import PackingListView from './views/PackingListView';
import MachineOrderView from './views/MachineOrderView';
import MachineStatisticsView from './views/MachineStatisticsView';
import IncomingShipmentView from './views/IncomingShipmentView';
import ProductionPartsView from './views/ProductionPartsView';

const App = () => {
  
  return (
    <AuthProvider>
        <Router>
          <UserProvider>
            <Routes>
              {/* Routes without SelectedRegionProvider */}
              <Route path="/login" element={<LoginForm />} />

              {/* Routes with SelectedRegionProvider */}
                <Route element={<LayoutWithNavBar />}>
                  {/* Admin protected routes */}
                  <Route element={<AdminProtectedRoute />}>
                    <Route path="/users" element={<User />} />
                    <Route path="/customers" element={<CustomerPage />} />
                  </Route>

                  {/* User protected routes */}
                  <Route element={<UserProtectedRoute />}>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/inventory" element={<Inventory />} />
                    <Route path="/machine" element={<Machine />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/Q&A" element={<Forum />} />
                    <Route path="/todo" element={<TodoPage />} />
                    <Route path="/bom" element={<Bom />} />
                    <Route path="/explosion" element={<Explosion />} />
                    <Route path="/chinareports" element={<ChinaReportPage />} />
                    <Route path="/remoteservice" element={<RemoteServicePage />} />
                    <Route path="/reports" element={<ReportsPage />} />
                    <Route path="/service" element={<ServiceOrder />} />
                    <Route path="/models" element={<MachineModel />} />
                    <Route path="/profile" element={<ProfilePage />} />
                    <Route path="/partrequestlog" element={<AfterSalesView region="OVERSEAS"/>} />
                    <Route path="/eupartrequestlog" element={<AfterSalesView region="EU"/>} />
                    <Route path="/chinapartrequestlog" element={<AfterSalesView region="CHINA"/>} />
                    <Route path="/itemrequest" element={<ItemRequestPage />} />
                    <Route path="/chinaproduction" element={<ChinaProduction region="China"/>} />
                    <Route path="/overseaschinaproduction" element={<ChinaProduction region="Overseas"/>} />
                    <Route path="/euchinaproduction" element={<ChinaProduction region="EU"/>} />
                    <Route path="/chinaitemrequest" element={<ChinaItemRequestPage />} />
                    <Route path="/formbuilder" element={<FormBuilder />} />
                    <Route path="/packinglist" element={<PackingListView />} />
                    <Route path="/work-in-progress" element={<WorkInProgressPage />} />
                    <Route path="/quotegenerator" element={<QoutationView open={true}/>}/>
                    <Route path="/machineorder" element={<MachineOrderView/>}/>
                    <Route path="/machineStatistics" element={<MachineStatisticsView/>}/>
                    <Route path="/incomingchecklist" element={<IncomingShipmentView/>}/>
                    <Route path="/productionparts" element={<ProductionPartsView/>}/>
                  </Route>
                </Route>
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </UserProvider>         
        </Router>
    </AuthProvider>
  );
};

// Layout component with NavBar
const LayoutWithNavBar = () => (
  <>
    <NavBar />
    <Outlet />
  </>
);

export default App;