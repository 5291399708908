import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Make sure to import axios
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
} from '@mui/material';
import ErrorDialog from '../ErrorDialog';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const USFinalReportForm = ({ open, onClose, onSubmit, report, viewOnly }) => {
  const [formData, setFormData] = useState({
    dateReported: '',
    model: '',
    reportNumber: '',
    serialNumber: '',
    customer: '',
    reportBy: '',
    issueType: '',
    problem: '',
    description: '',
    suggestedSolution: '',
    images: [],
    dateResolved: '',
    details: '',
    dateResolvedPermanent: '',
    ECN: '',
    quantity: '',
    trainingDate: '',
    entry: [{ _id: 0, dateResolved: '', details: '' }], // initialize as array
    files: [],
    requestECNDate: '',
    notifyEquipmetDate: '',
    recallDate: '',
  });
  const { selectedRegion } = useUser();
  const [error, setError] = useState(null);
  const [fileNames, setFileNames] = useState({});
  let connectionString = `${backendString}/nonconforming`;

  useEffect(() => {
    if (report) {
      setFormData(report);
    }
    else {
      setFormData({
        dateReported: '',
        model: '',
        reportNumber: '',
        serialNumber: '',
        customer: '',
        reportBy: '',
        issueType: '',
        problem: '',
        description: '',
        suggestedSolution: '',
        images: [],
        dateResolved: '',
        details: '',
        dateResolvedPermanent: '',
        ECN: '',
        quantity: '',
        trainingDate: '',
        entry: [{ _id: 0, dateResolved: '', details: '' }], // initialize as array
        files: [],
        requestECNDate: '',
        notifyEquipmetDate: '',
        recallDate: '',
      });
    }
  }, [report]);

  useEffect(() => {
    const fetchFileNames = async () => {
      if (!formData.files || formData.files.length === 0) return;

      try {
        const fileIds = formData.files.filter(file => typeof file === 'string'); // Existing file IDs

        // Fetch names for existing files
        const names = await Promise.all(
          fileIds.map(id =>
            axios
              .get(`${backendString}/nonconforming/files/names/${id}?selectedRegion=${formData.reportedRegion}`)
              .then(response => ({ _id: id, filename: response.data }))
              .catch(error => {
                console.error(`Error fetching file name for ${id}:`, error);
                return { _id: id, filename: "Unknown File" }; // Fallback filename
              })
          )
        );


        // Create a map of fileId -> filename
        const namesMap = names.reduce((acc, file) => {
          acc[file._id] = file.filename;
          return acc;
        }, {});

        setFileNames(namesMap);
      } catch (err) {
        console.error("Error fetching file names:", err);
      }
    };

    fetchFileNames();
  }, [formData.files, selectedRegion]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDateChange = (name) => (e) => {
    setFormData((prevData) => ({ ...prevData, [name]: e.target.value }));
  };

  const handleCloseErrorDialog = () => {
    setError(null); // Reset error state
  };

  const handleClose = () => {
    setFormData({
      dateReported: '',
      model: '',
      reportNumber: '',
      serialNumber: '',
      customer: '',
      reportBy: '',
      issueType: '',
      problem: '',
      description: '',
      suggestedSolution: '',
      images: [],
      dateResolved: '',
      details: '',
      dateResolvedPermanent: '',
      entry: [{ _id: 0, dateResolved: '', details: '' }], // initialize as array
      files: [],
      ECN: '',
      quantity: '',
      trainingDate: '',
      requestECNDate: '',
      notifyEquipmetDate: '',
      recallDate: '',
    });
    onClose();
  }

  const handleSubmit = async () => {
    if (viewOnly) return; // Do not submit if in view-only mode

    try {
      // Prepare the updated report data
      const updatedReport = {
        ...formData,
        status: 'Completed', // Set the status to 'Completed'
      };

      // Make the PUT request to update the report on the server
      const response = await axios.put(`${connectionString}/${report._id}?reportedRegion=${updatedReport.reportedRegion}`, updatedReport, {
        headers: {
          'Authorization': sessionStorage.getItem('token'),
        },
      });
      onSubmit(response.data, false);
      handleClose();
    } catch (error) {
      setError(error.response?.data?.error);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>{`${formData.reportedRegion} ` + GetTextByRegion("FinalNonconforming Form", selectedRegion)}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                label={GetTextByRegion("Date Reported", selectedRegion)}
                type="date"
                name="dateReported"
                value={formData.dateReported}
                onChange={handleDateChange('dateReported')}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                label={GetTextByRegion("Model", selectedRegion)}
                name="model"
                value={formData.model}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                label={GetTextByRegion("Report Number", selectedRegion)}
                name="reportNumber"
                value={formData.reportNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                label={GetTextByRegion("Serial Number", selectedRegion)}
                name="serialNumber"
                value={formData.serialNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                label={GetTextByRegion("Customer", selectedRegion)}
                name="customer"
                value={formData.customer}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                label={GetTextByRegion("Report By", selectedRegion)}
                name="reportBy"
                value={formData.reportBy}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                label={GetTextByRegion("Issue Type", selectedRegion)}
                name="Issue Type"
                value={formData.issueType}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                label={GetTextByRegion("Problem", selectedRegion)}
                name="problem"
                value={formData.problem}
                onChange={handleChange}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                label={GetTextByRegion("Description", selectedRegion)}
                name="description"
                value={formData.description}
                onChange={handleChange}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                label={GetTextByRegion("Suggested Solution", selectedRegion)}
                name="suggestedSolution"
                value={formData.suggestedSolution || null}
                onChange={handleChange}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <label>{GetTextByRegion("Images", selectedRegion)}:</label>
              <Grid container spacing={2}>
                {formData.images && formData.images.length > 0 ? (
                  formData.images.map((image, index) => (
                    <Grid item xs={3} key={index}>
                      <a
                        href={`${connectionString}/images/` + image + `?reportedRegion=${formData.reportedRegion}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={`${connectionString}/images/` + image + `?reportedRegion=${formData.reportedRegion}`} // Assuming `image` contains the URL or base64 encoded data
                          alt={`Preview ${index + 1}`}
                          style={{ width: '100%', height: '100%', borderRadius: 8 }}
                        />
                      </a>
                    </Grid>
                  ))
                ) : (
                  <Typography mt={3} ml={2}>{GetTextByRegion("No images to preview", selectedRegion)}</Typography>
                )}
              </Grid>
            </Grid>
           
            {/* New Table for Entry Array */}
            <Grid item xs={12}>
              <Typography variant="h6">{GetTextByRegion("Filled in by CHINA", selectedRegion)}</Typography>
              <Divider />              
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '25%' }}>{GetTextByRegion("Date Resolved", selectedRegion)}</TableCell>
                      <TableCell style={{ width: '75%' }}>{GetTextByRegion("Details", selectedRegion)}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formData.entry
                      .filter(entry => entry.dateResolved !== '') // Filter out entries with null dateResolved
                      .map((entry, index) => (
                        <TableRow key={entry._id || index}>
                          <TableCell  style={{ width: '25%' }}>{entry.dateResolved}</TableCell>
                          <TableCell  style={{ width: '75%' }}>{entry.details}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={12} mb={6}>
              <label>{GetTextByRegion("Files", selectedRegion)}:</label>
              <Grid container spacing={2} alignItems="center" direction="row">
                {formData.files && formData.files.length > 0 ? (
                  formData.files.map((file, index) => {
                    const fileId = typeof file === "string" ? file : file._id; // Handle both existing and new files
                    const fileUrl = `${backendString}/nonconforming/files/${fileId}`;


                    return (
                      <Grid item key={index}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                              <Typography variant="body2">{fileNames[fileId]?.name || "Unknown File"}</Typography>
                            </a>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })
                ) : (
                  <Typography mt={3} ml={2}>
                    {GetTextByRegion("No files uploaded", selectedRegion)}
                  </Typography>
                )}
              </Grid>
            </Grid>

            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={GetTextByRegion("Date Resolved Permanent", selectedRegion)}
                type="date"
                disabled
                name="dateResolvedPermanent"
                value={formData.dateResolvedPermanent}
                onChange={handleDateChange('dateResolvedPermanent')}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                label={GetTextByRegion("ECN", selectedRegion)}
                name="ECN"
                value={formData.ECN}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              < Typography variant="h6">
                {`${formData.reportedRegion} ` + GetTextByRegion("Follow Up", selectedRegion)}
              </Typography>
              <Divider fullWidth />
            </Grid>


            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={viewOnly}
                label={GetTextByRegion("Check Current stock situation Quantity", selectedRegion)}
                name="quantity"
                value={formData.quantity}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={viewOnly}
                // required
                label={GetTextByRegion("Internal Training Date", selectedRegion)}
                type="date"
                name="trainingDate"
                value={formData.trainingDate}
                onChange={handleDateChange('trainingDate')}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={viewOnly}
                // required
                label={GetTextByRegion("Request new parts based on ECN Date", selectedRegion)}
                type="date"
                name="requestECNDate"
                value={formData.requestECNDate}
                onChange={handleDateChange('requestECNDate')}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={viewOnly}
                // required
                label={GetTextByRegion("Notify and check Equipment of the current customers Date", selectedRegion)}
                type="date"
                name="notifyEquipmetDate"
                value={formData.notifyEquipmetDate}
                onChange={handleDateChange('notifyEquipmetDate')}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={viewOnly}
                // required
                label={GetTextByRegion("Recall Date", selectedRegion)}
                type="date"
                name="recallDate"
                value={formData.recallDate}
                onChange={handleDateChange('recallDate')}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">{GetTextByRegion("Cancel", selectedRegion)}</Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={viewOnly} // Disable button if in view-only mode
          >
            {GetTextByRegion("Submit", selectedRegion)}
          </Button>
        </DialogActions>

      </Dialog>
      <ErrorDialog
        open={!!error} // Ensure this is a boolean
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
};

export default USFinalReportForm;
