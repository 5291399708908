import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    FormControlLabel,
    Checkbox,
    Button,
    TextField,
} from "@mui/material";
import { useUser } from "../hooks/UserContext";

const backendString = process.env.REACT_APP_BACKEND_STRING;

const MachineConfiguration = ({ open, onClose,/*configurationsString*/ }) => {
    const { selectedRegion } = useUser();
    const [configurations, setConfigurations] = useState([]);


    const fetchData = async () => {
        try {
            const endpoint = `${backendString}/dropdown?selectedRegion=${selectedRegion}&type=option`;
            const response = await axios.get(endpoint);
            console.log(response.data);
            const initOptions = response.data.map((option, index) => ({
                index: index,
                labelname: option.ModelName,
                checkbox: false,
                quantity: 0,
            }));
            setConfigurations(initOptions);
        } catch (error) {
            console.error("Error fetching options:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [selectedRegion]);

    useEffect(() => {
        console.log(configurations);
    }, [configurations]);

    const onSave = () => {


    }

    // Handle checkbox changes
    const handleCheckBoxChange = (event) => {
        const { name, checked, type, value } = event.target;
        const index = parseInt(name.split("!")[1], 10);
        if (type === "checkbox") {
            setConfigurations((prevConfigurations) =>
                prevConfigurations.map((config, i) =>
                    i === index ? { ...config, checkbox: checked } : config
                )
            );
        } else if (type === "number") {
            const value = parseInt(event.target.value, 10) || 0;
            setConfigurations((prevConfigurations) =>
                prevConfigurations.map((config, i) =>
                    i === index ? { ...config, quantity: value } : config
                )
            );
        } else {
            //do nothing
        }

    };

    const optionTemplate = ({ labelname, labeltext, index }) => (
        <Grid container>
            <Grid item xs={8} sx={{ mb: 2 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            name={`${labelname}`}
                            checked={configurations[index]?.checkbox}
                            onChange={handleCheckBoxChange}
                        />
                    }
                    label={labeltext}
                />
            </Grid>
            <Grid item xs={4} sx={{ mb: 2 }}>
                <TextField
                    label={"Quantity"}
                    name={`${labelname}`}
                    value={configurations[index]?.quantity || 0}
                    variant="standard"
                    type="number"
                    onChange={handleCheckBoxChange}
                //error={!!validationErrors.quantity && item.quantity < 0}
                //helperText={item?.quantity >= 0 ? '' : validationErrors.quantity}                  
                />
            </Grid>
        </Grid>
    );

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>Machine Configuration</DialogTitle>
            <DialogContent>
                <div>
                    <Grid container spacing={2}>
                        {configurations.map((option, index) => (
                            <Grid item xs={6} key={index}>
                                <React.Fragment key={index}>
                                    {optionTemplate({
                                        labelname: `${option.labelname}!${option.index}`,
                                        labeltext: option.labelname,
                                        index: index
                                    })}
                                </React.Fragment>
                            </Grid>
                        ))}

                    </Grid>
                </div>

            </DialogContent>
            <div style={{ display: "flex", justifyContent: "flex-end", margin: "8px" }}>
                <Button onClick={onSave} variant="contained">
                    Save
                </Button>
                <Button onClick={onClose} variant="contained" sx={{ mr: 1, ml: 1 }}>
                    Close
                </Button>
            </div>
        </Dialog>
    );
};

export default MachineConfiguration;
